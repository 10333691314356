import { useMemo, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/common-table";
import UsersTableTools from "../../components/page-components/users/UserTableTools";
import { getUsersList } from "../../store/userSlice";
import { CheckBox } from "@mui/icons-material";
import { Cancel } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import SyncUser from "./SyncUser";

const Users = () => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const { users } = useSelector((state) => state.userSlice);
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)


  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Phone",
        accessor: "email",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee Id",
        accessor: "employee.emp_id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee name",
        accessor: "employee.name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Partner Name",
        accessor: "partner.name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Active Status",
        accessor: "is_active",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? <CheckBox color="success" /> : <Cancel color="error" />,
      },
      {
        Header: "Verification Status",
        accessor: "is_verified",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? <CheckBox color="success" /> : <Cancel color="error" />,
      },
      // {
      //   Header: "Sync Needed",
      //   accessor: "is_synce_needed",
      //   className: "font-bold",
      //   sortable: true,
      //   Cell: (val) =>
      //     val.value ? <CheckBox color="success" /> : <Cancel color="error" />,
      // },
      {
        Header: "Sync",
        id: "sync",
        sortable: false,
        className: "font-bold",
        Cell: ({ row }) => <SyncUser row={row} />,
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(
      getUsersList({
        partner_id: searchParams.get("partner_id"),
        offset: offset,
        limit: limit
      })
    );
  }, [dispatch, searchParams, offset, limit]);

  return (
    <Grid container direction="column" spacing={3}>
      <CommonTable
        request_type="advance"
        offset={offset}
        limit={limit}
        changePage={changePage}
        columns={columns}
        data={users.filter((user) => !user.is_superuser)}
        handleClick={() => null}
        TableToolsComponent={UsersTableTools}
      />
    </Grid>
  );
};

export default Users;
