const labels = {
    valid: "success",
    invalid: "warning",
    disburse: "secondary",
    invoiced: "info",
    cancelled: "error",
    batch_created: "secondary"
};

export default labels;
