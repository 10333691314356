import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import ConnectivityConfiguration from "./connectivity-configuration";
import SalaryConfiguration from "./salary-configuration";
import CompanyEmployeeList from "./company-employee-list";
import PartnerEmployeeAttendenceList from "./employee-attendence-list";
import PartnerEmployeeSalaryRequest from "./partner-employee-salary-request-list";
import OgreemFee from "./ogreem-fee";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConnectivitySalaryEmployeeListTab = ({
  companyName,
  partnerId,
  partnerInfo,
  tabValue,
  pageName,
}) => {
  const [value, setValue] = useState(tabValue);
  const { userData, notAdmin } = useSelector((state) => state.authSlice);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //     setValue(tabValue)
  // }, [tabValue])

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {userData.id !== notAdmin ? (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Connectivity Configuration" {...a11yProps(0)} />
            <Tab label="Salary Configuration" {...a11yProps(1)} />
            <Tab label="Employee List" {...a11yProps(2)} />
            <Tab label="Employee Attendence List" {...a11yProps(3)} />
            <Tab label="Employee Salary Request" {...a11yProps(4)} />
            <Tab label="Ogreem Fee" {...a11yProps(5)} />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {" "}
            <Tab label="Salary Configuration" {...a11yProps(0)} />
            <Tab label="Employee List" {...a11yProps(1)} />
            <Tab label="Employee Attendence List" {...a11yProps(2)} />
            <Tab label="Employee Salary Request" {...a11yProps(3)} />
          </Tabs>
        )}
      </Box>{" "}
      {userData.id !== notAdmin ? (
        <>
          <TabPanel value={value} index={0}>
            <ConnectivityConfiguration partnerId={partnerId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SalaryConfiguration partnerId={partnerId} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CompanyEmployeeList
              companyName={companyName}
              partnerId={partnerId}
              partnerInfo={partnerInfo}
              pageName={pageName}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PartnerEmployeeAttendenceList
              companyName={companyName}
              partnerId={partnerId}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <PartnerEmployeeSalaryRequest partnerId={partnerId} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <OgreemFee partnerId={partnerId} />
          </TabPanel>
        </>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <SalaryConfiguration partnerId={partnerId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CompanyEmployeeList
              companyName={companyName}
              partnerId={partnerId}
              partnerInfo={partnerInfo}
              pageName={pageName}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PartnerEmployeeAttendenceList
              companyName={companyName}
              partnerId={partnerId}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PartnerEmployeeSalaryRequest partnerId={partnerId} />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default ConnectivitySalaryEmployeeListTab;
