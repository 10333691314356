import React, { useMemo, useEffect, useCallback, useState } from "react";
// import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../../components/common-table";
import ChangeStatusActions from "../../../components/page-components/salary-request-list/ChangeStatusActions";
import TableTools from "../../../components/page-components/salary-request-list/TableTools";
import moment from "moment";
import {
  partnerEmployeeSalaryRequestData,
  salaryRequestDelete,
} from "../../../store/partnerSlice";
// import labels from "../../../utils/labels";
import { IconButton, Link as MUILink } from "@mui/material";
import {
  // getPaymentMethodList,
  getPaymentWalletList,
} from "../../../store/agrimSlice";
import { Cancel } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";

const SalaryRequestList = () => {
  const { userData, notAdmin } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  let [searchParams] = useSearchParams();
  const {
    partnerEmployeeSalaryRequestList,
    partnerEmployeeSalaryRequestListLoading,
  } = useSelector((state) => state.partnerSlice);

  const handleDelete = useCallback(
    (row) => {
      console.log(row.original);
      dispatch(salaryRequestDelete({ request_id: row.original.id }));
    },
    [dispatch]
  );

  const [filter_data, set_filter_data] = useState({
    offset: 0,
    limit: 10,
    status: "valid",
    partner_id: searchParams.get("partner_id"),
  })

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Partner",
        accessor: "partner_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee",
        accessor: "employee_name",
        className: "font-bold",
        sortable: true,
        Cell: ({ value, row }) => (
          <MUILink
            component={Link}
            to={`/admin/employee-profile/${row.original.employee.id}`}
          >
            {value}
          </MUILink>
        ),
      },
      {
        Header: "Employee Id",
        accessor: "employee.emp_id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Reason",
        accessor: "reason",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Date Requested",
        accessor: "requested_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("DD/MM/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Requested Amount",
        accessor: "requested_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Ogreem Fee",
        accessor: "agrim_fee",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Amount to Disburse",
        accessor: "amount_to_disburse",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Available Amount",
        accessor: "available_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Paynemt Type",
        id: "payment_type",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.type.toUpperCase()}
          </div>
        ),
      },
      {
        Header: "Paynemt Method",
        id: "payment_method",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.name}
          </div>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_no",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Branch Name",
        accessor: "branch",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
        Cell: ChangeStatusActions,
      },
      {
        Header: "Cancelled",
        id: "delete",
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {userData.id !== notAdmin ? (
              <IconButton onClick={(ev) => handleDelete(row)}>
                <Cancel sx={{ fontSize: 30, color: "red" }} />
              </IconButton>
            ) : (
              ""
            )}
          </div>
        ),
      },
    ],
    [handleDelete, notAdmin, userData.id]
  );

  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  useEffect(() => {

    set_filter_data({
      ...filter_data,
      offset: offset,
      limit: limit,
      status: "valid",
      partner_id: searchParams.get("partner_id"),
    })

    dispatch(
      partnerEmployeeSalaryRequestData({
        ...filter_data,
        offset: offset,
        limit: limit,
        status: "valid",
        partner_id: searchParams.get("partner_id"),
      })
    );
    dispatch(getPaymentWalletList());
  }, [dispatch, searchParams, offset, limit]);

  const handleSettingFilterData = query => {
    set_filter_data(query)
  }

  return (
    // <Grid container direction="column" spacing={3}>
    <CommonTable
      request_type="advance"
      columns={columns}
      data={partnerEmployeeSalaryRequestList}
      changePage={ changePage }
      offset={offset}
      limit={limit}
      handleClick={() => null}
      checkbox
      TableToolsComponent={TableTools}
      tableLoader={partnerEmployeeSalaryRequestListLoading}
      filter_data={filter_data}
      handleSettingFilterData= {handleSettingFilterData}
    />
    // </Grid>
  );
};

export default SalaryRequestList;
