import React, { Component } from "react";
import { useAsyncError } from "react-router-dom";

export function HandleRouteError() {
  const error = useAsyncError();
  return (
    <div>
      {error.message} {error.stack}
    </div>
  );
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children.type.name !== this.props.children.type.name) {
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return <h4>This section has a problem</h4>;
    }

    return this.props.children;
  }
}
