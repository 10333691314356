import { useRef, useState } from "react";
import { APIDataServices } from "../../../service-pattern";
import {
  Tooltip,
  Alert,
  IconButton,
  AlertTitle,
  Typography,
} from "@mui/material";
import { AssignmentTurnedIn, Cancel, PriceCheck } from "@mui/icons-material";
import {
  disburseBatchRequest,
  cancelBatchRequest,
} from "../../../store/agrimSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useRevalidator } from "react-router-dom";

const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const BatchActionButtons = ({ val }) => {
  const toastId = useRef(null);
  const { value: status } = val;
  const revalidator = useRevalidator();
  const { userData, notAdmin } = useSelector((state) => state.authSlice);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleBatchStatus = async (e) => {
    e.stopPropagation();
    setLoading(true);
    console.log(val);
    if (e.currentTarget.value === "approved") {
      toastId.current = toast.loading(`Approving. Please Wait..`);
      const { payload: response } =
        await new APIDataServices().ApproveBatchRequest({
          data: { status: "approved" },
          params: { batch_id: val.cell.row.original.id },
        });
      console.log(response);
      toast.update(toastId.current, {
        render: "Batch approved",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        theme: "colored",
        closeOnClick: true,
      });
    } else if (e.currentTarget.value === "disbursed") {
      toastId.current = toast.loading(`Disursing. Please Wait..`);
      const { payload: response } = await dispatch(
        disburseBatchRequest({ params: { batch_id: val.cell.row.original.id } })
      );

      // toast.dismiss(toastId);
      if (response.status === 200) {
        toast.update(toastId.current, {
          render: "Batch Disbursed",
          type: "success",
          isLoading: false,
          autoClose: 2000,
          theme: "colored",
          closeOnClick: true,
        });
      } else if (response.status === 406) {
        const { error, message, reason } = response.data;
        toast.update(toastId.current, {
          render: (
            <Alert severity="error">
              <AlertTitle>{error}: Something wrong</AlertTitle>
              <Typography>Reason: {message}</Typography>
              <Typography>
                Code: <strong>{reason}</strong>
              </Typography>
            </Alert>
          ),
          type: "error",
          isLoading: false,
          autoClose: 2000,
          theme: "colored",
          closeOnClick: true,
        });
      } else {
        toast.error("Error occoured. Please try again", toastOptions);
      }
    } else if (e.currentTarget.value === "cancelled") {
      toastId.current = toast.loading(`Cancelling. Please Wait..`);
      await dispatch(
        cancelBatchRequest({ params: { batch_id: val.cell.row.original.id } })
      );
      toast.update(toastId.current, {
        render: "Batch Cancelled",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        theme: "colored",
        closeOnClick: true,
      });
    }
    revalidator.revalidate();
    // dispatch(
    //     getSalaryRequestBatchInfo({
    //         params: batch_status ? { batch_status } : {},
    //     })
    // );
    setLoading(false);
  };

  // const handleDisburseReport = (e) => {
  //   e.stopPropagation();
  //   try {
  //     console.log(val);
  //   } catch (error) {
  //
  //       }
  // };
  if (userData.id === notAdmin) {
    return "-";
  }
  if (status === "draft") {
    return (
      <>
        <Tooltip placement="left" title="Approve">
          <IconButton
            id="approved"
            value="approved"
            onClick={handleBatchStatus}
            color="success"
            disabled={loading}
          >
            <AssignmentTurnedIn />
          </IconButton>
        </Tooltip>
        <Tooltip placement="right" title="Reject">
          <IconButton
            id="cancelled"
            value="cancelled"
            onClick={handleBatchStatus}
            color="error"
          >
            <Cancel />
          </IconButton>
        </Tooltip>
      </>
    );
  } else if (status === "approved") {
    return (
      <>
        <Tooltip placement="left" title="Disburse">
          <IconButton
            id="disbursed"
            value="disbursed"
            onClick={handleBatchStatus}
            color="info"
            disabled={loading}
          >
            <PriceCheck />
          </IconButton>
        </Tooltip>
        <Tooltip placement="right" title="Reject">
          <IconButton
            id="cancelled"
            value="cancelled"
            onClick={handleBatchStatus}
            color="error"
          >
            <Cancel />
          </IconButton>
        </Tooltip>
      </>
    );
  }
  //   else if (status === "disbursed") {
  //   return (
  //     <>
  //       <Tooltip placement="left" title="Disburse">
  //         <IconButton
  //           id="disbursed"
  //           onClick={handleDisburseReport}
  //           color="info"
  //           disabled={loading}
  //         >
  //           <Preview />
  //         </IconButton>
  //       </Tooltip>
  //     </>
  //   );
  // }
  return "-";
};

export default BatchActionButtons;
