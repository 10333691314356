import React, { useEffect, useState } from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import Select from "react-select";
// import _ from "lodash"
import { useDispatch, useSelector } from "react-redux";
import {
  createBatchRequest,
  // getPaymentWalletList
} from "../../../store/agrimSlice";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import TextField from "@mui/material/TextField";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import moment from "moment";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { toastNotification } from "../../../utils/toast";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F7F5ED",
  boxShadow: 24,
  p: 3,
  borderTopLeftRadius: "20px",
  borderBottomRightRadius: "20px",
};

const CreateBatchModal = ({
  isCreateBatchModalOpen,
  setIsCreateBatchModalOpen,
  selectedFlatRows,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(getPaymentWalletList())
  }, []);

  const { paymentWalletList } = useSelector((state) => state.agrimSlice);

  const [paymentWalletOptions, setPaymentWalletOptions] = useState([]);
  const [selectedPaymentWallet, setSelectedPaymentWallet] = useState(null);
  // const [axiosScheduletime, setAxiosScheduletime] = useState(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'))
  // const [frontEndScheduletime, setfrontEndScheduletime] = useState(new Date())

  useEffect(() => {
    if (paymentWalletList.length > 0) {
      const paymentWalletOptionsArray = paymentWalletList.map((wallet) => ({
        label: wallet.name,
        value: wallet.id,
      }));
      setPaymentWalletOptions(paymentWalletOptionsArray);
    }
  }, [paymentWalletList]);

  const handleValueChange = (name, value) => {
    if (name === "wallet_id") {
      setSelectedPaymentWallet(value);
    }

    // if (name === "scheduled_dt") {
    //     setAxiosScheduletime(moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS'))
    //     setfrontEndScheduletime(value)
    // }
  };

  const handleCreatingBatch = async () => {
    try {
      const request_ids = selectedFlatRows.map((row) => row.original.id);
      const batch_data = {
        wallet_id: selectedPaymentWallet.value,
        // scheduled_dt: axiosScheduletime
      };

      let response = await dispatch(
        createBatchRequest({ request_ids, batch_data })
      );
      // console.log(response);
      if (response?.payload?.data?.detail) {
        toastNotification("error", response?.payload?.data?.detail);
      }
      if (response.payload.status === 201) {
        handleClose();
        const name = response.payload.data.name
          .trim()
          // eslint-disable-next-line no-useless-escape
          .replace(/[\. ,:-]+/g, "-")
          .toLowerCase();
        const URL = `/admin/salary-request-batch/batch-info/${name}`;
        navigate(URL, {
          state: { batchId: response.payload.data.id, batchType: "draft" },
        });
      }
    } catch (error) {
      console.log(error);
      if (error?.data?.detail) {
        toastNotification("error", error?.data?.detail);
      } else {
        toastNotification(
          "error",
          error.message || "Something is wrong! try again."
        );
      }
    }
  };

  const handleClose = () => {
    setIsCreateBatchModalOpen(false);
    setSelectedPaymentWallet(null);
    // setfrontEndScheduletime(null)
    // setAxiosScheduletime(null)
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isCreateBatchModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableAutoFocus={true}
    >
      <Fade in={isCreateBatchModalOpen}>
        <Box sx={style} className="login-alert">
          <div className="w-full h-full relative">
            <div className="modal-close-button" onClick={handleClose}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7 5.3C18.5 5.1 18.3 5 18 5C17.7 5 17.5 5.1 17.3 5.3L12 10.6L6.7 5.3C6.3 4.9 5.7 4.9 5.3 5.3C4.9 5.7 4.9 6.3 5.3 6.7L10.6 12L5.3 17.3C5.1 17.5 5 17.7 5 18C5 18.3 5.1 18.5 5.3 18.7C5.5 18.9 5.7 19 6 19C6.3 19 6.5 18.9 6.7 18.7L12 13.4L17.3 18.7C17.5 18.9 17.7 19 18 19C18.3 19 18.5 18.9 18.7 18.7C18.9 18.5 19 18.3 19 18C19 17.7 18.9 17.5 18.7 17.3L13.4 12L18.7 6.7C18.9 6.5 19 6.3 19 6C19 5.7 18.9 5.5 18.7 5.3Z"
                  fill="#1A1A1A"
                />
              </svg>
            </div>

            <div className="grid grid-cols-3 mb-6">
              <div className="flex items-center">
                <p className="">Wallet</p>
              </div>
              <div className="w-full col-span-2">
                <Select
                  options={paymentWalletOptions}
                  styles={customStyles}
                  value={selectedPaymentWallet}
                  onChange={(event) => handleValueChange("wallet_id", event)}
                />
              </div>
            </div>

            {/* <div className='grid grid-cols-3 mb-6'>
                            <div className='flex items-center'>
                                <p className=''>Schedule Time</p>
                            </div>
                            <div className='w-full col-span-2'>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        onChange={(event) => handleValueChange("scheduled_dt", event)}
                                        renderInput={(params) => (
                                            <TextField label="Date to" size="small" {...params} />
                                        )}
                                        value={frontEndScheduletime}
                                        className="w-full"
                                    // {...restField}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div> */}

            <div className="flex justify-end">
              <Button
                variant="contained"
                disabled={selectedPaymentWallet == null ? true : false}
                onClick={handleCreatingBatch}
              >
                Create Batch
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateBatchModal;
