import { ArrowDownward } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import { Collapse, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import CollapseTable from "./CollapseTable";

const CollapseTableRow = ({
  row,
  level,
  params,
  groupBy,
  setGroupBy,
  setParams,
  groupByColors,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        sx={{ bgcolor: groupByColors[groupBy]?.[100] }}
        {...row.getRowProps()}
        className="cursor-pointer"
        //   onClick={(event) => handleClick(event, row)}
        hover
      >
        <TableCell
          onClick={() => setOpen((o) => !o)}
          width="40px"
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          {open ? <ArrowDownward /> : <ArrowForward />}
        </TableCell>
        {row.cells.map((cell, k) => {
          return (
            <TableCell
              {...cell.getCellProps()}
              width={cell.column.width}
              key={k}
            >
              {cell.render("Cell")}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={row.allCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CollapseTable
              groupByColors={groupByColors}
              level={level}
              groupBy={""}
              setGroupBy={(s) => setGroupBy(s)}
              params={params}
              setParams={(s) => setParams(s)}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapseTableRow;
