import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
// import { current } from "@reduxjs/toolkit";
// import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import { forwardRef } from "react";

// const toTitleCase = (str = " ", splitter = "_", joiner = " ") => {
//   const realString = typeof str === "number" ? str.toString() : str;
//   return realString
//     ?.split(splitter)
//     ?.map((txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
//     ?.join(joiner);
// };

// const styles = StyleSheet.create({
//   page: { padding: 60 },
//   header: {},
//   box: { width: "100%", marginBottom: 30, borderRadius: 5 },
//   pageNumbers: {
//     position: "absolute",
//     bottom: 20,
//     left: 0,
//     right: 0,
//     textAlign: "center",
//   },
// });

const titleKey = {
  name: "Employee Name",
  id_card_number: "Employee ID",
  acc_number: "Account",
  phone_number: "Phone number",
  date: "Req. Date",
  charge_fee: "Service Charge",
  total_requested_amount: "Total Amount",
  withdraw_amount: "Disbursed Ammount",
  designation: "Designation",
  payment_method: "Payment Method",
};

const PDFRenderer = forwardRef(({ invoiceData, invoiceDataKeys }, ref) => {
  return (
    <Box sx={{ p: "60px" }} ref={ref}>
      <TableContainer sx={{ width: "595px" }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: 0 }}>
                <img
                  src="https://www.ogreem.com/images/logo.svg"
                  alt=""
                  style={{ height: "30px" }}
                />
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontSize: "28px",
                  fontWeight: "bold",
                  color: "#5063aa",
                }}
                align="right"
              >
                Invoice
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}></TableCell>
              <TableCell sx={{ border: 0, color: "#5063aa" }} align="right">
                Date: {`${invoiceData?.partner_info?.date}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }} align="left">
                To
              </TableCell>
              <TableCell sx={{ border: 0 }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: 0 }}
                align="left"
              >{`${invoiceData?.partner_info?.partner_name}`}</TableCell>
              <TableCell sx={{ border: 0 }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: 0 }}
                align="left"
              >{`${invoiceData?.partner_info?.partner_address}`}</TableCell>
              <TableCell sx={{ border: 0 }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {invoiceData?.req_lines && invoiceData?.req_lines?.length !== 0 ? (
        <TableContainer sx={{ width: "595px", mt: 1 }}>
          <Table size="small" sx={{ border: 1, borderColor: grey[200] }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={invoiceDataKeys.length}
                  sx={{
                    fontSize: "20px",
                    color: "#5063aa",
                    wordBreak: "break-all",
                  }}
                >
                  Ogreem Payment
                </TableCell>
              </TableRow>
              {/* {console.log(Object?.keys(invoiceData?.req_lines?.[0]))} */}
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "8px",
                    borderColor: grey[200],
                    // wordBreak: "break-all",
                    p: 1,
                    lineHeight: 1,
                  }}
                >
                  S.N
                </TableCell>
                {invoiceDataKeys
                  ?.filter(
                    (r) =>
                      ![
                        "remarks",
                        "payment_method_type",
                        "designation",
                      ].includes(r)
                  )
                  .map((key) => (
                    <TableCell
                      sx={{
                        fontSize: "8px",
                        borderColor: grey[200],
                        // wordBreak: "break-all",
                        p: 1,
                        lineHeight: 1,
                      }}
                      key={key}
                    >
                      {/* {toTitleCase(key)} */}
                      {titleKey[key]}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData?.req_lines?.map((inv, key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontSize: "8px",
                      p: 1,
                      lineHeight: 1,
                    }}
                  >
                    {key + 1}
                  </TableCell>
                  {Object?.keys(inv)
                    .filter(
                      (r) =>
                        ![
                          "remarks",
                          "payment_method_type",
                          "designation",
                        ].includes(r)
                    )
                    .map((k) => (
                      <TableCell
                        sx={{
                          fontSize: "8px",
                          p: 1,
                          lineHeight: 1,
                        }}
                        key={`${key}-${k}`}
                      >
                        {inv[k]} {k === "withdraw_amount" && " ৳"}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  colSpan={invoiceDataKeys.length - 6}
                  sx={{
                    fontSize: "10px",
                  }}
                >
                  Total:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "10px",
                    p: 1,
                  }}
                >
                  {`${invoiceData.req_lines.reduce(
                    (prev, current) => prev + current.withdraw_amount,
                    0
                  )}
                  ৳`}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "10px",
                    p: 1,
                  }}
                >
                  {`${invoiceData?.total_calculation?.total_fee} ৳`}
                </TableCell>
                <TableCell></TableCell> <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={invoiceDataKeys.length - 4}
                  sx={{
                    fontSize: "10px",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    fontSize: "10px",
                    p: 1,
                  }}
                >
                  {`${invoiceData.total_calculation.total_amount} ৳`}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      <Typography sx={{ color: "#5063aa", mt: 3, textAlign: "center" }}>
        Thank you for your business!
      </Typography>
    </Box>
  );
});

export default PDFRenderer;
