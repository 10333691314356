import { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import moment from "moment";
import CommonTable from "../../../components/common-table";
import Chip from "@mui/material/Chip";
// import RFITableTools from "../../../components/page-components/ready-for-invoice/RFITableTools";
import labels from "../../../utils/labels";
import { partnerEmployeeSalaryRequestData } from "../../../store/partnerSlice";
import TableTools from "../../../components/page-components/salary-request-list/TableTools";
import { Link, useSearchParams } from "react-router-dom";
import { Link as MUILink } from "@mui/material";

const ReadyForInvoice = () => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const {
    partnerEmployeeSalaryRequestList,
    partnerEmployeeSalaryRequestListLoading,
  } = useSelector((state) => state.partnerSlice);
  let [searchParams] = useSearchParams();

  const [filter_data, set_filter_data] = useState({
    offset: 0,
    limit: 10,
    status: "disbursed",
    partner_id: searchParams.get("partner_id"),
  })

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Partner",
        accessor: "partner_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee",
        accessor: "employee_name",
        className: "font-bold",
        sortable: true,
        Cell: ({ value, row }) => (
          <MUILink
            component={Link}
            to={`/admin/employee-profile/${row.original.employee.id}`}
          >
            {value}
          </MUILink>
        ),
      },
      {
        Header: "Employee Id",
        accessor: "employee.emp_id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Date Requested",
        accessor: "requested_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY HH:mm:ss a") : "-",
      },
      {
        Header: "Date Disbursed",
        accessor: "disbursed_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value !== null
            ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a")
            : "-",
      },
      {
        Header: "Requested Amount",
        accessor: "requested_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Ogreem Fee",
        accessor: "agrim_fee",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Amount to Disburse",
        accessor: "amount_to_disburse",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Available Amount",
        accessor: "available_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Paynemt Type",
        id: "payment_type",
        width: 100,
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.type.toUpperCase()}
          </div>
        ),
      },
      {
        Header: "Paynemt Method",
        id: "payment_method",
        width: 100,
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.name}
          </div>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_no",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Branch Name",
        accessor: "branch",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
        Cell: ({ value }) => <Chip label={value} color={labels[value]} />,
      },
    ],
    []
  );

   const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  useEffect(() => {

    set_filter_data({
      ...filter_data,
      offset: offset,
      limit: limit,
      status: "disbursed",
      partner_id: searchParams.get("partner_id"),
    })

    dispatch(
      partnerEmployeeSalaryRequestData({
        ...filter_data,
        offset: offset,
        limit: limit,
        status: "disbursed",
        partner_id: searchParams.get("partner_id"),
      })
    );
  }, [dispatch, searchParams, offset, limit]);

  const handleSettingFilterData = query => {
    set_filter_data(query)
  }

  return (
    <Grid container direction="column" spacing={3}>
      <CommonTable
        request_type="advance"
        columns={columns}
        data={partnerEmployeeSalaryRequestList}
        changePage={ changePage }
        offset={offset}
        limit={limit}
        handleClick={() => null}
        checkbox
        TableToolsComponent={TableTools}
        tableLoader={partnerEmployeeSalaryRequestListLoading}
        filter_data={filter_data}
        handleSettingFilterData= {handleSettingFilterData}
      />
    </Grid>
  );
};

export default ReadyForInvoice;
