import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
// import { useDispatch } from "react-redux";
import {
  // Await,
  // defer,
  // useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
// import { getSalaryRequestBatchInfo } from "../../../store/agrimSlice";
import { Chip } from "@mui/material";
import labels from "../../../utils/labels";
import CommonTable from "../../../components/common-table";
import _ from "lodash";
import BatchActionButtons from "../../../components/page-components/salary-batch-requests/BatchActionButtons";
// import { getAllBatchRequests } from "../queries";
// import { HandleRouteError } from "../../../ErrorBoundary";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBatch } from "../../../store/batchSlice";
import { useRef } from "react";

// export async function loader({ params }) {
//   console.log(params.batch_status.split("-")[0]);
//   const data = getAllBatchRequests({
//     status: params.batch_status.split("-")[0],
//   });
//   return defer({ salaryBatchInfo: data });
// }

const ReusabelBatchList = () => {
  const batch_status_ref = useRef(null)
  const { batch_status } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const { batchRequestLoading, allBatchData } = useSelector(state => state.batchSlice)


  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  useEffect(() => {
     console.log(batch_status_ref.current, batch_status)
     if(batch_status_ref.current !== null && batch_status_ref.current !== batch_status){
        batch_status_ref.current = batch_status;
        dispatch(fetchAllBatch({
        status: batch_status.split("-")[0],
        offset: 0,
        limit: 10,
       }))
       setOffset(0)
       setLimit(10)
     } else {
        batch_status_ref.current = batch_status
        dispatch(fetchAllBatch({
        status: batch_status.split("-")[0],
        offset: offset,
        limit: limit,
       }))
     }
    
  }, [dispatch, offset, limit, batch_status]);

  const columns = useMemo(() => {
    let cols = [
      {
        Header: "Id",
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Scheduled Date",
        accessor: "scheduled_dt",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Total Request",
        accessor: "total_request",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Wallet Name",
        accessor: "wallet_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Approval Date",
        accessor: "approved_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
        Cell: (val) => (
          <div className="flex items-center">
            <Chip
              label={val.value}
              color={labels[val.value]}
              id="demo-simple-select-label"
            />
          </div>
        ),
      },
      {
        id: "actions",
        Header: "Actions",
        accessor: "status",
        className: "font-bold",
        sortable: false,
        Cell: (val) => (
          <BatchActionButtons
            val={val}
            batch_status={batch_status.split("-")[0]}
          />
        ),
      },
    ];
    if (batch_status.split("-")[0] === "disbursed") {
      cols.splice(7, 0, {
        Header: "Disbursed Date",
        accessor: "disbursed_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      });
    }
    if (batch_status.split("-")[0] === "cancelled") {
      cols.splice(7, 0, {
        Header: "Cancelled Date",
        accessor: "cancelled_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      });
    }
    return cols;
  }, [batch_status]);

  const handleGoingToIndividualBatchRequest = (row) => {
    const name = row.original.name
      .trim()
      // eslint-disable-next-line no-useless-escape
      .replace(/[\. ,:-]+/g, "-")
      .toLowerCase();
    const URL = `/admin/salary-request-batch/batch-info/${name}`;
    navigate(URL, {
      state: {
        batchId: row.original.id,
        batchType: batch_status.split("-")[0],
      },
    });
  };

  return (
    <div>
        <p className="text-xl font-bold mb-8">{batch_status.split("-")[0]} Batch List</p>
        <CommonTable
          request_type="advance"
          offset={offset}
          limit={limit}
          changePage={changePage}
          columns={columns}
          data={allBatchData}
          tableLoader={batchRequestLoading}
          handleClick={(_, row) =>
            handleGoingToIndividualBatchRequest(row)
          }
        />
        {allBatchData.length <= 0 && <p>No {batch_status.split("-")[0]} Batch</p>}
    </div>
  )
};

export default ReusabelBatchList;
