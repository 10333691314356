import { APIRequestServices } from "../../service-pattern";
import { store } from "../../store";
import { resetAuth } from "../../store/authSlice";

export const getDashboardReports = async (params) => {
  try {
    const { data, status } = await new APIRequestServices().Get({
      URL: `app/advance_requests/get_dashboard_reports`,
      getRawResponse: true,
      data: params,
    });
    if (status === 401) {
      sessionStorage.removeItem("token");
      store.dispatch(resetAuth());
    }
    return data;
  } catch (error) {
    throw error;
  }
};
