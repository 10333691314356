import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  // Chip,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // OutlinedInput,
  // Select,
} from "@mui/material";
import { blue, green, indigo } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CollapseTable from "../../components/common-table/CollapseTable";
import { getPaymentMethodList } from "../../store/agrimSlice";
import { partnerGetAll } from "../../store/partnerSlice";
import { removeEmptyValues } from "../../utils/helper";

const groupByColors = {
  id_number: green,
  employer_pay_cycle: indigo,
  partner_id: blue,
};

const Reports = () => {
  const dispatch = useDispatch();
  const { partnerList } = useSelector((state) => state.partnerSlice);

  const [, setGroupBy] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [params, setParams] = useState({});
  let [searchParams] = useSearchParams();
  // console.log(searchParams.get("groupBy"));

  // const handleGroupByChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setGroupBy(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  useEffect(() => {
    dispatch(partnerGetAll());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getPaymentMethodList());
  }, [dispatch]);
  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "Employee Name",
  //       accessor: "employee_name",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "ID Number",
  //       accessor: "id_number",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //       aggregate: "count",
  //       Aggregated: ({ value }) => `Group: (ID Number) ${value}`,
  //     },
  //     {
  //       Header: "Gender",
  //       accessor: "gender",
  //       className: "font-bold",
  //       sortable: true,
  //       aggregate: "count",
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Employer Pay Cycle",
  //       accessor: "employer_pay_cycle",
  //       className: "font-bold",
  //       sortable: true,
  //       aggregate: "count",
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Number of Days Worked",
  //       accessor: "number_of_days_worked",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Salary Earned",
  //       accessor: "salary_earned",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Withdrawable Amount",
  //       accessor: "withdrawable_amount",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Requested Amount",
  //       accessor: "requested_amount",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Carry Over Amount",
  //       accessor: "carry_over_amount",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "MFS Wallet",
  //       accessor: "mfs_wallet",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Bank Account",
  //       accessor: "bank_account",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     // {
  //     //   Header: "Date Requested",
  //     //   accessor: "requested_at",
  //     //   className: "font-bold",
  //     //   sortable: true,
  //     {
  //       Header: "Request Date",
  //       accessor: "requested_date",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) =>
  //         val.value ? moment(val.value).format("DD/MM/YYYY hh:mm:ss a") : "-",
  //     },
  //     {
  //       Header: "Disbursement Date",
  //       accessor: "disbursement_date",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Disbursement Amount",
  //       accessor: "disbursement_amount",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Service Charge",
  //       accessor: "service_charge",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Cost of Finance",
  //       accessor: "cost_of_finance_amount",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? Number(val.value.toFixed(2)) : "-"),
  //     },
  //     {
  //       Header: "Net Revenue",
  //       accessor: "net_revenue",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? Number(val.value.toFixed(2)) : "-"),
  //     },
  //     {
  //       Header: "Payment Date",
  //       accessor: "expected_payment_date",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Actual Reimnursement Date",
  //       accessor: "actual_reimnursement_date",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Penalty Interest",
  //       accessor: "penalty_interest",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Penalty Amount",
  //       accessor: "penalty_amount",
  //       className: "font-bold",
  //       sortable: true,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "status",
  //       className: "font-bold",
  //       sortable: true,
  //       // Cell: ChangeStatusActions,
  //       Cell: (val) => (val.value ? val.value : "-"),
  //     },
  //   ],
  //   []
  // );
  return (
    <Box>
      {/* <FormControl sx={{ width: 300, my: 1 }} size="small">
        <InputLabel id="group-by-select-label">Group By</InputLabel>
        <Select
          labelId="group-by-select-label"
          id="group-by-select"
          multiple
          value={groupBy}
          onChange={handleGroupByChange}
          input={<OutlinedInput label="Group By" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{ bgcolor: groupByColors[value][100] }}
                  // onDelete={() => {
                  //   setGroupBy(
                  //     typeof value === "string" ? value.split(",") : value
                  //   );
                  // }}
                />
              ))}
            </Box>
          )}
        >
          {[
            { label: "Employee ID Number", value: "id_number" },
            { label: "Employer Pay Cycle", value: "employer_pay_cycle" },
            { label: "Partner", value: "partner_id" },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      {searchParams.get("groupBy") !== "partner_id" && (
        <FormControl sx={{ width: 300, my: 1 }} size="small">
          <InputLabel id="partner-select-label">Partner</InputLabel>
          <Select
            labelId="partner-select-label"
            id="partner-select"
            value={partnerId}
            label="Partner"
            onChange={(e) => {
              setPartnerId(e.target.value);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {partnerList.map((partner) => (
              <MenuItem key={partner.id} value={partner.id}>
                {partner.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <CollapseTable
        groupByColors={groupByColors}
        level={0}
        groupBy={searchParams.get("groupBy")}
        setGroupBy={(s) => setGroupBy(s)}
        params={removeEmptyValues({ ...params, partner_id: partnerId })}
        setParams={(s) => setParams(s)}
      />
    </Box>
  );
};

export default Reports;
