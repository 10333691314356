import { Container, Box } from "@mui/material";
import React from "react";

const TermsAndCondition = () => {
  const currentDate = new Date();
  return (
    <Container className="w-full">
      <Box sx={{ px: 2 }}>
        <div>
          <p className="text-xl font-bold text-center my-8">
            OgreemTerms and Conditions
          </p>

          <p className="text-lg font-bold text-center my-8">
            Published and effective on 21 March 2022
          </p>

          <p className="mb-6">
            This OgreemTerms and Conditions (“Terms and Conditions”) is a valid
            electronic agreement between you (“You” or “Your” or “Employee” as
            defined below) and Ura Tech Ltd. (“Ogreem” or “We” or “Us”)
            governing Your access to the OgreemApp and the use of the Employee
            Services.
          </p>

          <p className="mb-6">
            <span className="font-bold">1. About Us</span> <br />
            OgreemApp is a service owned by Ura Tech Ltd., engaged in the
            business of information technology which was established under the
            applicable laws in the People of Bangladesh.
          </p>

          <p className="mb-6">
            <span className="font-bold">2. Definition and Interpretation</span>
            <br />
            “Activity” means a record available in the OgreemApp of any activity
            You undertake through the OgreemApp.
            <br />
            “Account” means a Ogreemmembership account to access and use the
            OgreemApp.
            <br />
            “Membership Fee” means the membership fee charged to the Employee
            for each Successful EWP at any given time as specified in the
            OgreemApp at the time You instruct the EWP.
            <br />
            “Coin Account” means a record of the total earned Coins in
            connection with Your Account for the purpose of determining when You
            are entitled to make a claim for a Reward.
            <br />
            “Personal Data” means personal documents, personal information,
            and/or other equivalent information based on the statutory
            provisions in the field of data protection in Bangladesh that make a
            person identifiable or identifiable.
            <br />
            “EWA” means earned wage access—Employee access to the amount of
            wages earned but not yet paid (including salaries, benefits, etc.)
            via the OgreemApp.
            <br />
            “EWP” means the payment of wages earned—each available EWA payment
            request as instructed by the Employee via the OgreemApp. EWP is a
            non-credit transaction and debt free transaction.
            <br />
            “Successful EWP” means each successful EWP as notified in the
            OgreemApp.
            <br />
            “Rewards” means Coupons or other benefits that You earn through the
            redemption of accumulated Coins.
            <br />
            “Applicable Law” means all applicable laws, statutes, rules,
            regulations, guidelines, policies, discretion, directives and other
            statements made by state or county governments and/or regulatory
            authorities having the force of law in the People of Bangladesh.
            <br />
            “Employee” means an employee who: (1) works for the Employer and is
            recognized by the Employer as its employee; (2) bound by an
            employment agreement with the Employer based on laws concerning
            Manpower and its amendments as well as other applicable laws and
            regulations relating to manpower (unless otherwise notified by the
            Employer) in Bangladesh; and (3) their payment of wages is managed,
            supervised and controlled by the Employer in relation to human
            resources payroll system and procedures.
            <br />
            “OTP Code” means the authentication code sent to Your mobile number
            via SMS (short message services) or whatsapp in the form of text
            and/or numbers for verification purposes.
            <br />
            “Reference Code” means a unique code generated for You as a Program
            participant.
            <br />
            “Coins” means coins that are added to or removed from the Coin
            Account in accordance with this Terms and Conditions.
            <br />
            “Coupon” means coupons or product vouchers or discounts to be spent
            on the OgreemApp.
            <br />
            “Employee Services” means the services available on the OgreemApp
            and included in the Program, whether owned and managed by
            Ogreemitself or by an Official Partner accessible through the
            OgreemApp.
            <br />
            “Official Partner” means a party that has a cooperation with
            Ogreemas one of the service providers of the Employee Services on
            the OgreemApp.
            <br />
            “Parties” means Ogreemand You.
            <br />
            “Employer” means the business entity that employs You and pays Your
            wages, directly or indirectly through a third party and has
            collaborated with Ogreem.
            <br />
            “EWP Withdrawal to Bank Account” means EWP to the bank account on
            behalf of Your name which is used to receive wages from Employer.
            <br />
            “EWP Withdrawal to Electronic Money” means EWP to Electronic Money
            on behalf of You and/or other recipients that You wish to address.
            <br />
            “Privacy Notice” means a Ogreemprivacy notice in
            https://www.Ogreem.app/privacy/ or as available in the website.
            <br />
            “PIN” means the personal identification number created by You and
            associated with Your Account to enable You to log into Your Account
            and use the Employee Services.
            <br />
            “Program” means membership program.
            <br />
            “Referees” means those who are referred or invited to create an
            Account.
            <br />
            “Referrers” means those who refer or invite to create an Account.
            <br />
            “Colleagues” means those You work with at the same Employer who do
            not have an Account.
            <br />
            “Electronic Money” means electronic money issued and managed by
            electronic money operators registered and licensed at Bank
            Bangladesh.
            <br />
            “OgreemApp” means an online, web, desktop application
            https://web.Ogreem.app ("Web App") and/or mobile application related
            to the provision of Employee Services.
            <br />
            “OgreemLoyalty Rewards” means the loyalty rewards feature associated
            with the Program available on the OgreemApp.
            <br />
          </p>

          <p className="mb-6">
            <span className="font-bold">3. General Terms</span>
            <br />
            You acknowledge that You have a thorough understanding of the
            contents of this Terms and Conditions and the Privacy Notice.
            <br />
            You will not challenge the validity of this Terms and Conditions,
            Privacy Notice, and/or other terms and conditions in relation to the
            Employee Services on the OgreemApp and/or the https://www.Ogreem.app
            site.
            <br />
            EWP can only be completed in BDT.
            <br />
            You, without our prior written consent, cannot assign this Agreement
            to any party. Any assignment by You in violation of this clause will
            be cancelled.
            <br />
            The maximum and minimum limit of EWP that can be withdrawn by You
            are as set forth and available in Your Account.
            <br />
            If any provision of this Terms and Conditions and the Privacy Notice
            is found to be invalid or unenforceable, the Parties still agree
            that such provision will only be ineffective to the extent of the
            invalidity or the unenforceable referred to without annulling the
            other provisions of this Agreement which may continue to exist,
            valid, and binding between the Parties.
            <br />
            The Terms and Conditions contained in this page is protected by
            copyright. Any use of part or the entire of this Terms and
            Conditions by third parties is not permitted, unless with our
            permission or is permitted under the Applicable Law.
            <br />
            This agreement is drawn up in English and Bangladesh. If there is
            any discrepancy or conflict between the Bangladesh text version and
            the English text version, the Bangladesh text version shall prevail,
            and the English text version shall be automatically amended in
            accordance with the Bangladesh text version.
          </p>

          <p className="mb-6">
            <span className="font-bold">
              4. Your Consent to the Terms and Conditions
            </span>
            <br />
            By pressing the 'register' button when registering an Account,
            pressing the 'log in' button when logging into an Account, pressing
            ‘I agree’ button when instructing EWP on the OgreemApp, accessing,
            using, participating in, or receiving benefits from the Employee
            Services means You actively consent to enter into this agreement
            with Us and that You have read, understood, agreed to, and to be
            bound by this Terms and Conditions and the Privacy Notice. If
            necessary, We and/or the Official Partner may ask You to agree to
            additional terms and conditions as linked to on the OgreemApp in
            order to process Your instructions or requests through the
            OgreemApp. You have full discretion over any decisions and
            instructions to use or continue to use the Employee Services through
            the OgreemApp.
          </p>

          <p className="mb-6">
            <span className="font-bold">
              5. Changes to Terms and Conditions
            </span>
            <br />
            We reserve the right, at any time and in its sole discretion, to
            change the service and/or modify the Terms and Conditions and/or
            terminate the Employee Services and/or cancel, suspend or limit Your
            participation or Your activity in the OgreemApp, without prior
            notice and without accountability. Notwithstanding the foregoing, We
            may provide notice within a reasonable period of time through the
            OgreemApp of any changes, which in its reasonable discretion are
            deemed material and necessary to the Terms and Conditions. Changes
            to the Terms and Conditions will be published and available from
            time to time in the Terms and Conditions section of the OgreemApp
            and will be effective as of the first time You continue to use the
            services on the OgreemApp.
          </p>

          <p className="mb-6">
            <span className="font-bold">6. Account</span>
            <br />
            Account Registration Every Employer who wishes to use the Employee
            Services needs to register for an Account on the OgreemApp by
            downloading the mobile application version at
            https://www.Ogreem.app/ or visiting the Web App.
            <br />
            At the time of registration, You are required to provide true,
            accurate, current and complete Personal Data.
            <br />
            After registration, We will first verify, among others, by sending
            an OTP Code. You must enter the OTP Code to complete the Account
            registration process. We cannot guarantee the success of creating an
            Account and cannot be held responsible for anything if it is due to
            an error in entering information and/or data by the Employee.
            <br />
            The mobile number used for Account registration must be Yours and
            not owned by any other party. We reserve the right to determine the
            number of mobile numbers that can be used by You to register an
            Account, which will be notified to You through the OgreemApp.
            <br />
            You can change the mobile number in Your Account by following the
            instructions given on the OgreemApp. We will send an OTP Code to the
            new mobile number and it must be entered by You for the mobile
            number change to be successful. If the verification code is not
            entered or failed to be entered then the mobile number change will
            not be successful.
            <br />
            Account Access You understand that in order to access the Employee
            Services through the OgreemApp, You must first 'log in' using the
            registered mobile number and input Your PIN or other authentication
            method.
            <br />
            You are solely responsible for Your Account and all Activities on
            Your Account.
            <br />
            Temporarily Inactive Account We may temporarily deactivate Your if
            You are no longer Employee of the Employer.
            <br />
            You may reactivate Your Account when You become an Employee at the
            new Employer. Account reactivation can be done by ‘log in’ to the
            Account or in accordance with other instructions as set forth in the
            OgreemApp.
            <br />
            Temporary or Permanently Blocked Account The account will be
            temporarily or permanently blocked if one of the following
            conditions is met: You are suspected of violating this Terms and
            Conditions and/or the Privacy Notice so that an investigation is
            deemed necessary by Us.
            <br />
            Based on Our judgment and discretion, You perform Activities outside
            the limits of reasonable use.
            <br />
            We receive Your report that Your Account is used or suspected to be
            used or misused by another person.
            <br />
            Part of or the entire Personal Data that You submit is untrue,
            inaccurate, not current or incomplete.
            <br />
            We receive Your report that Your mobile phone is lost or stolen by
            another party. All instructions via mobile or mobile number prior to
            receipt of such notification of lost shall remain Your sole
            responsibility.
            <br />
            You have passed away, been placed in guardianship or custody or
            otherwise experienced a disability that renders You legally
            incapacitated under the Applicable Law.
            <br />
            Conducting Activities on the OgreemApp that is detrimental to Us,
            Official Partner, and/or You.
            <br />
            You are suspected of having committed immoral acts, violations,
            crimes, or other actions that are contrary to this Terms and
            Conditions and/or Applicable Law, whether specified in this Terms
            and Conditions or not, including
            <br />
            Verbal harassment or violence, including but not limited to,
            physical, gender, ethnic, religious and racial abuse by You against
            an Employee Service provider;
            <br />
            Fraudulent activity; Use of the OgreemApp on rooted or jailbroken
            devices;
            <br />
            Harassment or physical violence by You against a provider of
            Employee Services;
            <br />
            Sexual harassment or violence, whether verbal or physical, by You
            against a provider of Employee Services.
            <br />
            You agree and acknowledge that Our decision with respect to
            provisions 6.3 and 6.4 is final, cannot be contested, and legally
            binding on You, unless You can prove otherwise.
          </p>

          <p className="mb-6">
            <span className="font-bold">
              7. Provision and Use of Employee Services
            </span>
            <br />
            Employee services available on the OgreemApp include:
            <br />
            access to EWA information.
            <br />
            EWP withdrawal made by sales method including but not limited to EWP
            Withdrawal to Bank Account, EWP Withdrawal to Electronic Money, and
            EWP withdrawal for certain payment transactions.
            <br />
            The terms of use of the Employee Services are as follows:
            <br />
            You shall follow all instructions on the selected Employee Services.
            <br />
            You shall maintain the security of Your Account. We receive and
            carry out every instruction from You through the OgreemApp as a
            valid instruction. We have no obligation to examine or investigate
            the authenticity or validity or authority of the use of, among
            others, Accounts, mobile phone numbers, PINs, OTP codes or assess or
            prove the accuracy or completeness of the instructions in question.
            Therefore, all instructions or Activities in Your Account are valid
            and legally binding on You, unless You can prove otherwise.
            <br />
            All Activities that have been instructed by You to Us cannot be
            cancelled for any reason. You as the account owner are responsible
            for all consequences arising from it. You understand and agree that
            we cannot and will not be held liable for Your errors and
            negligence.
            <br />
            You are required to upgrade the OgreemApp version as requested by Us
            from time to time. If You neglect to upgrade, it will result in You
            being unable to use the Employee Services to its full potential or
            only being able to access certain features in the OgreemApp and You
            agree to be fully responsible for such negligence.
            <br />
            EWP withdrawal is an event of transfer or sale of material rights,
            namely the transfer or sale of part or the entire of the wage rights
            that You have earned but have not been paid by the Employer, to the
            buyer of EWP through the OgreemApp. Any EWP instructions on the
            OgreemApp will be treated as equivalent to a written and authentic
            order that complies with the provisions of Applicable Law. You
            acknowledge and agree that for each Successful EWP means that You
            have agreed to sell, transfer, assign and deliver to the buyer of
            the EWP a certain amount of Your wages equal to the Successful EWP
            at any given time. Thus, the buyer of the EWP becomes the legal
            recipient of the amount and is therefore entitled to receive
            payments that must be paid right when or after You receive wages
            from the Employer. Details regarding EWP withdrawal is contained in
            the EWP withdrawal details which are an integral part of these Terms
            and Conditions.
            <br />
            You acknowledge and agree that by nature, the Employee Services are
            for the benefit of the Employee managed by the Employer. So based on
            the Employer's internal provisions or a separate agreement (if any
            and necessary), the Employer has the right to use and share Your
            Personal Data to Us limited for the implementation of the Employee
            Services.
            <br />
            For each Successful EWP withdrawal made on the OgreemApp, You will
            receive a notification and proof of transaction with a reference
            number therein recorded in the Activity. We will perform cleaning of
            the Activity history from time to time. Thus, make sure You have
            saved the required proof of transaction for Your reference.
            <br />
            We reserve the right to refuse or not to carry out EWP instructions
            from You, if the number of EWA is not sufficient to carry out the
            relevant transaction or has not been accessed or has passed the
            specified withdrawal time limit or the destination number is not
            found / does not exist. In the case of EWP Withdrawal to Electronic
            Money, You must be responsible for ensuring the accuracy and
            completeness of the intended mobile number that You enter into the
            OgreemApp as well as the suitability of the name of the Electronic
            Money account holder. We are not responsible for any consequences
            arising from inaccuracies, completeness, or errors that You make.
            <br />
            You agree and acknowledge the validity, correctness or authenticity
            of any instructions through Your Account, evidence of Successful EWP
            transactions and Activity history on the OgreemApp as the only valid
            and binding evidence of instructions through Your Account and EWP
            transactions that Successfully done by You, unless You can prove
            otherwise. You acknowledge that by nature the Employee Services is
            information technology-based where all instructions through Your
            Account, evidence of Successful EWP transactions, and Activity
            history recorded and stored in electronic form are valid evidence
            and legally binding on You.
            <br />
            In the event that You provide EWP instructions for certain payment
            transactions provided by an Official Partner, then You will also be
            subject to other terms and conditions of the Official Partner which
            You can read before You make that particular payment transaction. If
            certain payment transactions are routine or recurring then You
            acknowledge and agree to have given EWP instructions in routine and
            automatically the first time You make certain payment transactions
            which cannot be withdrawn until the payment obligation is completed
            and/or no cancellation (if relevant to the selected Employee
            Services .<br />
            We reserve the right to close part or the entire of the Employee
            Services that You can access on the OgreemApp in the event of a
            violation of this Terms and Conditions and/or the provisions of
            Applicable Law.
            <br />
            We reserve the right to close part or the entire of the Employee
            Services with written notice to You through available communication
            media and as set forth in this Terms and Conditions.
            <br />
          </p>

          <p className="mb-6">
            <span className="font-bold">8. Use of Data</span>
            <br />
            You agree and give consent to Employer as contained in the consent
            form and/or Us to collect, use, disclose, request any additional
            data, and process Your data and/or Personal Data as necessary and
            limited for the purposes, including but not limited to the following
            matters: Perform Our obligations with respect to this Terms and
            Conditions;
            <br />
            Provide You with Gifts, products and services in accordance with
            this Terms and Conditions;
            <br />
            Process Activities in accordance with this Terms and Conditions;
            <br />
            Develop and improve various Employee Services related programs on
            the OgreemApp in accordance with this Terms and Conditions; and
            <br />
            Internal administration and monitoring purposes, such as internal
            control and data analysis.
            <br />
            You agree that You are obliged to:
            <br />
            Continue to maintain and promptly update such information to keep it
            true, accurate, updated and complete.
            <br />
            Provide any additional data requested by Us through the OgreemApp or
            other channels as informed by Us through the communication channels
            as set forth in this Terms and Conditions which use is limited to
            the implementation of the Employee Services. Learn more about how We
            use and protect Your Personal Data at Our Privacy Notice.
            <br />
            You hereby agree that Your failure to provide true and accurate
            Personal Data will make You liable for any losses incurred,
            including losses from Us and other third parties. In addition, for
            such negligence, we are authorized to decide regarding the
            continuity of Your account and access to the Employee Services.
            <br />
          </p>

          <p className="mb-6">
            <span className="font-bold">9. OgreemLoyalty Rewards</span>
            <br />
            OgreemLoyalty Rewards are open to and can be participated by
            Employee who already have an Account.
            <br />
            Conditions for earning Coins: Rewards are determined by the number
            of Coins You earn as a result of any challenge or mission to earn
            Coins in the OgreemApp ("Qualified OgreemChallenge") successfully
            performed by You. Coins will be credited and added to Your Coin
            Account no later than 48 (forty eight) hours but not later than 30
            (thirty) calendar days.
            <br />
            You can start earning Coins by completing the Qualified
            OgreemChallenge as outlined below:
            <br />
            Invite Colleagues to create Accounts
            <br />
            Coins to earn: For each Successful Referral, both Referrers and
            Referees will receive a certain amount of Coins as specified in the
            OgreemApp.
            <br />
            How it works: Log in to Your Account, sign up for the Program and
            start sharing Your Referral Code with as many Referees as You want.
            Reference Code shall not be published or distributed on commercial
            or public websites.
            <br />
            Invite Referees to make transactions
            <br />
            Coins to earn: For Successful EWA Referees, Referrers will receive
            certain amount of Coins as specified in the OgreemApp.
            <br />
            How it works: Invite Referees to withdraw EWP periodically. Your
            Coin Record will be updated when Referees have created a Successful
            EWP.
            <br />
            You are responsible to ensure that Your Coins are properly credited.
            Any claim for Coins that are not accurately credited must be
            notified to Us within 7 (seven) calendar days from the date of the
            Coins credit in the Coin Account, otherwise, You are deemed to have
            reviewed and approved the credited Coins.
            <br />
            You will not earn Coins if Your Account is having one of the
            following reasons (1) temporarily inactive; (2) suspended; or (3)
            not participate in the Program yet.
            <br />
            Coins may not be assigned, transferred, sold or encumbered for any
            reason. Coins are not property and can only be used to claim Rewards
            on the respective Account. Any Coins remaining under the Coin
            Account respective to Your Account will be forfeited without
            compensation if OgreemLoyalty Rewards are cancelled or terminated.
            There will be no assignment or transfer of Coins to other programs,
            unless otherwise determined by Us and at Our sole discretion.
            <br />
            The terms of Coin redemption, use of Rewards, and applicable
            taxation are as follows: Only You who participate in OgreemLoyalty
            Rewards that can redeem Coins and claim Rewards. Your right to claim
            Rewards is based on the Coin Account attached to Your Account. Total
            Coins must be higher or equal to the desired Rewards.
            <br />
            You may choose Your Rewards by browsing the Rewards section of the
            OgreemApp. Once You have selected a Reward, You can claim it by
            pressing the "Apply Now" button. You will receive confirmation that
            the Reward has been claimed and the Coins used to claim the Reward
            will be deducted from Your Coin Record when We receive Your request
            to claim the Reward.
            <br />
            The Coupon You claim shall be valid for 12 (twelve) months from the
            date of claim. Coupons that remain unused after expiration shall be
            cancelled.
            <br />
            You may not be able to use Your Coupon if Your Account experiences
            any of the following reasons (1) is temporarily inactive; atau (2)
            blocked.
            <br />
            You can only use 1 (one) Reward at a time. You must perform a
            Successful EWP each time before using the rewards.
            <br />
            You are responsible for any applicable taxes on the Rewards under
            Applicable Laws as We inform You from time to time in the OgreemApp
            at the time You claim Your Rewards.
            <br />
            Employee of Ogreemcannot participate in OgreemLoyalty Rewards as
            Referrers or Referees.
            <br />
          </p>
          <p className="mb-6">
            <span className="font-bold">10. Security</span>
            <br />
            PIN, OTP Code, and/or other authentication methods must be kept
            confidential by You and may only be used by You, by:
            <br />
            Not revealing Your PIN, OTP Code or other authentication methods to
            others including Your family members or closest people;
            <br />
            Not storing the PIN or other authentication method on mobile phones,
            other objects or any means that allow the PIN or other
            authentication method to be known;
            <br />
            Being careful in using the PIN, OTP Code or other authentication
            methods when making transactions or giving instructions on the
            OgreemApp so that they are not visible to others;
            <br />
            Not using a PIN or other authentication method that is easily
            guessed by others.
            <br />
            A PIN is created and only known to You. We have never and will never
            ask for Your PIN number for any reason.
            <br />
            Any misuse of Your PIN, OTP Code, and/or other authentication
            methods is Your sole responsibility. You hereby release Us from all
            claims that arise, both from other parties and yourself as a result
            of misuse of Your PIN, OTP Code and/or other authentication methods.
            <br />
            The use of the PIN, OTP Code, and/or other methods on the OgreemApp
            is an electronic order that has binding legal force.
            <br />
            You can change Your Account PIN at any time by following the steps
            on the OgreemApp.
            <br />
            You must ensure that the mobile phone used for the OgreemApp is free
            from viruses, malware, and/or things that can harm You.
          </p>

          <p className="mb-6">
            <span className="font-bold">
              11. Membership Fee and Payment Obligations
            </span>
            <br />
            The download of the OgreemApp is free of charge.
            <br />
            We may charge a Membership Fee for each EWP withdrawal within a
            certain time which You are required to pay together with the
            Successful EWP payment to the EWP buyer through Us. We and/or
            Official Partner may change or update Membership Fee from time to
            time at Our sole discretion and depending on the type of Employee
            Services You select in the OgreemApp.
            <br />
            In connection with the settlement of payment of the number of
            Successful EWP and Membership Fee that You are required to make, You
            acknowledge and agree that You have authorized the Employer, Us,
            and/or the relevant bank to deduct a certain amount from the wages
            You receive as a form of payment to the extent for Successful EWP
            and Membership Fee until Your obligations on and/or through Us are
            completed.
            <br />
            Notwithstanding the above provisions, You may be released from the
            obligation to pay the Membership Fee if the Employer agrees to bear
            the Membership Fee.
          </p>

          <p className="mb-6">
            <span className="font-bold">
              12. Ownership and Intellectual Property
            </span>
            <br />
            You understand and agree that the OgreemApp, Employee Services and
            any necessary tools used in connection with the Services ("Devices")
            contain intellectual property rights and/or confidential
            information, and other material protected by applicable intellectual
            property rights and laws. others (including, but not limited to,
            copyrights, trademarks, service marks and patents). You acknowledge
            that You do not acquire ownership rights to any intellectual
            property through the use of the Employee Services. Except as
            expressly permitted by Us, You may not modify, distribute, create
            derivative works ostensibly issued by Us, license the OgreemApp,
            Employee Services, and Devices in whole or in part.
            <br />
            Any proposal, feedback, suggestions, and/or criticisms that may
            contain ideas that You convey to Us via or to the electronic mail
            address contained in the Ogreemapp, are not intellectual property.
            All Employee Services and any Employee Services improvements made
            based on Your proposal, feedback, suggestions, and/or criticisms are
            entirely ours. As long as it does not conflict with Applicable Law,
            none of this Terms and Conditions shall be construed as granting any
            commercial license from Us to You in connection with the
            Ogreemenhancement of the App and Employee Services owned and managed
            by Us.
            <br />
            You represent that every proposal, feedback, suggestion, and/or
            criticism to Us is true and accurate submitted by You based on Your
            own experience using the OgreemApp and/or Employee Services and
            submitted by You directly using Your Account. We reserve the right
            to refuse, accept, publish, display, modify, shorten, delete, or
            hide suggestions, feedback, suggestions, and/or criticisms that You
            submit through the OgreemApp in accordance with Our own policies.
            <br />
            You may not copy, modify, adapt, translate, create derivative works
            from, distribute, license, sell, reproduce, decompile any part of or
            otherwise exploit the OgreemApp and Employee Services.
          </p>

          <p className="mb-6">
            <span className="font-bold">13. Promotion and Marketing</span>
            <br />
            We reserve the right to place certain advertisements and offer
            certain promotions related to Employee Services to You on the
            OgreemApp, either by Us or together with Our partners. You
            acknowledge and agree to be willing to accept all promotions and
            advertisements related to Employee Services that will be sent or
            shared by Us to You through OgreemApp, email, SMS, and/or other
            methods.
            <br />
            You have a full discretion to participate or not participate in the
            promotions or advertisements that We send You. You can unsubscribe
            from Ogreempromotions or advertisements by pressing the
            'unsubscribe' button and following our next steps.
          </p>

          <p className="mb-6">
            <span className="font-bold">14. Official Partner Services</span>
            <br />
            Official Official Partner services may be available on the OgreemApp
            and become part of the Employee Services on a cooperative basis. You
            may be asked to agree to other terms and conditions, depending on
            the type of service the Official Partner provides.
            <br />
            We are not responsible for and cannot be held responsible for the
            services of an Official Partner, even if You are offered or become
            aware of a program or service through the OgreemApp. Any activity
            with an Official Partner service and any other terms and conditions,
            warranties or representations relating to such activity, is between
            You and the Official Partner.
            <br />
            We may provide links to other sites managed by Official Partner
            and/or other parties, You acknowledge and agree that such actions
            are Your voluntary actions to view or visit the sites of Official
            Partner and/or other parties who Ogreemhave no oversight or
            responsibility responsible for the content.
          </p>

          <p className="mb-6">
            <span className="font-bold">15. Link to Social Media</span>
            <br />
            If You choose to use the function of linking to social media
            platforms when using the Employee Services, You acknowledge and
            agree to comply with the relevant terms and conditions of each such
            social media platform. The terms and conditions of social media can
            be seen on each social media platform.
            <br />
            This functionality is intended to allow You to post newsfeeds about
            Your activity to each of those social media platforms from the
            OgreemApp. Social media platforms linked to the OgreemApp shall be
            based on Your consent.
            <br />
            You acknowledge and agree that we are not responsible for the
            availability of access and guarantee that each system and/or network
            of social media platforms runs without interruption.
            <br />
            You represent that You are fully responsible for any writing content
            that You share on social media platforms that are linked to the
            OgreemApp and any consequences arising from it You can be
            responsible for yourself without involving and harming Us.
          </p>

          <p className="mb-6">
            <span className="font-bold">
              16. Representations and Warranties
            </span>
            <br />
            You hereby represent and warrant that:
            <br />
            You are an Bangladeshi citizen and at least 18 (eighteen) years old
            or married;
            <br />
            Account registration is carried out by You of Your own volition and
            is limited to one Account per employee ID or ID card; and
            <br />
            You have good faith in using the Employee Services for legitimate
            purposes and not in contravention of the Applicable Law.
            <br />
            You hereby represent and warrant that any information submitted to
            Us is true, accurate, updated, and not misleading.
            <br />
            This Terms and Conditions is legally binding on You.
          </p>

          <p className="mb-6">
            <span className="font-bold">17. Force Majeure</span>
            <br />
            In the event that we are unable to carry out instructions from You,
            either partially or wholly due to events or things beyond Our
            control or ability, including but not limited to riots, disease
            outbreaks, fires, floods, earthquakes, strikes, wars, government,
            act of terrorism, lockout, general failure of electricity or other
            supply, technical failure, mechanical or computer accident or
            failure, breach of security in violation of Applicable Law
            (hacking), or any reason beyond the control of such party which
            directly and indirectly materially prevents the Parties from
            performing or in other ways fulfil their obligations under this
            Terms and Conditions ("Force Majeure"), then You hereby release Us
            from all kinds of claims of any kind whatsoever related to this
            matter.
          </p>

          <p className="mb-6">
            <span className="font-bold">18. Prohibition</span>
            <br />
            While using the Employee Services, You are prohibited from
            performing one or more of the following actions:
            <br />
            Inhibit or interfere with other users' use of the Employee Services;
            <br />
            Conduct activities violating Applicable Laws that affect the
            OgreemApp or Our brand;
            <br />
            Disturbing the public by violating public order, morals, and/or
            recognized customs;
            <br />
            Use the information and data You receive regarding Employee Services
            for purposes other than those specified in this Terms and Conditions
            and the Privacy Notice;
            <br />
            Actions that cause a misunderstanding of Ogreemby brand and/or third
            parties, or which intentionally spreads false, misleading, or
            questionable information;
            <br />
            Acts related to gambling activities, fraud, forgery, threats,
            extortion, or other acts which constitute criminal offenses in the
            relevant jurisdiction;
            <br />
            Acts of interfering with Employee Services servers and/or network
            systems;
            <br />
            Manipulating Employee Services fraudulently by using various tools,
            or other technical measures; “frame,” “mirror,” or “deep link” any
            part of Employee Services;
            <br />
            Provide a link from any website or web page to the OgreemApp, or
            Content that is not authorized by Us; and/or Posting any Personal
            Data from any third party on the OgreemApp.
          </p>

          <p className="mb-6">
            <span className="font-bold">19. Limitation of Liability</span>
            <br />
            You expressly understand and agree that We are not responsible, to
            the extent permitted by Applicable Law, for any material or
            non-material losses, including but not limited to, losses for lost
            profits, goodwill, use, data or other things other intangibles (even
            if We have been notified of the possibility of such loss),
            originating from including but not limited to:
            <br />
            Your use of or inability to use the Employee Services or any
            services obtained through the OgreemApp or Your inability to use the
            OgreemApp;
            <br />
            unauthorized access to or alteration of Your transmissions or data
            that is not caused by Our fault or negligence;
            <br />
            any third party statements or conduct relating to the Employee
            Services;
            <br />
            delays or interruptions that cause unavailability of access to and
            from the OgreemApp;
            <br />
            the legal relationship between You and the Employer, including but
            not limited to an act of the Employer, whether intentional or
            unintentional, that causes a loss to You;
            <br />
            the impact of losses that You suffer as a result of accessing the
            OgreemApp, including but not limited to lost profits, business
            interruption or business opportunities;
            <br />
            any viruses or other malicious software or malfunctions that may
            occur in connection with Your use of the OgreemApp;
            <br />
            system, network, server performance failures, interruptions, bugs,
            errors, or inaccuracies or omissions of any kind in the OgreemApp
            caused by system defects and beyond Our control;
            <br />
            decision making based on the content, actions or inaction of any
            third party. We have never and will never recommend You to choose an
            Official Partner service. You hereby acknowledge and agree that it
            is Your own voluntary choice and take full responsibility for it;
            <br />
            change, modification, deletion, termination of the OgreemApp, this
            Terms and Conditions and/or Privacy Notice;
            <br />
            any loss arising from a Force Majeure;
            <br />
            any action caused by Your fault or negligence; and/or
            <br />
            events beyond Our reasonable control, We make no representation or
            warranty that defects or errors will be corrected.
            <br />
            You agree to indemnify Ogreem, its officers, directors, employees
            from and against any and all claims, costs, damages, losses,
            liabilities and expenses arising in connection with (1) any claims
            or requests made or issued by third parties due to or arising from
            Your actions that violate or violate Applicable Laws and the Terms
            and Conditions; (2) Your misuse of the Employee Services, features,
            Programs on the OgreemApp; and/or (3) Your breach of the
            representations and warranties as set forth in this Terms and
            Conditions, Privacy Notice, and Applicable Law.
          </p>

          <p className="mb-6">
            <span className="font-bold">20. Notifications and Contacts</span>
            <br />
            If You have any questions or comments regarding this Terms and
            Conditions, or if You wish to report a violation of this Terms and
            Conditions, please contact Us at compliance@Ogreem.app.
            <br />
            If You have any questions and/or complaints regarding the Employee
            Services, You can contact Us at hello@Ogreem.app .
          </p>

          <p className="mb-6">
            <span className="font-bold">
              21. Applicable Law and Dispute Resolution
            </span>
            <br />
            This Terms and Conditions shall be interpreted, implemented,
            governed by, and subject to the laws of the People of Bangladesh.
            <br />
            You agree that all disputes, controversies or claims arising out of
            or in connection with this Terms and Conditions, shall be resolved
            by way of amicable settlement.
            <br />
            If after a certain period of time, the dispute cannot be resolved
            amicably, it is agreed to be resolved in the relevant judicial
            institutions by appointing district courts within the territory of
            the People of Bangladesh on a non-exclusive basis.
          </p>

          <p className="mb-6">
            <span className="font-bold">Consent Form</span>
            <br />
            I, on {currentDate.toLocaleDateString()} irrevocably consent to
            disclose and share my Personal Data to Ogreem as Ogreem reasonably
            requires. Ogreem, therefore reserves the right to receive, use and
            manage my Personal Data from Employer for the purposes of
            registration, granting access to OgreemApp, and use of the Employee
            Services in the Program, including but not limited to: <br />
            full name
            <br />
            government-issued id (KTP)
            <br />
            employee ID
            <br />
            phone number
            <br />
            Email
            <br />
            residential address and
            <br />
            postal code
            <br />
            the amount of wages
            <br />
            loan rebates (if any)
            <br />
            employment status
            <br />
            job title
            <br />
            level bank details (bank name, bank account holder name, and bank
            account number)
            <br />
            start of work date
            <br />
            This consent is given in the form of electronic consent of
            applicable Law.
            <br />
            I have read, understood, re-examined, and agreed without coercion
            the information and/or data to be disclosed and shared by the
            Employer under this Consent Form.
            <br />
            I understand and acknowledge that the above information and/or data
            is required Ogreemin the provision of Employee Services to the
            maximum extent and if my consent is not obtained it will result in
            me being unable to register, access to the OgreemApp, and/or use the
            Employee Services.
            <br />
            EWP Withdrawal Details
            <br />
            {/* Employee name and EWP application Date:
#{first_name} #{last_name} #{request_date}



Amount of EWP applications (if any):
#{ewa_amount}



Membership Fees (if any):
#{employee_membership_fee}



Employer Contributions (if any):
#{ewa_contribution}



Date of payment of wages for the selected payment period:
#{scheduled_payday} */}
          </p>
        </div>
      </Box>
    </Container>
  );
};

export default TermsAndCondition;
