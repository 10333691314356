import { APIRequestServices } from "../api-request-service";

export class APIDataServices {
  Auth = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `auth/jwt/login`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CompanyCreate = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `company/create`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CompanyUpdate = async ({ data, company_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Put({
          URL: `company/update/${company_id}`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CompanyDataGet = async () => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `company/get`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CompanyGetFee = async ({ company_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `company/get_fee/${company_id}`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CompanyFeeCreate = async ({ data, company_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `company/create_fee/${company_id}`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CompanyFeeUpdate = async ({ data, fee_line_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Put({
          URL: `company/update_fee/${fee_line_id}`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CompanyDeleteFee = async ({ fee_line_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Delete({
          URL: `company/delete_fee/${fee_line_id}`,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerCreate = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `partners`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerGetAll = async () => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `partners`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerGet = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `partners`,
          getRawResponse: true,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerUpdate = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `partners/${data.partner_id}`,
          data: data.partner,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerCredentialsCreate = async ({ data, partner_id }) => {
    data.partner_id = partner_id;
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `credentials`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerCredentialsUpdate = async ({ data, partner_id }) => {
    data.partner_id = partner_id;
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `credentials/${partner_id}`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerCredentialsGet = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `credentials`,
          getRawResponse: true,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  SalaryConfigurationSync = async ({ partner_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `config/sync/v2/${partner_id}`,
          // data
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  SalaryConfigurationUpdate = async ({ partner_id, data }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `config/update/${partner_id}`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  SalaryConfigurationSyncGetData = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `config`,
          getRawResponse: true,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerEmployeeSync = async ({ partner_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `employees/sync/${partner_id}`,
          // data
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerEmployeeSyncGetData = async (params) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `employees`,
          getRawResponse: true,
          data: { ...params },
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerEmployeeAttendenceSync = async (params) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `attendances/sync/${params.partner_id}`,
          params: {
            from_date: params.filterDate.date_from,
            to_date: params.filterDate.date_to,
          },
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerEmployeeAttendenceSyncGetData = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `attendances`,
          getRawResponse: true,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerEmployeeSalaryRequestData = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `app/advance_requests`,
          getRawResponse: true,
          data: data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerEmployeeSalaryEditData = async (ids, action_type) => {
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Patch({
          URL: `app/advance_requests/actions?action_type=${action_type}`,
          getRawResponse: true,
          data: ids,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  };

  GetPaymentMethodList = async () => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `payment`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CreatePaymentMethod = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `payment`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  UpdatePaymentMethod = async ({ payment_method_id, data }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Put({
          URL: `payment/${payment_method_id}`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  GetPaymentWalletList = async () => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `payment_wallet`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CreatePaymentWallet = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `payment_wallet`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  UpdatePaymentWallet = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `payment_wallet/${data.id}`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PaymentBatchStatus = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `payment_batch/check_status/${data.batch_name}`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CreateOgreemFee = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `agrim_fee`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  GetOgreemFeeList = async ({ params }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `agrim_fee`,
          getRawResponse: true,
          data: params,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  DeleteOgreemFee = async (params) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Delete({
          URL: `agrim_fee`,
          params,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  UpdateOgreemFee = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `agrim_fee`,
          data,
          params: { fee_id: data.id },
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CreateBatchRequest = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `app/advance_requests/batch`,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  GetSalaryRequestBatchInfo = async ({ params }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `app/advance_requests/batch`,
          getRawResponse: true,
          data: params,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CreateManualTransaction = async (params) => {
    // console.log(params);
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Patch({
          URL: `app/advance_requests/manual/transaction`,
          getRawResponse: true,
          params,
        })
        .then((result) => {
          resolve(result);
        });
      // .catch((err) => reject(err));
    });
  };

  CancelManualTransaction = async (params) => {
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Patch({
          URL: `app/advance_requests/cancel`,
          getRawResponse: true,
          params,
        })
        .then((result) => {
          resolve(result);
        });
      // .catch((err) => reject(err));
    });
  };

  GetUsersList = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `app/users`,
          getRawResponse: true,
          data: data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  UserWallerSync = async ({ employee_id }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `app/user_wallets/sync/${employee_id}`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  ApproveBatchRequest = async ({ params }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `app/advance_requests/batch/approve`,
          params,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  DisburseBatchRequest = async ({ params }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `app/advance_requests/batch/disburse`,
          getRawResponse: true,
          params,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  CancelBatchRequest = async ({ params }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `app/advance_requests/batch/cancel`,
          getRawResponse: true,
          params,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  SalaryRequestDelete = async (params) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Delete({
          URL: `app/advance_requests`,
          params,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  InvoiceList = async (params) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `invoice/line`,
          data: params,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  InvoiceCreate = async (data) => {
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Get({
          URL: `invoice/create`,
          data,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  };

  InvoiceGenerate = async (data) => {
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Get({
          URL: `invoice/generate/data`,
          data,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  };

  InvoiceApprove = async (data) => {
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Patch({
          URL: `invoice/approve`,
          params: data,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  };

  InvoiceSettle = async (data) => {
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Patch({
          URL: `invoice/settled`,
          params: data,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  };

  InvoiceCancel = async (data) => {
    return await new Promise((resolve, reject) => {
      new APIRequestServices()
        .Patch({
          URL: `invoice/cancel`,
          params: data,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  };

  PaymentMethodSyncToAllPartner = async () => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `payment/sync`,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  PartnerSync = async (partner_id) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `payment/sync/${partner_id}`,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  GetAndroidVersionSetting = async () => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Get({
          URL: `agrim_setting`,
          getRawResponse: true,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  UpdateAndroidVersionSetting = async ({ data, params }) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Patch({
          URL: `agrim_setting`,
          params,
          data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  SendNotification = async (data) => {
    return await new Promise((resolve) => {
      new APIRequestServices()
        .Post({
          URL: `user/notification`,
          params: data,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
}
