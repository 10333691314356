import React, { useEffect, useMemo } from "react";
import CommonTable from "../../components/common-table";
import {
  deleteOgreemFee,
  getOgreemFeeList,
  setOgreemFeeModal,
} from "../../store/partnerSlice";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import OgreemFeeModal from "./ogreem-fee-modal";

const OgreemFee = ({ partnerId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOgreemFeeList({ params: { partner_id: partnerId } }));
  }, [dispatch, partnerId]);

  const { ogreemFeeList } = useSelector((state) => state.partnerSlice);

  const columns = useMemo(() => {
    const handleEdit = (row) => {
      dispatch(
        setOgreemFeeModal({ open: true, type: "edit", id: row.original.id })
      );
    };

    const handleDelete = (row) => {
      dispatch(
        deleteOgreemFee({
          partner_id: partnerId,
          params: { fee_id: row.original.id },
        })
      );
    };
    return [
      {
        Header: "From Amount",
        accessor: "from_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "To Amount",
        accessor: "to_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Fee Type",
        // accessor: 'type',
        className: "font-bold",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {_.capitalize(row.original.fee_type)}
          </div>
        ),
      },
      {
        Header: "Transaction Fee",
        accessor: "fee_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Edit",
        id: "edit",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton onClick={(ev) => handleEdit(row)}>
              <EditIcon sx={{ fontSize: 30, color: "black" }} />
            </IconButton>
          </div>
        ),
      },
      {
        Header: "Delete",
        id: "delete",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton onClick={(ev) => handleDelete(row)}>
              <DeleteIcon sx={{ fontSize: 30, color: "red" }} />
            </IconButton>
          </div>
        ),
      },
    ];
  }, [dispatch, partnerId]);

  return (
    <div>
      <p className="mb-8 font-bold text-lg">Ogreem Fee Configuration</p>

      <div className="flex justify-between mb-8">
        <button
          className="p-4 bg-yellow-500"
          onClick={() =>
            dispatch(setOgreemFeeModal({ open: true, type: "new", id: null }))
          }
        >
          Add new
        </button>
      </div>

      <CommonTable
        columns={columns}
        data={ogreemFeeList}
        handleClick={(event, row) => null}
      />

      <OgreemFeeModal partnerId={partnerId} />
    </div>
  );
};

export default OgreemFee;
