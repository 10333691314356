import { ArrowRight } from "@mui/icons-material";
import { ArrowLeft } from "@mui/icons-material";
import {
  ButtonGroup,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useExpanded,
  useGlobalFilter,
  //   useGroupBy,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { getReports, getReportSummary } from "../../pages/reports/queries";
import { removeEmptyValues, toTitleCase } from "../../utils/helper";
import { toastNotification } from "../../utils/toast";
import TableSearch from "../page-components/TableSearch";
import CollapseTableRow from "./CollapseTableRow";
import ExportToExcel from "./ExportToExcel";
import FilterModal from "./FilterModal";
import { useSearchParams } from "react-router-dom";
// import * as XLSX from "xlsx";
// import useExportToExcel from "./useExportToExcel";
// import PaginationActions from "./PaginationActions";

const CollapseTable = ({
  level,
  dense = true,
  groupBy,
  setGroupBy,
  params,
  setParams,
  groupByColors,
}) => {
  const tableRef = useRef(null);
  let [searchParams] = useSearchParams();

  const scrollPressTimer = useRef(null);
  const [limt, setLimt] = useState(10);
  const [offst, setOffst] = useState(0);
  const [filter, setFilter] = useState({});
  // const [, setRowPerPage] = useState(10);
  // const [pageNumber, setPageNumber] = useState(0);
  // const [totalCount, setTotalCount] = useState(0);
  // const [queryPageIndex, setQueryPageIndex] = useState(0);
  // const [queryPageSize, setQueryPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  //   const [thisParams, setThisParams] = useState({});
  //   const [groupBy, setGroupBy] = useState([]);
  const columns = useMemo(() => {
    let clms = [];
    clms =
      data.length !== 0
        ? !!groupBy
          ? Object.keys(data[0])
              ?.filter((key) => !["partner_id"].includes(key))
              ?.map((key) => ({
                Header: toTitleCase(key),
                accessor: key,
                className: "font-bold",
                sortable: true,
                Cell: (val) => {
                  if (["updated_at", "requested_date"].includes(key)) {
                    return moment(val.value).format("DD/MM/YYYY hh:mm:ss a");
                  }
                  return val.value ? val.value : "-";
                },
              }))
          : [
              {
                Header: "Employee Name",
                accessor: "employee_name",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "ID Number",
                accessor: "id_number",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
                aggregate: "count",
                Aggregated: ({ value }) => `Group: (ID Number) ${value}`,
              },
              {
                Header: "Gender",
                accessor: "gender",
                className: "font-bold",
                sortable: true,
                aggregate: "count",
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Employer Pay Cycle",
                accessor: "employer_pay_cycle",
                className: "font-bold",
                sortable: true,
                aggregate: "count",
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Number of Days Worked",
                accessor: "number_of_days_worked",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Salary Earned",
                accessor: "salary_earned",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Withdrawable Amount",
                accessor: "withdrawable_amount",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Requested Amount",
                accessor: "requested_amount",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Carry Over Amount",
                accessor: "carry_over_amount",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "MFS Wallet",
                accessor: "mfs_wallet",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Bank Account",
                accessor: "bank_account",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              // {
              //   Header: "Date Requested",
              //   accessor: "requested_at",
              //   className: "font-bold",
              //   sortable: true,
              {
                Header: "Request Date",
                accessor: "requested_date",
                className: "font-bold",
                sortable: true,
                Cell: (val) =>
                  val.value ? moment(val.value).format("DD/MM/YYYY") : "-",
              },
              {
                Header: "Disbursement Date",
                accessor: "disbursement_date",
                className: "font-bold",
                sortable: true,
                Cell: (val) =>
                  val.value ? moment(val.value).format("DD-MM-YYYY") : "-",
              },
              {
                Header: "Disbursement Amount",
                accessor: "disbursement_amount",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Service Charge",
                accessor: "service_charge",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Cost of Finance",
                accessor: "cost_of_finance_amount",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? Number(val.value.toFixed(2)) : 0),
              },
              {
                Header: "Net Revenue",
                accessor: "net_revenue",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? Number(val.value.toFixed(2)) : "-"),
              },
              {
                Header: "Payment Date",
                accessor: "expected_payment_date",
                className: "font-bold",
                sortable: true,
                Cell: (val) =>
                  val.value
                    ? moment(val.value, "YYYY-MM-DD").format("DD-MM-YYYY")
                    : "-",
              },
              {
                Header: "Actual Reimnursement Date",
                accessor: "actual_reimnursement_date",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Penalty Interest",
                accessor: "penalty_interest",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Penalty Amount",
                accessor: "penalty_amount",
                className: "font-bold",
                sortable: true,
                Cell: (val) => (val.value ? val.value : "-"),
              },
              {
                Header: "Status",
                accessor: "status",
                className: "font-bold",
                sortable: true,
                // Cell: ChangeStatusActions,
                Cell: (val) => (val.value ? val.value : "-"),
              },
            ]
        : [];
    return clms || [];
  }, [data, groupBy]);
  const scroll = (scrollOffset) => {
    tableRef.current.scrollLeft += scrollOffset;
  };
  const scrollPress = (scrollOffset) => {
    scrollPressTimer.current = setInterval(
      () => (tableRef.current.scrollLeft += scrollOffset),
      500
    );
  };
  function scrollClear() {
    clearInterval(scrollPressTimer.current);
  }

  const table = useTable(
    {
      columns,
      data,
      autoResetPage: true,
      // initialState: { pageIndex: queryPageIndex, pageSize: queryPageSize },
      // manualPagination: true,
      // pageCount: Math.ceil(totalCount / queryPageSize) || null,
    },
    useGlobalFilter,
    // useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
    //   useRowSelect
  );
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    // pageOptions,
    // nextPage,
    // canNextPage,
    // previousPage,
    // canPreviousPage,
    // pageCount,
    // gotoPage,
    setPageSize,
    setGlobalFilter,
    // rows,
    // setGroupBy,
    // state: {
    // pageIndex,
    // pageSize,
    // groupBy
    // },
  } = table;
  // console.log(pageIndex, pageSize);
  // const exportToExcel = async () => {
  //   let data;
  //   if (!!!groupBy[level]) {
  //     let filterParams = removeEmptyValues(filter);

  //     const { data: resData } = await getReports({
  //       ...params,
  //       ...filterParams,
  //     });
  //     data = resData;
  //   } else {
  //     data = await getReportSummary({
  //       ...params,
  //       groupby: groupBy[level],
  //     });
  //   }
  //   const exportData = table?.initialRows?.map((t) => t.values);
  //   console.log(exportData);
  //   const worksheet = XLSX.utils.json_to_sheet(exportData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   // console.log(table, columns);
  //   XLSX.writeFile(
  //     workbook,
  //     `Report-${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
  //   );
  // };

  const fetchData = useCallback(async () => {
    setLoading(true);
    // const offset = pageSize * pageIndex;
    try {
      let data;
      if (!!!groupBy) {
        let filterParams = removeEmptyValues(filter);
        let grpBy = searchParams.get("groupBy");
        if (grpBy === "employer_pay_cycle") {
          let from_time = moment(params?.employer_pay_cycle.slice(0, 10));
          let to_time = moment(params?.employer_pay_cycle.slice(14, 25)).add(
            1,
            "months"
          );
          // console.log(
          //   params?.employer_pay_cycle.slice(14, 25),
          //   to_time.format("MM"),
          //   to_time.format("YYYY")
          // );
          filterParams = {
            ...filterParams,
            from_year: parseInt(from_time.format("YYYY")),
            from_month: parseInt(from_time.format("MM")),
            to_year: parseInt(to_time.format("YYYY")),
            to_month: parseInt(to_time.format("MM")),
          };
        }
        const { data: resData } = await getReports({
          ...params,
          ...filterParams,
          off_set: offst,
          limit: limt,
        });
        data = resData;
        // console.log(countData, data);
        // setTotalCount(countData?.[0]?.count || 0);
      } else {
        data = await getReportSummary({
          ...params,
          groupby: groupBy,
          off_set: offst,
          limit: limt,
        });
      }
      if (data === "No DATA here") {
        setOffst((of) => (of === 0 ? 0 : of - limt));
      } else {
        setData(data);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }

    setLoading(false);
  }, [filter, groupBy, limt, offst, params, searchParams]);
  // const [exportToExcel] = useExportToExcel({
  //   groupBy,
  //   level,
  //   data,
  //   filter,
  //   params,
  // });
  // useEffect(() => {
  //   setQueryPageIndex(pageIndex);
  //   setQueryPageSize(pageSize);
  // }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  //   useEffect(() => {
  //     console.log(rows);
  //     if (!!groupBy[level]) {
  //       setThisParams((p) => ({
  //         ...p,
  //         [groupBy[level]]: rows?.original?.[groupBy[level]],
  //       }));
  //     }
  //   }, [groupBy, level, params, rows, setParams]);
  return (
    <>
      {/* {TableToolsComponent ? <TableToolsComponent table={table} /> : null} */}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{
          mt: 2,
        }}
      >
        <TableSearch setGlobalFilter={setGlobalFilter} loading={loading} />
        {!!!groupBy && (
          <FilterModal
            loading={loading}
            groupBy={groupBy}
            filter={filter}
            setFilter={(f) => setFilter(f)}
          />
        )}
        {level === 0 && (
          <ButtonGroup>
            <IconButton
              disabled={loading}
              onMouseLeave={scrollClear}
              onMouseUp={scrollClear}
              onMouseDown={() => scrollPress(-250)}
              onClick={() => scroll(-150)}
              // disabled={
              //   !(
              //     tableRef?.current?.clientWidth <
              //     tableRef?.current?.scrollWidth
              //   )
              // }
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              disabled={loading}
              onMouseLeave={scrollClear}
              onMouseUp={scrollClear}
              onMouseDown={() => scrollPress(250)}
              onClick={() => scroll(150)}
              // disabled={
              //   !(
              //     tableRef?.current?.clientWidth <
              //     tableRef?.current?.scrollWidth
              //   )
              // }
            >
              <ArrowRight />
            </IconButton>
          </ButtonGroup>
        )}
        <ExportToExcel
          loading={loading}
          groupBy={groupBy}
          level={level}
          params={params}
          filter={filter}
        />

        <IconButton
          onClick={() => {
            setOffst((of) => (of === 0 ? 0 : of - limt));
            // previousPage();
          }}
          size="small"
        >
          <ArrowLeft />
        </IconButton>
        <Typography>Page {Math.ceil(offst / limt) + 1}</Typography>
        <IconButton
          onClick={() => {
            setOffst((of) => of + limt);
          }}
          size="small"
        >
          <ArrowRight />
        </IconButton>
        <Select
          size="small"
          value={limt}
          onChange={(e) => {
            setOffst(0);
            setLimt(Number(e.target.value));
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              Show {pageSize}
            </MenuItem>
          ))}
        </Select>

        {/* <div className="pagination">
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>{" "}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>{" "}
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>{" "}
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              value={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div> */}

        {/* <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          // count={paginationData?.total ? paginationData?.total : 0}
          count={totalCount || 0}
          // count={data.length}
          rowsPerPage={pageSize}
          page={pageNumber}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={PaginationActions}
        /> */}
      </Stack>
      {loading ? (
        <LinearProgress sx={{ width: "100%", p: 0, my: 1 }} />
      ) : (
        <Grid item xs={12} sx={{ mt: 2 }}>
          {!loading && data.length === 0 ? (
            <Typography>No data to show</Typography>
          ) : (
            <>
              <TableContainer
                ref={tableRef}
                sx={{
                  scrollBehavior: "smooth",
                  bgcolor: groupByColors[groupBy]?.[50],
                }}
              >
                <Table
                  {...getTableProps()}
                  stickyHeader
                  size={dense ? "small" : ""}
                >
                  <TableHead>
                    {headerGroups.map((headerGroup) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {!!groupBy && (
                          <TableCell
                            sx={{
                              bgcolor: groupByColors[groupBy]?.[50],
                              fontWeight: "bold",
                            }}
                          />
                        )}
                        {headerGroup.headers.map((column) => (
                          <TableCell
                            sx={{
                              bgcolor: groupByColors[groupBy]?.[50],
                              fontWeight: "bold",
                            }}
                            {...(!column.sortable
                              ? column.getHeaderProps()
                              : column.getHeaderProps(
                                  column.getSortByToggleProps()
                                ))}
                          >
                            {column.render("Header")}
                            {column.sortable ? (
                              <TableSortLabel
                                active={column.isSorted}
                                // react-table has a unsorted state which is not treated here
                                direction={column.isSortedDesc ? "desc" : "asc"}
                              />
                            ) : null}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <Fragment key={i}>
                          {!!groupBy ? (
                            <CollapseTableRow
                              groupByColors={groupByColors}
                              row={row}
                              level={level + 1}
                              groupBy={groupBy}
                              params={{
                                ...params,
                                [groupBy]: row?.original?.[groupBy],
                              }}
                              setGroupBy={(s) => setGroupBy(s)}
                              setParams={(s) => setParams(s)}
                            />
                          ) : (
                            <TableRow
                              {...row.getRowProps()}
                              className="cursor-pointer"
                              //   onClick={(event) => handleClick(event, row)}
                              hover
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <TableCell
                                    {...cell.getCellProps()}
                                    className={`p-4 md:p-12 ${cell.column.className}`}
                                    width={cell.column.width}
                                  >
                                    {cell.isPlaceholder
                                      ? null
                                      : cell.render("Cell")}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          )}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default CollapseTable;
