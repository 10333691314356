import { toast } from 'react-toastify';

const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}

export const toastNotification = (type, message, toastId) => {
    if(type === "loading"){
        return toast.loading(message, toastOptions);
    }
    if(type === "dismiss"){
        toast.dismiss(toastId)
    }
    if(type === "success"){
        toast.success(message, toastOptions);
    }
    if(type === "error"){
        toast.error(message, toastOptions);
    }
}