import React, { useState, useEffect } from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  setPaymentWalletModal,
  createPaymentWallet,
  updatePaymentWallet,
} from "../../store/agrimSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F7F5ED",
  boxShadow: 24,
  p: 3,
  borderTopLeftRadius: "20px",
  borderBottomRightRadius: "20px",
};

const paymentWalletStatusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const PaymentWalletModal = () => {
  const dispatch = useDispatch();

  const { paymentWalletModal, paymentMethodList, paymentWalletList } =
    useSelector((state) => state.agrimSlice);

  const [paymentWalletData, setPaymentWalletData] = useState({
    name: "",
    account_no: "",
    active: true,
    payment_method_id: null,
  });

  useEffect(() => {
    if (paymentWalletModal.type === "new") {
      setPaymentWalletData({
        name: "",
        account_no: "",
        active: true,
        payment_method_id: null,
      });
      setSelectedPaymentMethod(null);
    }

    if (paymentWalletModal.type === "edit") {
      let selectedPaymentWallet = paymentWalletList.find(
        (wallet) => wallet.id === paymentWalletModal.id
      );
      setPaymentWalletData({
        name: selectedPaymentWallet.name,
        account_no: selectedPaymentWallet.account_no,
        active: selectedPaymentWallet.active,
        payment_method_id: selectedPaymentWallet.payment_method.id,
        id: selectedPaymentWallet.id,
      });
      const selectedPaymentMethod = paymentMethodList.find(
        (method) => method.id === selectedPaymentWallet.payment_method.id
      );
      setSelectedPaymentMethod({
        label: selectedPaymentMethod.name,
        value: selectedPaymentMethod.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentWalletModal, paymentMethodList]);

  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  useEffect(() => {
    if (paymentMethodList.length) {
      const paymentMethodOptionsArray = paymentMethodList.map((method) => ({
        label: method.name,
        value: method.id,
      }));
      setPaymentMethodOptions(paymentMethodOptionsArray);
    }
  }, [paymentMethodList]);

  const handleValueChange = (name, value) => {
    let modifiedValue = null;
    if (name === "payment_method_id") {
      modifiedValue = value.value;
      setSelectedPaymentMethod(value);
    } else {
      modifiedValue = value;
    }

    setPaymentWalletData({
      ...paymentWalletData,
      [name]: modifiedValue,
    });
  };

  const handleClose = () => {
    dispatch(setPaymentWalletModal({ open: false, type: "new", id: null }));
  };

  const handleSubmit = () => {
    dispatch(createPaymentWallet(paymentWalletData));
  };

  const handleUpdate = () => {
    dispatch(updatePaymentWallet(paymentWalletData));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={paymentWalletModal.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableAutoFocus={true}
    >
      <Fade in={paymentWalletModal.open}>
        <Box sx={style} className="login-alert">
          <div className="w-full h-full relative">
            <div className="modal-close-button" onClick={handleClose}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7 5.3C18.5 5.1 18.3 5 18 5C17.7 5 17.5 5.1 17.3 5.3L12 10.6L6.7 5.3C6.3 4.9 5.7 4.9 5.3 5.3C4.9 5.7 4.9 6.3 5.3 6.7L10.6 12L5.3 17.3C5.1 17.5 5 17.7 5 18C5 18.3 5.1 18.5 5.3 18.7C5.5 18.9 5.7 19 6 19C6.3 19 6.5 18.9 6.7 18.7L12 13.4L17.3 18.7C17.5 18.9 17.7 19 18 19C18.3 19 18.5 18.9 18.7 18.7C18.9 18.5 19 18.3 19 18C19 17.7 18.9 17.5 18.7 17.3L13.4 12L18.7 6.7C18.9 6.5 19 6.3 19 6C19 5.7 18.9 5.5 18.7 5.3Z"
                  fill="#1A1A1A"
                />
              </svg>
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">Name</p>
              <input
                type="text"
                name="name"
                value={paymentWalletData.name}
                className="border-2 w-full col-span-2 border-black pl-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">Account No</p>
              <input
                type="text"
                name="account_no"
                value={paymentWalletData.account_no}
                className="w-full col-span-2 border-2 border-black pl-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            <div className="grid grid-cols-3 mb-6">
              <div className="flex items-center">
                <p className="">Active</p>
              </div>
              <div className="w-full col-span-2">
                <Select
                  options={paymentWalletStatusOptions}
                  styles={customStyles}
                  value={
                    paymentWalletData.active
                      ? { label: "Active", value: paymentWalletData.active }
                      : { label: "Inactive", value: paymentWalletData.active }
                  }
                  onChange={(event) => handleValueChange("active", event.value)}
                />
              </div>
            </div>

            <div className="grid grid-cols-3 mb-6">
              <div className="flex items-center">
                <p className="">Payment Method</p>
              </div>
              <div className="w-full col-span-2">
                <Select
                  options={paymentMethodOptions}
                  placeholder="Select Payment Method"
                  styles={customStyles}
                  value={selectedPaymentMethod}
                  onChange={(event) =>
                    handleValueChange("payment_method_id", event)
                  }
                />
              </div>
            </div>

            <div className="flex justify-end">
              {paymentWalletModal.type === "new" ? (
                <button className="p-4 bg-green-500" onClick={handleSubmit}>
                  Submit
                </button>
              ) : (
                <button className="p-4 bg-green-500" onClick={handleUpdate}>
                  Update
                </button>
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PaymentWalletModal;
