import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIDataServices } from "../service-pattern";
import { toastNotification } from "../utils/toast";
import { resetAuth, setUser } from "./authSlice";

export const companyDataGet = createAsyncThunk(
  "argimSlice/companyDataGet",
  async (_, { dispatch }) => {
    const data = await new APIDataServices().CompanyDataGet().then((result) => {
      if (result.status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
        dispatch(
          setUser({
            id: "",
          })
        );
      }
      dispatch(setCompanyData(result.data));

      return { data: result.data };
    });

    return data;
  }
);

export const companyCreate = createAsyncThunk(
  "argimSlice/companyCreate",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CompanyCreate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        dispatch(setCompanyData(result.data));
      });

    return data;
  }
);

export const companyUpdate = createAsyncThunk(
  "argimSlice/companyUpdate",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CompanyUpdate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
      });

    return data;
  }
);

export const companyGetFee = createAsyncThunk(
  "argimSlice/companyGetFee",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CompanyGetFee(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          result.data.sort((a, b) => a.fid - b.fid);
          dispatch(setCompanyFeeData(result.data));
        } else {
          dispatch(setCompanyFeeData([]));
        }
      });

    return data;
  }
);

export const companyFeeCreate = createAsyncThunk(
  "argimSlice/companyFeeCreate",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CompanyFeeCreate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          dispatch(setCreateFeeModal({ open: false, type: "new", id: null }));
          dispatch(companyGetFee({ company_id: formData.company_id }));
        }
      });

    return data;
  }
);

export const companyFeeUpdate = createAsyncThunk(
  "argimSlice/companyFeeUpdate",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CompanyFeeUpdate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          dispatch(setCreateFeeModal({ open: false, type: "new", id: null }));
          dispatch(companyGetFee({ company_id: formData.company_id }));
        }
      });

    return data;
  }
);

export const companyDeleteFee = createAsyncThunk(
  "argimSlice/companyDeleteFee",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CompanyDeleteFee(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        dispatch(companyGetFee({ company_id: formData.company_id }));
      });

    return data;
  }
);

export const getPaymentMethodList = createAsyncThunk(
  "argimSlice/getPaymentMethodList",
  async (_, { dispatch }) => {
    const data = await new APIDataServices()
      .GetPaymentMethodList()
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        } else {
          result.data.sort((a, b) => a.id - b.id);
          dispatch(setPaymentMethodList(result.data));
        }
      });

    return data;
  }
);

export const createPaymentMethod = createAsyncThunk(
  "argimSlice/createPaymentMethod",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CreatePaymentMethod(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        if (result.status === 200) {
          dispatch(getPaymentMethodList());
          dispatch(
            setPaymentMethodListModal({ open: false, type: "new", id: null })
          );
        }
      });

    return data;
  }
);

export const updatePaymentMethod = createAsyncThunk(
  "argimSlice/updatePaymentMethod",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .UpdatePaymentMethod(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        if (result.status === 202) {
          dispatch(getPaymentMethodList());
          dispatch(
            setPaymentMethodListModal({ open: false, type: "new", id: null })
          );
        }
      });

    return data;
  }
);

export const createPaymentWallet = createAsyncThunk(
  "argimSlice/createPaymentWallet",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CreatePaymentWallet(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        if (result.status === 201) {
          dispatch(getPaymentWalletList());
          dispatch(
            setPaymentWalletModal({ open: false, type: "new", id: null })
          );
        }
      });

    return data;
  }
);

export const getPaymentWalletList = createAsyncThunk(
  "argimSlice/getPaymentWalletList",
  async (_, { dispatch }) => {
    const data = await new APIDataServices()
      .GetPaymentWalletList()
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        } else {
          result.data.sort((a, b) => a.id - b.id);
          dispatch(setPaymentWalletList(result.data));
        }
      });

    return data;
  }
);

export const updatePaymentWallet = createAsyncThunk(
  "argimSlice/updatePaymentWallet",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .UpdatePaymentWallet(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        if (result.status === 202) {
          dispatch(getPaymentWalletList());
          dispatch(
            setPaymentWalletModal({ open: false, type: "new", id: null })
          );
        }
      });

    return data;
  }
);

export const createBatchRequest = createAsyncThunk(
  "argimSlice/createBatchRequest",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .CreateBatchRequest(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        // if (result.status === 201) {
        //     dispatch(getPaymentWalletList())
        //     dispatch(setPaymentWalletModal({ open: false, type: "new", id: null }))
        // }

        return { data: result.data, status: result.status };
      });

    return data;
  }
);

export const getSalaryRequestBatchInfo = createAsyncThunk(
  "argimSlice/getSalaryRequestBatchInfo",
  async (formData, { dispatch }) => {
    console.log("aaa");
    const data = await new APIDataServices()
      .GetSalaryRequestBatchInfo(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        if (result.status === 200) {
          result.data.sort((a, b) => b.id - a.id);
          dispatch(setSalaryBatchInfo(result.data));
        } else {
          dispatch(setSalaryBatchInfo(null));
        }
      });

    return data;
  }
);

export const getSingleSalaryRequestBatchInfo = createAsyncThunk(
  "argimSlice/getSingleSalaryRequestBatchInfo",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .GetSalaryRequestBatchInfo(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        if (result.status === 200) {
          //   result.data.sort((a, b) => b.id - a.id);
          //   dispatch(setSalaryBatchInfo(result.data));
          return result.data;
        }
        // else {
        //   dispatch(setSalaryBatchInfo(null));
        // }
      });

    return data;
  }
);

export const approveBatchRequest = createAsyncThunk(
  "argimSlice/approveBatchRequest",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .ApproveBatchRequest(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        // if (result.status === 200) {
        //   dispatch(
        //     getSingleSalaryRequestBatchInfo({
        //       params: { batch_id: formData.params.batch_id },
        //     })
        //   );
        // }

        return { status: result.status };
      });

    return data;
  }
);

export const disburseBatchRequest = createAsyncThunk(
  "argimSlice/disburseBatchRequest",
  async (formData, { dispatch }) => {
    const response = await new APIDataServices().DisburseBatchRequest(formData);
    if (response.status === 401) {
      sessionStorage.removeItem("token");
      dispatch(resetAuth());
    }
    // if (response.status === 200) {
    //   dispatch(
    //     getSingleSalaryRequestBatchInfo({
    //       params: { batch_id: formData.params.batch_id },
    //     })
    //   );
    // }
    return response;
  }
);

export const cancelBatchRequest = createAsyncThunk(
  "argimSlice/cancelBatchRequest",
  async (formData, { dispatch }) => {
    const response = await new APIDataServices().CancelBatchRequest(formData);
    if (response.status === 401) {
      sessionStorage.removeItem("token");
      dispatch(resetAuth());
    }
    // if (response.status === 200) {
    //   dispatch(
    //     getSingleSalaryRequestBatchInfo({
    //       params: { batch_id: formData.params.batch_id },
    //     })
    //   );
    // }
    return response;
  }
);

export const paymentMethodSyncToAllPartner = createAsyncThunk(
  "argimSlice/paymentMethodSyncToAllPartner",
  async (_, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .PaymentMethodSyncToAllPartner()
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        toastNotification("dismiss", undefined, toastId);
        if (result.status === 202) {
          toastNotification("success", "Payment Method to all Partners Synced");
          dispatch(
            setPaymentMethodSyncResultModal({ open: true, data: result.data })
          );
        } else {
          toastNotification("error", "Getting Old Data");
        }
      });

    return data;
  }
);

const agrimSlice = createSlice({
  name: "argimSlice",
  initialState: {
    companyData: null,
    companyFeeData: [],
    createFeeModal: {
      open: false,
      type: "new",
      id: null,
    },
    paymentMethodList: [],
    paymentMethodListModal: {
      open: false,
      type: "new",
      id: null,
    },
    paymentWalletList: [],
    paymentWalletModal: {
      open: false,
      type: "new",
      id: null,
    },
    salaryBatchInfo: null,
    paymentMethodSyncResultModal: {
      open: false,
      data: [],
    },
  },
  reducers: {
    setCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
    setCompanyFeeData: (state, action) => {
      state.companyFeeData = action.payload;
    },
    setCreateFeeModal: (state, action) => {
      state.createFeeModal = action.payload;
    },
    setPaymentMethodList: (state, action) => {
      state.paymentMethodList = action.payload;
    },
    setPaymentMethodListModal: (state, action) => {
      state.paymentMethodListModal = action.payload;
    },
    setPaymentWalletList: (state, action) => {
      state.paymentWalletList = action.payload;
    },
    setPaymentWalletModal: (state, action) => {
      state.paymentWalletModal = action.payload;
    },
    setSalaryBatchInfo: (state, action) => {
      state.salaryBatchInfo = action.payload;
    },
    setPaymentMethodSyncResultModal: (state, action) => {
      state.paymentMethodSyncResultModal = action.payload;
    },
  },
});

export const {
  setCompanyData,
  setCompanyFeeData,
  setCreateFeeModal,
  setPaymentMethodList,
  setPaymentMethodListModal,
  setPaymentWalletModal,
  setPaymentWalletList,
  setSalaryBatchInfo,
  setPaymentMethodSyncResultModal,
} = agrimSlice.actions;

export default agrimSlice.reducer;
