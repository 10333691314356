import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { APIDataServices } from "../service-pattern";
import { resetAuth } from "./authSlice";

export const partnerCreate = createAsyncThunk(
  "partnerSlice/partnerCreate",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .PartnerCreate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        return { status: result.status };
      });

    return data;
  }
);

export const invoiceGetAll = createAsyncThunk(
  "invoiceSlice/invoiceGetAll",
  async (formData, { dispatch }) => {
    const { status, data } = await new APIDataServices().InvoiceList(formData);
    if (status === 401) {
      sessionStorage.removeItem("token");
      dispatch(resetAuth());
    }
    if (status === 200) {
      // console.table(data);
      dispatch(setInvoiceList(data));
    } else {
      dispatch(setInvoiceList([]));
    }
    return data;
  }
);

const invoiceSlice = createSlice({
  name: "invoiceSlice",
  initialState: {
    invoiceList: [],
  },
  reducers: {
    setInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
    },
  },
});

export const { setInvoiceList, createInvoice } = invoiceSlice.actions;

export default invoiceSlice.reducer;
