import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar";
import { resetAuth } from "./store/authSlice";
import { useDispatch } from "react-redux";
/* import { useNavigate } from 'react-router-dom' */

const SignedInUser = () => {
  const dispatch = useDispatch();
  /* const navigate = useNavigate() */

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    dispatch(resetAuth());
    // navigate('/')
  };

  return (
    <div className="flex h-screen">
      <Sidebar />

      <div className="w-full h-screen overflow-y-scroll relative">
        <div className="h-20 w-full bg-stone-600 flex justify-end items-center fixed right-2 lg:right-3.5 z-10">
          <button className="h-10 w-40 bg-red-500 mr-4" onClick={handleLogout}>
            Logout
          </button>
        </div>

        <div className="mt-24 mb-8 w-full px-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SignedInUser;
