import {
  Checkbox,
  Avatar,
  FormControlLabel,
  // FormControl,
  // InputLabel,
  // Select,
} from "@mui/material";
import { Button } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { Dialog } from "@mui/material";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { MenuItem } from "@mui/material";
import { ListItemAvatar } from "@mui/material";
import { ListItemText } from "@mui/material";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { DialogContent } from "@mui/material";
import { TextField } from "@mui/material";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APIDataServices } from "../../service-pattern";
import { partnerGetAll } from "../../store/partnerSlice";

const CreateInvoice = () => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      with_validation: true,
      payroll_month: new Date().getMonth() + 1,
      partner_id: "",
    },
  });
  const [errorData, setErrorData] = useState([]);
  const { partnerList } = useSelector((state) => state.partnerSlice);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const vals = watch();
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (formValues) => {
    setLoading(true);
    try {
      const formData = {
        partner_id: formValues.partner_id,
        with_validation: formValues.with_validation,
        payroll_month: formValues.payroll_month,
      };

      // if (formValues.to_date) {
      //   formData.to_date = moment(formValues.to_date).format("YYYY-MM-DD");
      // }
      console.log(formValues);
      const { data, status } = await new APIDataServices().InvoiceCreate(
        formData
      );
      if (status === 406) {
        setErrorData(data.requests);
        setOpen(true);
      } else {
        setOpen(false);
        navigate("/admin/invoice-list");
      }
      console.log(data, status);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const handleChange = (e) => setValue("payroll_month", e.target.value);

  // useEffect(() => {
  //   register({ name: "payroll_month" });
  // }, [register]);

  useEffect(() => {
    dispatch(partnerGetAll());
  }, [dispatch]);

  return (
    <div>
      <p className="font-bold text-2xl mb-8">Create Invoice</p>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={loading}
              fullWidth
              label="Partner Id"
              select
              type="select"
              defaultValue=""
              // error={errors.partner_id}
              {...register("partner_id", {
                required: "Partmer id is required",
              })}
            >
              {partnerList.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {partner.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              disabled={loading}
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Date from"
              type="date"
              {...register("from_date")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={loading}
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Date to"
              type="date"
              {...register("to_date")}
            />
          </Grid> */}

          <Grid item xs={6}>
            <TextField
              disabled={loading}
              fullWidth
              label="Payroll Month"
              select
              type="select"
              defaultValue={new Date().getMonth() + 1}
              // error={errors.partner_id}
              {...register("payroll_month", {
                required: "Payroll month required",
              })}
            >
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </TextField>
            {/* <FormControl fullWidth>
              <InputLabel id="payroll-month-label">Payroll Month</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    labelId="payroll-month-label"
                    id="payroll_month"
                    value={payrollMonth}
                    label="Payroll Month"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                )}
              />
            </FormControl> */}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={loading}
                  defaultChecked
                  {...register("with_validation")}
                />
              }
              label="Validate?"
            />
          </Grid>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
              There are {errorData.length} requests left for disbursement. Do
              you still want to Continue?
            </DialogTitle>
            <DialogContent>
              <List>
                {errorData.map((errD) => (
                  <ListItem key={errD.id}>
                    <ListItemAvatar>
                      <Avatar>{errD.id}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={errD.employee_name}
                      secondary={`${errD.partner_name} | A/C no. - ${errD.account_no}`}
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                disabled={loading}
                type="button"
                color="error"
                variant="contained"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={loading}
                type="button"
                color="primary"
                variant="contained"
                onClick={() => {
                  console.log(vals);
                  const newValues = vals;
                  newValues.with_validation = false;
                  onSubmit(newValues);
                }}
              >
                Comfirm
              </Button>
            </DialogContent>
          </Dialog>
          <Grid item>
            <Button
              disabled={loading}
              type="button"
              color="error"
              variant="contained"
              onClick={() => navigate("/admin/invoice-list")}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={loading}
              type="submit"
              color="primary"
              variant="contained"
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CreateInvoice;
