import { Send } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { APIDataServices } from "../../service-pattern";
import { resetAuth } from "../../store/authSlice";
import {
  partnerEmployeeSyncGetData,
  partnerGetAll,
} from "../../store/partnerSlice";
import { toastNotification } from "../../utils/toast";

const Notification = () => {
  const dispatch = useDispatch();
  const {
    partnerList,
    partnerListLoading,
    partnerEmployeeListData,
    partnerEmployeeListDataLoading,
  } = useSelector((state) => state.partnerSlice);
  const initValue = {
    title: "",
    body: "",
    partner_id: 0,
    //   user_id: "",
    employee_id: 0,
    //   serial_number: "",
  };
  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: initValue,
  });
  const selectedPartner = watch("partner_id");
  const onSubmit = async (formData) => {
    try {
      const { status } = await new APIDataServices().SendNotification(formData);
      if (status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      }
      if (status === 201) {
        toastNotification("success", "Notification send");
        reset(initValue);
      } else {
        throw Error("Failed to send notification. Please Try Again");
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  };
  useEffect(() => {
    dispatch(partnerGetAll());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPartner) {
      dispatch(partnerEmployeeSyncGetData({ partner_id: selectedPartner }));
    }
  }, [dispatch, selectedPartner]);

  return (
    <div>
      <Typography sx={{ m: 2, fontWeight: "bold" }}>Notification</Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "400px" }}
      >
        <TextField
          fullWidth
          sx={{ m: 2 }}
          label="Title"
          {...register("title", { required: true })}
          placeholder="Notification title"
        />
        <TextField
          fullWidth
          sx={{ m: 2 }}
          label="Body"
          {...register("body", { required: true })}
          placeholder="Notification body"
        />
        <FormControl fullWidth sx={{ m: 2 }}>
          <InputLabel id="partner_id-select-label">Partner</InputLabel>
          {partnerListLoading ? (
            <LinearProgress />
          ) : (
            <Select
              labelId="partner_id-select-label"
              label="Partner"
              {...register("partner_id", { required: false })}
              placeholder="Partner"
            >
              <MenuItem value={0}>None</MenuItem>
              {partnerList.map((partner) => {
                return (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>

        {/* <TextField
          {...register("user_id", { required: false })}
          placeholder="User"
        /> */}

        <FormControl fullWidth sx={{ m: 2 }}>
          <InputLabel id="employee_id-select-label">Employee</InputLabel>
          {partnerEmployeeListDataLoading ? (
            <LinearProgress />
          ) : (
            <Select
              disabled={selectedPartner === ""}
              labelId="employee_id-select-label"
              label="Employee"
              {...register("employee_id", { required: false })}
              placeholder="Employee"
            >
              <MenuItem value={0}>None</MenuItem>
              {partnerEmployeeListData.map((employee) => {
                return (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>

        {/* <TextField
          {...register("serial_number", { required: false })}
          placeholder="Serial no."
        /> */}
        {/* <Button
          variant="contained"
          type="button"
          color="error"
          onClick={() => reset(initValue)}
        >
          Cancel
        </Button> */}
        <Button
          variant="contained"
          type="submit"
          startIcon={<Send />}
          sx={{ m: 2 }}
        >
          Send
        </Button>
      </Box>
    </div>
  );
};

export default Notification;
