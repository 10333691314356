import React, { useState } from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import OgreemLogoBangla from "./ogreem-logo-bangla.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  AccountBox,
  Ballot,
  People,
  Receipt,
  ReceiptLong,
  RequestPage,
  RequestQuote,
  Settings,
} from "@mui/icons-material";
import AgrimIcon from "./agrim-logo.png";
import AddCardIcon from "@mui/icons-material/AddCard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Contacts } from "@mui/icons-material";
import { PendingActions } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { NotificationAdd } from "@mui/icons-material";

const Sidebar = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const { userData, notAdmin } = useSelector((state) => state.authSlice);

  const handleToggleSidebar = (value) => {
    setIsToggled(value);
  };

  let location = useLocation();

  return (
    <div className="sidebar-app">
      <p className="md:hidden" onClick={() => handleToggleSidebar(true)}>
        Argha
      </p>
      <ProSidebar
        collapsed={isSideBarOpen}
        breakPoint="md"
        onToggle={handleToggleSidebar}
        toggled={isToggled}
        width={280}
      >
        <SidebarHeader>
          <div className="p-4">
            <img
              src={OgreemLogoBangla}
              alt="logo"
              onClick={() => setIsSideBarOpen((prevState) => !prevState)}
            />
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle" subMenuBullets={true}>
            <MenuItem
              active={location.pathname === "/admin" ? true : false}
              icon={<DashboardIcon />}
            >
              Dashboard <Link to="/admin" />
            </MenuItem>

            {userData.id !== notAdmin ? (
              <SubMenu
                title="Ogreem"
                icon={
                  <Box
                    component="img"
                    src={AgrimIcon}
                    alt="logo"
                    sx={{ height: "20px" }}
                  />
                }
              >
                <MenuItem
                  active={
                    location.pathname === "/admin/ogrim/profile" ? true : false
                  }
                  icon={<AccountBox />}
                >
                  Profile
                  <Link to="/admin/ogrim/profile" />
                </MenuItem>
                <MenuItem
                  active={
                    location.pathname === "/admin/ogrim/settings" ? true : false
                  }
                  icon={<Settings />}
                >
                  Settings
                  <Link to="/admin/ogrim/settings" />
                </MenuItem>
                <MenuItem
                  active={
                    location.pathname === "/admin/ogrim/settings/app"
                      ? true
                      : false
                  }
                  icon={<Settings />}
                >
                  App version setting
                  <Link to="/admin/ogrim/settings/app" />
                </MenuItem>
                <MenuItem
                  active={
                    location.pathname === "/admin/ogrim/payment-method"
                      ? true
                      : false
                  }
                  icon={<AddCardIcon />}
                >
                  Payment Method
                  <Link to="/admin/ogrim/payment-method" />
                </MenuItem>
                <MenuItem
                  active={
                    location.pathname === "/admin/ogrim/payment-wallet"
                      ? true
                      : false
                  }
                  icon={<AccountBalanceWalletIcon />}
                >
                  Disbursement Wallet
                  <Link to="/admin/ogrim/payment-wallet" />
                </MenuItem>
              </SubMenu>
            ) : (
              ""
            )}

            <SubMenu title="Partner" icon={<People />}>
              <MenuItem
                active={
                  location.pathname === "/admin/company-list" ? true : false
                }
                icon={<Ballot />}
              >
                Partner List
                <Link to="/admin/company-list" />
              </MenuItem>
            </SubMenu>

            <SubMenu title="Advance Request List" icon={<RequestPage />}>
              <MenuItem
                active={
                  location.pathname === "/admin/salary-request-list-all"
                    ? true
                    : false
                }
                icon={<RequestPage />}
              >
                All Request
                <Link to="/admin/salary-request-list-all" />
              </MenuItem>
              <MenuItem
                active={
                  location.pathname === "/admin/salary-request-list-valid"
                    ? true
                    : false
                }
                icon={<PendingActions />}
              >
                Ready for processing
                <Link to="/admin/salary-request-list-valid" />
              </MenuItem>
              {/* <MenuItem
                active={
                  location.pathname === "/admin/salary-batch-created" ? true : false
                }
                icon={<RequestPage />}
              >
                Batch Created
                <Link to="/admin/salary-batch-created" />
              </MenuItem> */}
              {/* <MenuItem
                active={
                  location.pathname === "/admin/salary-batch-approved" ? true : false
                }
                icon={<RequestPage />}
              >
                Batch Approved
                <Link to="/admin/salary-batch-approved" />
              </MenuItem> */}
              <MenuItem
                active={
                  location.pathname === "/admin/salary-request-list-disbursed"
                    ? true
                    : false
                }
                icon={<Receipt />}
              >
                Ready for invoice
                <Link to="/admin/salary-request-list-disbursed" />
              </MenuItem>
              <MenuItem
                active={
                  location.pathname === "/admin/salary-request-list-invoiced"
                    ? true
                    : false
                }
                icon={<ReceiptLong />}
              >
                Invoiced
                <Link to="/admin/salary-request-list-invoiced" />
              </MenuItem>
              <MenuItem
                active={
                  location.pathname === "/admin/salary-request-list-done"
                    ? true
                    : false
                }
                icon={<RequestQuote />}
              >
                Done
                <Link to="/admin/salary-request-list-done" />
              </MenuItem>
            </SubMenu>

            <SubMenu title="Payment" icon={<RequestQuote />}>
              <MenuItem
                active={
                  location.pathname ===
                  "/admin/salary-request-batch/all-batch-list"
                    ? true
                    : false
                }
                icon={<Ballot />}
              >
                All Batch
                <Link to="/admin/salary-request-batch/all-batch-list" />
              </MenuItem>

              <MenuItem
                active={
                  location.pathname ===
                  "/admin/salary-request-batch/draft-batch-list"
                    ? true
                    : false
                }
                icon={<Ballot />}
              >
                Draft Batch
                <Link to="/admin/salary-request-batch/draft-batch-list" />
              </MenuItem>

              <MenuItem
                active={
                  location.pathname ===
                  "/admin/salary-request-batch/approved-batch-list"
                    ? true
                    : false
                }
                icon={<Ballot />}
              >
                Approved Batch
                <Link to="/admin/salary-request-batch/approved-batch-list" />
              </MenuItem>

              <MenuItem
                active={
                  location.pathname ===
                  "/admin/salary-request-batch/disbursed-batch-list"
                    ? true
                    : false
                }
                icon={<Ballot />}
              >
                Disbursed Batch
                <Link to="/admin/salary-request-batch/disbursed-batch-list" />
              </MenuItem>

              {/* <MenuItem */}
              {/*     active={ */}
              {/*         location.pathname === */}
              {/*             "/admin/salary-request-batch/refused-batch-list" */}
              {/*             ? true */}
              {/*             : false */}
              {/*     } */}
              {/*     icon={<Ballot />} */}
              {/* > */}
              {/*     Refused Batch */}
              {/*     <Link to="/admin/salary-request-batch/refused-batch-list" /> */}
              {/* </MenuItem> */}

              <MenuItem
                active={
                  location.pathname ===
                  "/admin/salary-request-batch/cancelled-batch-list"
                    ? true
                    : false
                }
                icon={<Ballot />}
              >
                Cancelled Batch
                <Link to="/admin/salary-request-batch/cancelled-batch-list" />
              </MenuItem>
            </SubMenu>
            <MenuItem
              active={
                location.pathname === "/admin/invoice-list" ? true : false
              }
              icon={<Receipt />}
            >
              Invoice List
              <Link to="/admin/invoice-list" />
            </MenuItem>
            <MenuItem
              active={location.pathname === "/admin/users" ? true : false}
              icon={<Contacts />}
            >
              App Users <Link to="/admin/users" />
            </MenuItem>
            <SubMenu title="Report" icon={<RequestPage />}>
              <MenuItem
                active={location.pathname === "/admin/users" ? true : false}
                icon={<ReceiptLong />}
              >
                All Report <Link to="/admin/reports" />
              </MenuItem>
              <MenuItem
                active={location.pathname === "/admin/users" ? true : false}
                icon={<ReceiptLong />}
              >
                Report by Pay Cycle
                <Link to="/admin/reports/gb?groupBy=employer_pay_cycle" />
              </MenuItem>
              <MenuItem
                active={location.pathname === "/admin/users" ? true : false}
                icon={<ReceiptLong />}
              >
                Report by Employee ID
                <Link to="/admin/reports/gb?groupBy=id_number" />
              </MenuItem>
              <MenuItem
                active={location.pathname === "/admin/users" ? true : false}
                icon={<ReceiptLong />}
              >
                Report by Partner{" "}
                <Link to="/admin/reports/gb?groupBy=partner_id" />
              </MenuItem>
            </SubMenu>
            <MenuItem
              active={
                location.pathname === "/admin/notification" ? true : false
              }
              icon={<NotificationAdd />}
            >
              Notification <Link to="/admin/notification" />
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/admin/terms-and-condition"
                  ? true
                  : false
              }
              icon={<Contacts />}
            >
              Terms And Condition
              <Link to="/admin/terms-and-condition" />
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <p>this is the footer</p>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
