import React from 'react';
import { Pagination } from '@mui/material';

const PaginationActions = props => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleButtonChange = (event, value) => {
        onPageChange(event, value-1);
    };
    return (
        <div className="common-table-pagination-app flex-shrink-0 px-12 overflow-hidden">
            <Pagination
                count={Math.ceil(count / rowsPerPage)}
                onChange={handleButtonChange}
                showFirstButton
                showLastButton
                page={page + 1}
            />
        </div>
    );
};

export default PaginationActions;
