import { useLoaderData, useNavigate } from "react-router-dom";
import _ from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getEmployeeProfile } from "./queries";
import EmployeeAdvancedRequest from "./EmployeeAdvancedRequest";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import EmployeeAttendances from "./EmployeeAttendances";
import { indigo } from "@mui/material/colors";
import { Menu } from "@mui/icons-material";

export function loader({ params }) {
  return getEmployeeProfile(params);
}

function a11yProps(index) {
  return {
    id: `employee-profile-tab-${index}`,
    "aria-controls": `employee-profile-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`employee-profile-tabpanel-${index}`}
      aria-labelledby={`employee-profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const EmployeeProfile = () => {
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(true);

  // const location = useLocation();
  const navigate = useNavigate();
  // const [locationState, setLocationState] = useState({});
  const { profile: employeeProfileData, config } = useLoaderData();

  // useEffect(() => {
  //   if (location.state !== null) {
  //     setLocationState({ ...location.state });
  //   }
  // }, [location]);
  const handleChange = (_, newValue) => {
    setTab(newValue);
  };
  const handleBackButton = () => {
    // const name = locationState.partnerInfo?.name
    //   .trim()
    //   .replace(/[\. ,:-]+/g, "-")
    //   .toLowerCase();
    // const URL = `/admin/company-profile/${name}`;
    navigate(-1, {
      //   state: {
      //     partnerInfo: { ...locationState.partnerInfo },
      //     tabValue: 2,
      //     pageName: locationState.pageName,
      //   },
    });
  };

  //   if (Object.keys(locationState).length === 0) {
  //     return null;
  //   }

  return (
    <Box>
      <Button
        size="small"
        onClick={handleBackButton}
        startIcon={<ArrowBackIcon />}
        sx={{ mb: 1 }}
      >
        Back
      </Button>
      <Card sx={{ mb: 1 }}>
        <CardHeader
          avatar={
            <IconButton onClick={() => setOpen((o) => !o)}>
              <Menu />
            </IconButton>
          }
          title={
            <Typography sx={{ fontSize: "20px" }}>
              {employeeProfileData?.name}
            </Typography>
          }
          sx={{ bgcolor: indigo[300] }}
        />
        <Collapse in={open}>
          <CardContent>
            <div className="flex flex-row">
              <table className="m-2">
                <tbody>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Name</td>
                    <td className="py-1 pr-4">{employeeProfileData?.name}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Designation</td>
                    <td className="py-1 pr-4">
                      {employeeProfileData.designation}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-1 pr-4 font-bold">Job Status</td>
                    <td className="py-1 pr-4">
                      {_.capitalize(employeeProfileData.job_status)}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-1 pr-4 font-bold">Employee ID</td>
                    <td className="py-1 pr-4">{employeeProfileData.emp_id}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Address</td>
                    <td className="py-1 pr-4">{employeeProfileData.address}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Phone</td>
                    <td className="py-1 pr-4">{employeeProfileData.phone}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Active Status</td>
                    <td className="py-1 pr-4">
                      {employeeProfileData.active_status
                        ? "Active"
                        : "Inactive"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="m-2">
                <tbody>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Salary</td>
                    <td className="py-1 pr-4">{employeeProfileData.salary}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Payment Method</td>
                    <td className="py-1 pr-4">
                      {employeeProfileData.payment_method?.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Payment Type</td>
                    <td className="py-1 pr-4">
                      {employeeProfileData.payment_method?.type.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Account No.</td>
                    <td className="py-1 pr-4">{employeeProfileData.acc_no}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">National ID</td>
                    <td className="py-1 pr-4">{employeeProfileData.nid}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Date of Birth</td>
                    <td className="py-1 pr-4">
                      {employeeProfileData.date_of_birth}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Payroll start date</td>
                    <td className="py-1 pr-4">{config.payroll_start_date}</td>
                  </tr>
                  <tr>
                    <td className="py-1 pr-4 font-bold">Payroll end date</td>
                    <td className="py-1 pr-4">{config.payroll_end_date}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardContent>
        </Collapse>
      </Card>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="Employee profile tabs"
          >
            <Tab label="Advanced Request" {...a11yProps(0)} />
            <Tab label="Attendances" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <EmployeeAdvancedRequest employee_id={employeeProfileData.id} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <EmployeeAttendances employee_id={employeeProfileData.id} />
        </TabPanel>
      </Box>
      {/* <p>name: {locationState.employeeDetails.name}</p>
            <p>designation: {locationState.employeeDetails.designation}</p>
            <p>Job Status: {locationState.employeeDetails.job_status}</p>
            <p>Employee ID: {locationState.employeeDetails.emp_id}</p>
            <p>address: {locationState.employeeDetails.address}</p>
            <p>phone: {locationState.employeeDetails.phone}</p>
            <p>salary: {locationState.employeeDetails.salary}</p>
            <p>Bank Name: {locationState.employeeDetails.bank_name}</p>
            <p>Bank Branch: {locationState.employeeDetails.branch}</p>
            <p>Bank Account Number: {locationState.employeeDetails.acc_no}</p>
            <p>National ID: {locationState.employeeDetails.nid}</p>
            <p>Active Status: {locationState.employeeDetails.active_status ? "Active" : "Inactive"}</p> */}
    </Box>
  );
};

export default EmployeeProfile;
