import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";
import labels from "../../../utils/labels";
import CommonTable from "../../../components/common-table";
import BatchActionButtons from "../../../components/page-components/salary-batch-requests/BatchActionButtons";

import { useDispatch, useSelector } from "react-redux";
import { fetchAllBatch } from "../../../store/batchSlice";

const AllBatchList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const { batchRequestLoading, allBatchData } = useSelector(state => state.batchSlice)

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Scheduled Date",
        accessor: "scheduled_dt",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Total Request",
        accessor: "total_request",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Wallet Name",
        accessor: "wallet_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Approval Date",
        accessor: "approved_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Disbursed Date",
        accessor: "disbursed_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Cancel Date",
        accessor: "cancelled_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
        Cell: (val) => (
          <div className="flex items-center">
            <Chip
              label={val.value}
              color={labels[val.value]}
              id="demo-simple-select-label"
            />
          </div>
        ),
      },
      {
        id: "actions",
        Header: "Actions",
        accessor: "status",
        className: "font-bold",
        sortable: false,
        Cell: (val) => <BatchActionButtons val={val} />,
      },
    ],
    []
  );

  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  useEffect(() => {
     dispatch(fetchAllBatch({
        offset: offset,
        limit: limit,
     }))
    
  }, [dispatch, offset, limit]);

  const handleGoingToIndividualBatchRequest = (row) => {
    const name = row.original.name
      .trim()
      // eslint-disable-next-line no-useless-escape
      .replace(/[\. ,:-]+/g, "-")
      .toLowerCase();
    const URL = `/admin/salary-request-batch/batch-info/${name}`;
    navigate(URL, { state: { batchId: row.original.id, batchType: "all" } });
  };

  return (
     <div>
        <p className="text-xl font-bold mb-8">All Batch List</p>
        <CommonTable
          request_type="advance"
          offset={offset}
          limit={limit}
          changePage={changePage}
          columns={columns}
          data={allBatchData}
          tableLoader={batchRequestLoading}
          handleClick={(_, row) =>
            handleGoingToIndividualBatchRequest(row)
          }
        />
     </div>
  )

};

export default AllBatchList;
