import "./App.css";
import LogInPage from "./pages/sign-in";
import {
  Route,
  useNavigate,
  useLocation,
  createRoutesFromElements,
  RouterProvider,
  createBrowserRouter,
  Outlet,
  useRouteError,
} from "react-router-dom";
import jwt from "jwt-decode";
import Dashboard, { loader as dashboardLoader } from "./pages/dashboard";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setToken, setUser } from "./store/authSlice";
import CompanyList from "./pages/partner-list";
import CompanyProfile from "./pages/partner-profile";
import OgrimProfile from "./pages/ogrim-profile";
import OgrimSettings from "./pages/ogrim-settings";
import EmployeeProfile, {
  loader as employeeProfileLoader,
} from "./pages/employee-profile";
import SignedInUser from "./SignedInUser";
import PartnerProfileCreate from "./pages/partner-create";
import PartnerEmployeeAttendenceProfile from "./pages/partner-employee-attendence-profile";

import SalaryRequestList from "./pages/advance-salary-request-list/valid-request-list";
import ReadyForInvoiceList from "./pages/advance-salary-request-list/ready-for-invoice";
import InvoicedList from "./pages/advance-salary-request-list/invoiced-list";
import TransactionList from "./pages/advance-salary-request-list/done-list";
// import BatchCreatedList from "./pages/advance-salary-request-list/batch-created-list";
// import SalaryBatchApprovedList from "./pages/advance-salary-request-list/batch-approved-list";
import AllRequestList from "./pages/advance-salary-request-list/all-request-list";

import ErrorBoundary from "./ErrorBoundary";
import OgrimPaymentMethod from "./pages/ogrim-payment-method";
import OgrimPaymentWallet from "./pages/ogrim-payment-wallet";
import SalaryRequestBatchInfo from "./pages/salary-batch-request/batch-info";
import Users from "./pages/users";
import ReusabelBatchList, {
  loader as statusBatchRequestLoader,
} from "./pages/salary-batch-request/reusable-batch-list";
import AllBatchList, {
  loader as allBatchRequestLoader,
} from "./pages/salary-batch-request/all-batch-list";

import InvoiceList from "./pages/invoice";

import TermsAndCondition from "./TermsAndCondition";
import TermsAndConditionBN from "./TNCBN";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateInvoice from "./pages/invoice/CreateInvoice";
import Notification from "./pages/notification";
import AppVersionSetting from "./pages/ogreem-app-version-setting";
import ReportsGB from "./pages/reports/GroupedBy";
import Reports from "./pages/reports";
import PrivacyPolicy from "./PrivacyPolicy";

const ErrorRoute = () => {
  const error = useRouteError();
  return (
    <main id="error">
      <h1>An Error occured</h1>
      <p>{error.response ? error.response.message : error.message}</p>
    </main>
  );
};

const RootLayout = () => {
  // const auth = useAuth()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { token } = useSelector((state) => state.authSlice);

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      let token = sessionStorage.getItem("token");
      dispatch(setToken(token));
      let decoded = jwt(token);
      let user = {
        id: decoded.user_id || "",
      };
      dispatch(setUser(user));
      if (location.pathname === "/") {
        navigate("/admin");
      } else {
        navigate(`${location.pathname}`, { state: location.state });
      }
    } else {
      if (location.pathname === "/terms-and-condition") {
        navigate("/terms-and-condition");
      } else if (location.pathname === "/terms-and-condition-bn") {
        navigate("/terms-and-condition-bn");
      } else if (location.pathname === "/privacy-policy") {
        navigate("/privacy-policy");
      }else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<LogInPage />} />
      <Route path="/admin" element={<SignedInUser />}>
        <Route
          index
          element={<Dashboard />}
          loader={dashboardLoader}
          errorElement={<ErrorRoute />}
        />
        <Route
          path="company-list"
          element={
            <ErrorBoundary>
              <CompanyList />
            </ErrorBoundary>
          }
        />
        <Route path="company-profile/:id" element={<CompanyProfile />} />
        <Route
          path="ogrim/profile"
          element={
            <ErrorBoundary>
              <OgrimProfile />
            </ErrorBoundary>
          }
        />
        <Route path="ogrim/settings" element={<OgrimSettings />} />
        <Route path="ogrim/settings/app" element={<AppVersionSetting />} />

        <Route path="ogrim/payment-method" element={<OgrimPaymentMethod />} />
        <Route path="ogrim/payment-wallet" element={<OgrimPaymentWallet />} />
        <Route
          path="company-profile/:companyName/employee-profile/:employeeName"
          element={
            <ErrorBoundary>
              <EmployeeProfile />
            </ErrorBoundary>
          }
        />
        <Route
          id="employee-profile"
          loader={employeeProfileLoader}
          path="employee-profile/:employee_id"
          element={
            <ErrorBoundary>
              <EmployeeProfile />
            </ErrorBoundary>
          }
        />
        <Route path="partner-create" element={<PartnerProfileCreate />} />
        <Route
          path="company-profile/:companyName/employee-attendence-profile/:employeeName"
          element={<PartnerEmployeeAttendenceProfile />}
        />
        <Route path="salary-request-list-all" element={<AllRequestList />} />
        <Route
          path="salary-request-list-valid"
          element={<SalaryRequestList />}
        />
        {/* <Route path="salary-batch-created" element={<BatchCreatedList />} /> */}
        {/* <Route path="salary-batch-approved" element={<SalaryBatchApprovedList />} /> */}
        <Route
          path="salary-request-list-disbursed"
          element={<ReadyForInvoiceList />}
        />
        <Route path="salary-request-list-invoiced" element={<InvoicedList />} />
        <Route path="salary-request-list-done" element={<TransactionList />} />
        <Route
          path="salary-request-batch/batch-info/:batchName"
          element={<SalaryRequestBatchInfo />}
        />
        <Route
          path="salary-request-batch/:batch_status"
          element={<ReusabelBatchList />}
          // loader={statusBatchRequestLoader}
          // errorElement={<ErrorRoute />}
        />
        {/*  <Route
                path="salary-request-batch/approved-batch-list"
                element={<ReusabelBatchList batch_status="approved" />}
            />
            <Route
                path="salary-request-batch/disbursed-batch-list"
                element={<ReusabelBatchList batch_status="disbursed" />}
            />
            <Route
                path="salary-request-batch/refused-batch-list"
                element={<ReusabelBatchList batch_status="refused" />}
            /> */}
        <Route
          path="salary-request-batch/all-batch-list"
          element={<AllBatchList />}
          // loader={allBatchRequestLoader}
          // errorElement={<ErrorRoute />}
        />
        <Route path="invoice-list" element={<InvoiceList />} />
        <Route path="invoice-list/create" element={<CreateInvoice />} />
        <Route path="users" element={<Users />} />
        <Route path="notification" element={<Notification />} />

        <Route
          path="reports"
          element={
            <ErrorBoundary>
              <ReportsGB />
            </ErrorBoundary>
          }
        />
        <Route
          path="reports/gb"
          element={
            <ErrorBoundary>
              <Reports />
            </ErrorBoundary>
          }
        />
        <Route path="terms-and-condition" element={<TermsAndCondition />} />
        <Route
          path="terms-and-condition-bn"
          element={<TermsAndConditionBN />}
        />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Route>
     
      <Route path="terms-and-condition" element={<TermsAndCondition />} />
      <Route path="terms-and-condition-bn" element={<TermsAndConditionBN />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}

export default App;
