import React, { useState, useEffect } from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  createPaymentMethod,
  setPaymentMethodListModal,
  updatePaymentMethod,
} from "../../store/agrimSlice";
import _ from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F7F5ED",
  boxShadow: 24,
  p: 3,
  borderTopLeftRadius: "20px",
  borderBottomRightRadius: "20px",
};

const paymentMethodListOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const paymentTypeListOptions = [
  { label: "Bank", value: "bank" },
  { label: "MFS", value: "mfs" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const PaymentMethodListModal = () => {
  const dispatch = useDispatch();

  const { paymentMethodListModal, paymentMethodList } = useSelector(
    (state) => state.agrimSlice
  );

  const [paymentMethodData, setPaymentMethodData] = useState({
    name: "",
    code: "",
    account_length: 0,
    type: "",
    active: true,
  });

  const [selectedPaymentType, setSelectedPaymentType] = useState(null);

  useEffect(() => {
    if (paymentMethodListModal.type === "new") {
      setPaymentMethodData({
        name: "",
        code: "",
        account_length: 0,
        type: "",
        active: true,
      });
      setSelectedPaymentType(null);
    }
    if (paymentMethodListModal.type === "edit") {
      let selectedPaymentMethod = paymentMethodList.find(
        (method) => method.id === paymentMethodListModal.id
      );

      setPaymentMethodData({
        name: selectedPaymentMethod.name,
        code: selectedPaymentMethod.code,
        account_length: selectedPaymentMethod.account_length,
        type: selectedPaymentMethod.type,
        active: selectedPaymentMethod.active,
      });

      if (selectedPaymentMethod.type === "mfs") {
        setSelectedPaymentType({
          label: selectedPaymentMethod.type.toUpperCase(),
          value: selectedPaymentMethod.type,
        });
      } else {
        setSelectedPaymentType({
          label: _.capitalize(selectedPaymentMethod.type),
          value: selectedPaymentMethod.type,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodListModal]);

  const handleValueChange = (name, value) => {
    let modifiedValue = null;

    if (name === "account_length") {
      modifiedValue = parseInt(value);
    } else {
      modifiedValue = value;
    }

    if (name === "type") {
      modifiedValue = value.value;
      setSelectedPaymentType(value);
    }

    setPaymentMethodData({
      ...paymentMethodData,
      [name]: modifiedValue,
    });
  };

  const handleClose = () => {
    dispatch(setPaymentMethodListModal({ open: false, type: "new", id: null }));
  };

  const handleSubmit = () => {
    dispatch(createPaymentMethod(paymentMethodData));
  };

  const handleUpdate = () => {
    dispatch(
      updatePaymentMethod({
        payment_method_id: paymentMethodListModal.id,
        data: paymentMethodData,
      })
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={paymentMethodListModal.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableAutoFocus={true}
    >
      <Fade in={paymentMethodListModal.open}>
        <Box sx={style} className="login-alert">
          <div className="w-full h-full relative">
            <div className="modal-close-button" onClick={handleClose}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7 5.3C18.5 5.1 18.3 5 18 5C17.7 5 17.5 5.1 17.3 5.3L12 10.6L6.7 5.3C6.3 4.9 5.7 4.9 5.3 5.3C4.9 5.7 4.9 6.3 5.3 6.7L10.6 12L5.3 17.3C5.1 17.5 5 17.7 5 18C5 18.3 5.1 18.5 5.3 18.7C5.5 18.9 5.7 19 6 19C6.3 19 6.5 18.9 6.7 18.7L12 13.4L17.3 18.7C17.5 18.9 17.7 19 18 19C18.3 19 18.5 18.9 18.7 18.7C18.9 18.5 19 18.3 19 18C19 17.7 18.9 17.5 18.7 17.3L13.4 12L18.7 6.7C18.9 6.5 19 6.3 19 6C19 5.7 18.9 5.5 18.7 5.3Z"
                  fill="#1A1A1A"
                />
              </svg>
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">Name</p>
              <input
                type="text"
                name="name"
                value={paymentMethodData.name}
                className="border-2 w-full col-span-2 border-black pl-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">Code</p>
              <input
                type="text"
                name="code"
                value={paymentMethodData.code}
                className="w-full col-span-2 border-2 border-black pl-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">Account Length</p>
              <input
                type="number"
                name="account_length"
                value={paymentMethodData.account_length}
                className="w-full border-2 border-black pl-2 col-span-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            {/* <div className='grid grid-cols-3 mb-6'>
                            <p className=''>Type</p>
                            <input
                                type="text"
                                name='type'
                                value={paymentMethodData.type}
                                className='w-full col-span-2 border-2 border-black pl-2'
                                onChange={event => handleValueChange(event.target.name, event.target.value)}
                            />
                        </div> */}

            <div className="grid grid-cols-3 mb-6">
              <div className="flex items-center">
                <p className="">Type</p>
              </div>
              <div className="w-full col-span-2">
                <Select
                  options={paymentTypeListOptions}
                  styles={customStyles}
                  value={selectedPaymentType}
                  onChange={(event) => handleValueChange("type", event)}
                  placeholder="Select Type"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 mb-6">
              <div className="flex items-center">
                <p className="">Active</p>
              </div>
              <div className="w-full col-span-2">
                <Select
                  options={paymentMethodListOptions}
                  styles={customStyles}
                  value={
                    paymentMethodData.active
                      ? { label: "Active", value: paymentMethodData.active }
                      : { label: "Inactive", value: paymentMethodData.active }
                  }
                  onChange={(event) => handleValueChange("active", event.value)}
                />
              </div>
            </div>

            <div className="flex justify-end">
              {paymentMethodListModal.type === "new" ? (
                <button className="p-4 bg-green-500" onClick={handleSubmit}>
                  Submit
                </button>
              ) : (
                <button className="p-4 bg-green-500" onClick={handleUpdate}>
                  Update
                </button>
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PaymentMethodListModal;
