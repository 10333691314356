import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/common-table";
import {
  partnerEmployeeAttendenceSync,
  partnerEmployeeAttendenceSyncGetData,
} from "../../store/partnerSlice";

import TextField from "@mui/material/TextField";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import TableSearch from "../../components/page-components/TableSearch";

const PartnerEmployeeAttendenceList = ({ companyName, partnerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const {
    partnerEmployeeAttendenceListData,
    partnerEmployeeAttendenceListDataLoading,
  } = useSelector((state) => state.partnerSlice);

  const handleEmployeeProfileView = useCallback(
    (event, row) => {
      event.stopPropagation();
      // const name = row.original.name
      //     .trim()
      //     .replace(/[\. ,:-]+/g, '-')
      //     .toLowerCase();
      const compName = companyName
        .trim()
        // eslint-disable-next-line no-useless-escape
        .replace(/[\. ,:-]+/g, "-")
        .toLowerCase();
      // const URL = `/employee-profile/${compName}/${name}`
      const URL = `/admin/company-profile/${compName}/employee-attendence-profile/${row.original.id}`;
      navigate(URL, { state: { employeeDetails: row.original } });
    },
    [companyName, navigate]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "employee_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Check In",
        accessor: "check_in",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Working Hours",
        accessor: "worked_hours",
        className: "font-bold",
        sortable: true,
        width: 80,
      },
      {
        Header: "Is Late",
        id: "is_late",
        className: "font-bold",
        sortable: true,
        width: 80,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.is_late ? "Late" : "Not Late"}
          </div>
        ),
      },
      {
        Header: "Check Out",
        accessor: "check_out",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Is Early",
        id: "is_early",
        className: "font-bold",
        sortable: true,
        width: 100,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.is_early ? "Early" : "Not Early"}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "date",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Profile View",
        id: "profile-view",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton onClick={(ev) => handleEmployeeProfileView(ev, row)}>
              <VisibilityIcon sx={{ fontSize: 30, color: "black" }} />
            </IconButton>
          </div>
        ),
      },
    ],
    [handleEmployeeProfileView]
  );

  const handleEmployeeAttendenceSync = useCallback(() => {
    const filterDate = {};
    if (fromDate) {
      filterDate.date_from = moment(fromDate).format("YYYY-MM-DD");
    }
    if (toDate) {
      filterDate.date_to = moment(toDate).format("YYYY-MM-DD");
    }
    dispatch(
      partnerEmployeeAttendenceSync({ partner_id: partnerId, filterDate, offset: offset, limit: limit })
    );
  }, [dispatch, fromDate, partnerId, toDate, offset, limit]);

  const handleDateFilter = useCallback(() => {
    const filterDate = {
      partner_id: partnerId,
    };
    if (fromDate) {
      filterDate.date_from = moment(fromDate).format("YYYY-MM-DD");
    }
    if (toDate) {
      filterDate.date_to = moment(toDate).format("YYYY-MM-DD");
    }
    dispatch(partnerEmployeeAttendenceSyncGetData({...filterDate, offset: offset, limit: limit}));
  }, [dispatch, fromDate, partnerId, toDate, offset, limit]);

  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  useEffect(() => {
    handleDateFilter();
  }, [handleDateFilter]);

  return (
    <div>
      <div className="flex justify-end">
        <div className="flex">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="From Date"
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <div className="ml-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="To Date"
                inputFormat="DD/MM/YYYY"
                value={toDate}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <button
            disabled={partnerEmployeeAttendenceListDataLoading}
            className={`p-4 bg-yellow-500 mb-6 ml-4`}
            onClick={handleDateFilter}
          >
            Filter
          </button>
          <button
            className={`p-4 bg-yellow-500 mb-6 ml-4`}
            onClick={handleEmployeeAttendenceSync}
          >
            Attendence Sync
          </button>
        </div>
      </div>

      <CommonTable
        request_type="advance"
        columns={columns}
        data={partnerEmployeeAttendenceListData}
        changePage={changePage}
        offset={offset}
        limit={limit}
        handleClick={(event, row) => console.log("")}
        tableLoader={partnerEmployeeAttendenceListDataLoading}
        TableSearch={TableSearch}
      />
    </div>
  );
};

export default PartnerEmployeeAttendenceList;
