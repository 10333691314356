import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TableSearch from "../TableSearch";
import FilterDialog from "./FilterDialog";
import ReloadAdvancedSalaryRequests from "../ReloadAdvancedSalaryRequests";
import Button from "@mui/material/Button";
import CreateBatchModal from "./create-batch-modal";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { partnerEmployeeSalaryRequestData, partnerGetAll } from "../../../store/partnerSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as XLSX from "xlsx";
import moment from "moment";
import TextField from "@mui/material/TextField";

const TableTools = ({ table, filter_data = {}, handleSettingFilterData = () => { } }) => {
  const [filterPartner, setFilterPartner] = useState("all");
  const { partnerList } = useSelector((state) => state.partnerSlice);
  let [, setSearchParams] = useSearchParams();
  const {
    selectedFlatRows,
    // state: { selectedRowIds },
    setGlobalFilter,
  } = table;

  const location = useLocation();

  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const handleChange = (e) => {
    setFilterPartner(e.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(partnerGetAll());
  }, [dispatch]);

  useEffect(() => {
    if (filterPartner === "all") {
      setSearchParams({});
    } else {
      setSearchParams({
        partner_id: filterPartner,
      });
    }
  }, [filterPartner, setSearchParams]);

  useEffect(() => {
    setStatus(location.pathname.split("/")[2].split("-")[3]);
  }, [location]);

  const handleCreateBatch = () => {
    setIsCreateBatchModalOpen(true);
  };

  const handleCreateSheet = () => {
    const sheetData = selectedFlatRows.map((sfl, id) => {
      return {
        Id: parseInt(id) + 1,
        Name: sfl.original.employee.name,
        "Account No": sfl.original.account_no,
        "Amount (Taka)": sfl.original.amount_to_disburse,
      };
    });
    // console.log(sheetData);
    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `Batch-${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
    );
  };

  const [search_value, set_search_value] = useState("")

  const handleKeyDown = event => {
    if (event.key == "Enter") {
      dispatch(
        partnerEmployeeSalaryRequestData({
          ...filter_data,
          search_str: search_value
        })
      );
    }
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <ReloadAdvancedSalaryRequests />
          {/* <EditDialog
                        selectedFlatRows={selectedFlatRows}
                        selectedRowIds={selectedRowIds}
                    /> */}

          {status === "valid" && (
            <>
              <Button
                variant="contained"
                sx={{ marginLeft: "16px" }}
                disabled={selectedFlatRows.length > 0 ? false : true}
                onClick={handleCreateBatch}
              >
                Create Batch
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: "16px" }}
                disabled={selectedFlatRows.length > 0 ? false : true}
                onClick={handleCreateSheet}
              >
                Export to sheet
              </Button>
            </>
          )}

          {/* {status === "disbursed" && (
            <Button
              variant="contained"
              sx={{ marginLeft: "16px" }}
              disabled={selectedFlatRows.length > 0 ? false : true}
              onClick={handleCreateBatch}
            >
              Create Invoice
            </Button>
          )} */}
        </Box>
        <Box>
          {/* <TableSearch setGlobalFilter={setGlobalFilter} /> */}
          <TextField
            // type="search"
            size="small"
            value={search_value}
            onChange={(e) => set_search_value(e.target.value)}
            placeholder="Search"
            onKeyDown={handleKeyDown}
          // disabled={loading}
          />
          <FormControl size="small" sx={{ ml: 2 }}>
            <InputLabel id="partner-select-label">Filter by partner</InputLabel>
            <Select
              sx={{ width: "250px" }}
              size="small"
              labelId="partner-select-label"
              id="partner-select"
              value={filterPartner}
              label="Filter by partner"
              onChange={handleChange}
            >
              <MenuItem value="all">All</MenuItem>
              {partnerList.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {partner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <FilterDialog status={status} filter_data={filter_data} handleSettingFilterData={handleSettingFilterData} />
        <CreateBatchModal
          isCreateBatchModalOpen={isCreateBatchModalOpen}
          setIsCreateBatchModalOpen={setIsCreateBatchModalOpen}
          selectedFlatRows={selectedFlatRows}
        />
      </Box>
    </Grid>
  );
};

export default TableTools;
