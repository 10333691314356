import { Close, List, Send } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogActions,
  Button,
  DialogTitle,
  Tooltip,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import ReactToPrint from "react-to-print";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { APIDataServices } from "../../service-pattern";
import { invoiceGetAll } from "../../store/invoiceSlice";
import { toastNotification } from "../../utils/toast";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { renderToString } from "react-dom/server";

const SettleInvoice = ({ invoice_id, partner_id, status }) => {
  let [searchParams] = useSearchParams();
  const [paymentDate, setPaymentDate] = useState(null);
  const [payrollMonth, setPayrollMonth] = useState(new Date().getMonth() + 1);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  // const printInvoice = () => {
  // const pdf = new jsPDF();
  // console.log(ref.current);
  // const dop = renderToString(ref.current.innerHTML);
  // pdf.html(ref.current, {
  //   callback: function (doc) {
  //     doc.save();
  //   },
  // });
  // // pdf.fromHTML(ref.current.innerHTML);
  // // pdf.save();
  // };

  const settleInvoice = async () => {
    try {
      const params = {
        invoice_id,
        partner_id,
      };
      if (!!paymentDate?.format()) {
        params.payment_date = paymentDate?.format();
        params.payroll_month = payrollMonth;
        params.before_done = status !== "invoiced";
      }
      console.log(params);
      const { data } = await new APIDataServices().InvoiceSettle(params);
      if (data) {
        handleClose();
        dispatch(
          invoiceGetAll({
            partner_id: searchParams.get("partner_id"),
          })
        );
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  };

  return (
    <>
      <Tooltip title="Settle invoice">
        <IconButton color="primary" onClick={handleClickOpen}>
          <List />
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>Do you want to settle this invoice?</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Payment Date"
                value={paymentDate}
                onChange={(newValue) => {
                  setPaymentDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={null}
                    size="small"
                    sx={{ my: 2 }}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControl size="small" sx={{ ml: 2, my: 2 }}>
              <InputLabel id="payroll-month-label">Payroll Month</InputLabel>
              <Select
                sx={{ minWidth: "250px" }}
                size="small"
                labelId="payroll-month-label"
                id="payroll-month"
                value={payrollMonth}
                label="Payroll Month"
                onChange={(e) => setPayrollMonth(e.target.value)}
              >
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button startIcon={<Close />} color="error" onClick={handleClose}>
              Close
            </Button>
            <Button startIcon={<Send />} onClick={settleInvoice}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default SettleInvoice;
