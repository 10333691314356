import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { APIDataServices } from "../../service-pattern";
import { resetAuth } from "../../store/authSlice";
import { toastNotification } from "../../utils/toast";

const AppVersionSetting = () => {
  const dispatch = useDispatch();

  const [initValue] = useState({
    latest_application_version: 0,
    lowest_android_version: 0,
    force_update: false,
  });
  const { register, handleSubmit, watch, reset, control } = useForm({
    defaultValues: initValue,
  });

  const getSettings = useCallback(async () => {
    try {
      const { data, status } =
        await new APIDataServices().GetAndroidVersionSetting();
      if (status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      }
      if (status === 200) {
        reset(data);
      } else {
        throw Error(
          "Failed to load android version setting. Please reload this page"
        );
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  }, [dispatch, reset]);
  const onSubmit = async (formData) => {
    try {
      const { status } =
        await new APIDataServices().UpdateAndroidVersionSetting({
          data: formData,
          params: { setting_id: watch("id") },
        });
      if (status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      }
      if (status === 201) {
        toastNotification("success", "Successfully updated");
        getSettings();
      } else {
        throw Error("Failed to send notification. Please Try Again");
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  };
  useEffect(() => {
    getSettings();
    return () => {
      reset(initValue);
    };
  }, [getSettings, initValue, reset]);
  return (
    <Box>
      <Typography sx={{ m: 2, fontWeight: "bold" }}>
        App version setting
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "400px" }}
      >
        <TextField
          fullWidth
          sx={{ m: 2 }}
          label="Latest Application Version"
          {...register("latest_application_version", { required: true })}
          placeholder="Latest Application Version"
        />
        <TextField
          fullWidth
          sx={{ m: 2 }}
          label="Lowest Android Version"
          {...register("lowest_android_version", { required: true })}
          placeholder="Lowest Android Version"
        />
        <FormControlLabel
          sx={{ m: 1 }}
          control={
            <Controller
              name="force_update"
              control={control}
              value={watch("force_update")}
              render={({ field }) => (
                <Checkbox {...field} defaultChecked={watch("force_update")} />
              )}
            />
          }
          label="Force update?"
          {...register("force_update")}
        />
        <br />
        <Button
          variant="contained"
          type="submit"
          startIcon={<Save />}
          sx={{ m: 2 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AppVersionSetting;
