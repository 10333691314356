import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PartnerEmployeeAttendenceProfile = () => {
  const location = useLocation();
  const [locationState, setLocationState] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state !== null) {
      setLocationState({ ...location.state });
    }
  }, [location]);

  if (Object.keys(locationState).length === 0) {
    return null;
  }
  return (
    <div>
      <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
        Back
      </Button>
      <p>name: {locationState.employeeDetails.employee_name}</p>
      <p>Check In: {locationState.employeeDetails.check_in}</p>
      <p>Check Out: {locationState.employeeDetails.check_out}</p>
      <p>Date: {locationState.employeeDetails.date}</p>
      <p>
        Is Early:{" "}
        {locationState.employeeDetails.is_early ? "Early" : "Not Early"}
      </p>
      <p>
        Is Late: {locationState.employeeDetails.is_late ? "Late" : "Not Late"}
      </p>
      <p>Worked Hours: {locationState.employeeDetails.worked_hours}</p>
    </div>
  );
};

export default PartnerEmployeeAttendenceProfile;
