import {
  Box,
  Card,
  CardContent,
  // CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import CommonTable from "../../components/common-table";
import ChangeStatusActions from "../../components/page-components/salary-request-list/ChangeStatusActions";
import { APIRequestServices } from "../../service-pattern";
import { store } from "../../store";
import { resetAuth } from "../../store/authSlice";
import { toastNotification } from "../../utils/toast";

const EmployeeAdvancedRequest = ({ employee_id }) => {
  const [ar, setAR] = useState([]);
  const [wallet, setWallet] = useState({});
  const { detail } = useRouteLoaderData("employee-profile");
  const [loading, setLoading] = useState(false);
  const getGetWalletInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data, status } = await new APIRequestServices().Get({
        URL: `app/user_wallets/sync/${employee_id}`,
        getRawResponse: true,
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
      } else {
        setWallet(data);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
    setLoading(false);
  }, [employee_id]);

  const getEmployeeAdvancedRequests = useCallback(async () => {
    setLoading(true);
    try {
      const { data, status } = await new APIRequestServices().Get({
        URL: `app/advance_requests`,
        getRawResponse: true,
        data: { employee_id },
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
      } else {
        setAR(data);
        getGetWalletInfo();
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
    setLoading(false);
  }, [employee_id, getGetWalletInfo]);
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Partner",
        accessor: "partner_name",
        className: "font-bold",
        sortable: true,
      },

      {
        Header: "Employee Id",
        accessor: "employee.emp_id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Reason",
        accessor: "reason",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Date Requested",
        accessor: "requested_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("DD/MM/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Requested Amount",
        accessor: "requested_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Ogreem Fee",
        accessor: "agrim_fee",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Amount to Disburse",
        accessor: "amount_to_disburse",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Available Amount",
        accessor: "available_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Paynemt Type",
        id: "payment_type",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.type.toUpperCase()}
          </div>
        ),
      },
      {
        Header: "Paynemt Method",
        id: "payment_method",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.name}
          </div>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_no",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Branch Name",
        accessor: "branch",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
        Cell: ChangeStatusActions,
      },
    ],
    []
  );

  useEffect(() => {
    getEmployeeAdvancedRequests();
  }, [getEmployeeAdvancedRequests]);

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>
                  Total no. of Requests
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {detail.total_no_of_request}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>
                  Total Requested Amount
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {detail?.total_requested_amount}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>Total Fee</Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {detail?.total_fee}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>
                  Total Available Amount
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {wallet?.hold_amount + wallet?.available_amount}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CommonTable
            columns={columns}
            data={ar}
            handleClick={() => null}
            tableLoader={loading}
            // TableToolsComponent={TableTools}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeAdvancedRequest;
