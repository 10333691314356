import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  approveBatchRequest,
  cancelBatchRequest,
  disburseBatchRequest,
  getSingleSalaryRequestBatchInfo,
} from "../../../store/agrimSlice";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";
import CommonTable from "../../../components/common-table";
import { toast } from "react-toastify";
import {
  Preview,
  ArrowBack,
  // Delete,
  TaskAlt,
  Cancel,
} from "@mui/icons-material";
import { APIDataServices } from "../../../service-pattern";
import {
  Grid,
  // IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
  // Tooltip,
} from "@mui/material";
import lodash from "lodash";
import { resetAuth } from "../../../store/authSlice";
import { Alert } from "@mui/material";
import { AlertTitle } from "@mui/material";
import ManualAdvanceRequestForm from "./ManualAdvanceRequestForm";
import DeleteAdvanceRequest from "./DeleteAdvanceRequest";

const toastOptions = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

// const ToastMessage = ({ data }) => {
//     console.log(data);
//     const { error, message, reason } = data;
//     return <Alert severity="error">
//         <AlertTitle>{error}</AlertTitle>
//         {message} <strong>{reason}</strong>
//     </Alert>
// }

const SalaryRequestBatchInfo = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  // const { salaryBatchInfo } = useSelector((state) => state.agrimSlice);
  const { userData, notAdmin } = useSelector((state) => state.authSlice);
  const [salaryBatchInfo, setSalaryBatchInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [batchStatusLoading, setBatchStatusLoading] = useState(false);
  const [batchStatus, setBatchStatus] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = useCallback(async () => {
    if (location.state?.batchId) {
      const { payload } = await dispatch(
        getSingleSalaryRequestBatchInfo({
          params: { batch_id: location.state.batchId },
        })
      );
      setSalaryBatchInfo(payload);
    }
  }, [dispatch, location]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        width: 70,
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Partner",
        accessor: "partner_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee",
        accessor: "employee_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Available Amount",
        accessor: "available_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Date Requested",
        accessor: "requested_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("MM/DD/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Payment method",
        accessor: "payment_method.name",
        className: "font-bold",
        sortable: true,
        Cell: (val) => (val.value ? val.value : "-"),
      },
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Ogreem Fee",
        // width: 100,
        accessor: "agrim_fee",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Requested Amount",
        accessor: "requested_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Account Number",
        accessor: "account_no",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Actions",
        id: "delete",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {userData.id !== notAdmin ? (
              <>
                {(salaryBatchInfo.status === "draft" ||
                  salaryBatchInfo.status === "approved") && (
                  <>
                    <DeleteAdvanceRequest
                      row={row}
                      fetchData={() => fetchData()}
                    />
                    {row?.original?.payment_method_id !== 2 && (
                      <ManualAdvanceRequestForm
                        row={row}
                        fetchData={() => fetchData()}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              "-"
            )}
          </div>
        ),
      },
    ],
    [fetchData, notAdmin, salaryBatchInfo.status, userData.id]
  );

  const batchStatusColumns = useMemo(
    () => [
      { Header: "Account No.", accessor: "accNum", sortable: true },
      { Header: "Account No. Type", accessor: "accNumType", sortable: true },
      { Header: "Account Type", accessor: "accType", sortable: true },
      { Header: "Fail reason", accessor: "failReason", sortable: true },
      { Header: "Status", accessor: "status", sortable: true },
      { Header: "TRX Amount", accessor: "trxAmount", sortable: true },
    ],
    []
  );

  const handleApprove = async () => {
    const toastId = toast.loading("Approving. Please Wait..");

    const response = await dispatch(
      approveBatchRequest({
        data: { status: "approved" },
        params: { batch_id: salaryBatchInfo?.id },
      })
    );

    if (response.payload.status === 200) {
      toast.success("Batch Approve Done", toastOptions);
      fetchData();
    } else {
      toast.error("Error occoured. Please try again", toastOptions);
    }
    toast.dismiss(toastId);
  };

  const handleDisburse = async () => {
    const toastId = toast.loading("Disbursing. Please Wait..");
    const { payload: response } = await dispatch(
      disburseBatchRequest({ params: { batch_id: salaryBatchInfo?.id } })
    );

    console.log(response);
    if (response.status === 200) {
      toast.success("Batch Disburse Done", toastOptions);
      fetchData();
    } else if (response.status === 406) {
      const { error, message, reason } = response.data;
      console.log(response);
      toast(
        <Alert severity="error">
          <AlertTitle>{error}: Something wrong</AlertTitle>
          <Typography>Reason: {message}</Typography>
          <Typography>
            Code: <strong>{reason}</strong>
          </Typography>
        </Alert>,
        toastOptions
      );
    } else {
      const { detail } = response.data;

      toast.error(detail || "Error occoured. Please try again", toastOptions);
    }
    toast.dismiss(toastId);
  };

  const handleCancel = async () => {
    const toastId = toast.loading("Cancelling. Please Wait..");
    const { payload: response } = await dispatch(
      cancelBatchRequest({ params: { batch_id: salaryBatchInfo?.id } })
    );

    console.log(response);
    if (response.status === 200) {
      toast.success("Batch Cancelled", toastOptions);
      fetchData();
    } else {
      toast.error("Error occoured. Please try again", toastOptions);
    }
    toast.dismiss(toastId);
  };

  // const handleDelete = () => {};

  if (salaryBatchInfo == null) {
    return <p>loading</p>;
  }

  const handleBatchStatus = async () => {
    setBatchStatusLoading(true);
    try {
      const { data, status } = await new APIDataServices().PaymentBatchStatus({
        batch_name: salaryBatchInfo?.batch_ref,
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      } else if (status === 200) {
        console.log(data);
        setBatchStatus(data);
        handleClickOpen();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error occoured. Please try again", toastOptions);
    }
    setBatchStatusLoading(false);
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
      >
        <Link
          to={`/admin/salary-request-batch/${location.state.batchType}-batch-list`}
          className="flex items-center mb-8"
        >
          <ArrowBack sx={{ fontSize: 35 }} />
          <span className="text-2xl font-bold ml-2">
            {_.capitalize(location.state.batchType)} Batch List
          </span>
        </Link>
        {salaryBatchInfo?.status === "disbursed" && (
          <>
            <Button
              disabled={batchStatusLoading}
              startIcon={<Preview />}
              size="small"
              onClick={handleBatchStatus}
            >
              View batch status
            </Button>
            <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
              <DialogTitle>Batch Status</DialogTitle>
              <DialogContent>
                <DialogContentText>Summary</DialogContentText>
                {open && (
                  <Grid container direction="row" spacing={1}>
                    {Object.entries(batchStatus.summary)
                      .sort()
                      .map((key) => (
                        <Grid item xs={6}>
                          <Typography>
                            {lodash.startCase(lodash.camelCase(key[0]))}
                          </Typography>
                          <Typography sx={{ color: "text.secondary" }}>
                            {typeof key[1] === "boolean" ? (
                              key[1] ? (
                                <TaskAlt color="success" />
                              ) : (
                                <Cancel color="error" />
                              )
                            ) : (
                              key[1] || "-"
                            )}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                )}

                <CommonTable
                  columns={batchStatusColumns}
                  data={batchStatus.content}
                  handleClick={(_) => null}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      </Stack>

      {userData.id !== notAdmin ? (
        <div className="flex mb-8">
          {salaryBatchInfo?.status === "draft" && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={handleApprove}
              >
                Approve
              </Button>
              <Button variant="contained" color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </>
          )}

          {salaryBatchInfo?.status === "approved" && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDisburse}
              >
                Disburse
              </Button>
              <Button variant="contained" color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </>
          )}
        </div>
      ) : (
        ""
      )}
      <Grid container direction="row">
        <Grid item xs>
          <table className="border-separate border-spacing-6 border border-slate-400">
            <tbody>
              <tr>
                <td className="border border-slate-400 p-3 font-bold text-lg">
                  Batch Id
                </td>
                <td className="border border-slate-400 p-3">
                  {salaryBatchInfo?.id}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400 p-3 font-bold text-lg">
                  Batch Reference
                </td>
                <td className="border border-slate-400 p-3">
                  {salaryBatchInfo?.batch_ref === null
                    ? ""
                    : salaryBatchInfo?.batch_ref}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400 p-3 font-bold text-lg">
                  Batch Status
                </td>
                <td className="border border-slate-400 p-3">
                  {_.capitalize(salaryBatchInfo?.status)}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400 p-3 font-bold text-lg">
                  Schedule Time
                </td>
                <td className="border border-slate-400 p-3">
                  {moment(salaryBatchInfo?.scheduled_dt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400 p-3 font-bold text-lg">
                  Wallet
                </td>
                <td className="border border-slate-400 p-3">
                  {salaryBatchInfo?.wallet_name}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400 p-3 font-bold text-lg">
                  Total Request
                </td>
                <td className="border border-slate-400 p-3">
                  {salaryBatchInfo?.total_request}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400 p-3 font-bold text-lg">
                  Total Amount
                </td>
                <td className="border border-slate-400 p-3">
                  {salaryBatchInfo?.total_amount}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
      <p className="text-xl font-bold my-8">Advance Requests in this batch</p>

      {!!salaryBatchInfo?.advance_requests && (
        <CommonTable
          columns={columns}
          data={salaryBatchInfo?.advance_requests}
          handleClick={(_) => null}
        />
      )}
    </div>
  );
};

export default SalaryRequestBatchInfo;
