import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConnectivitySalaryEmployeeListTab from "./connectivity-salary-employeeList-tab";
import { useDispatch } from "react-redux";
import { partnerSync } from "../../store/partnerSlice";
import { Card, CardContent, LinearProgress } from "@mui/material";
import { grey } from "@mui/material/colors";
import EditPartnerProfile from "./EditPartnerProfile";
import { toastNotification } from "../../utils/toast";
import { resetAuth } from "../../store/authSlice";
import { APIDataServices } from "../../service-pattern";
import { useParams } from "react-router-dom";

const CompanyProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [locationState, setLocationState] = useState({});
  const dispatch = useDispatch();
  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(location.state.partnerInfo);
    if (location.state !== null) {
      setLocationState((ls) => ({
        // ...location.state.partnerInfo,
        ...ls,
        tabValue: location.state.tabValue,
        pageName: location.state.pageName,
      }));
    }
  }, [location]);

  const handleNavigation = () => {
    if (locationState.pageName === "Company List") {
      navigate("/admin/company-list");
    }
    if (locationState.pageName === "Dashboard") {
      navigate("/admin");
    }
  };

  const handlePartnerSync = () => {
    dispatch(partnerSync(id));
  };
  
  const getPartnerInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data, status } = await new APIDataServices().PartnerGet({
        partner_id: id,
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      }
      if (status !== 200) {
        toastNotification(
          "error",
          "Error loading partner profile. Please Try Again"
        );
      }
      setPartner(data);
    } catch (_) {
      toastNotification(
        "error",
        "Error loading partner profile. Please Try Again"
      );
    }
    setLoading(false);
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      getPartnerInfo();
    }
  }, [getPartnerInfo, id]);

  if (Object.keys(locationState).length === 0) {
    return null;
  }

  return (
    <div className="company-profile-app">
      {!loading ? (
        <>
          <div
            className="flex items-center mb-8 cursor-pointer"
            onClick={handleNavigation}
          >
            <ArrowBackIcon sx={{ fontSize: 35 }} />
            <span className="text-2xl font-bold ml-2">
              {locationState.pageName}
            </span>
          </div>

          <div>
            <p className="font-bold text-xl">Company Profile</p>
            <div className="flex">
              <Card
                className="w-card  mt-4"
                sx={{ bgcolor: grey[200] }}
                elevation={0}
              >
                <EditPartnerProfile
                  partner={partner}
                  getPartnerInfo={() => getPartnerInfo()}
                />
                <CardContent>
                  <p className="">Name: {partner?.name}</p>
                  <p className="">Address: {partner?.address}</p>
                  <p className="">
                    Status: {partner?.status ? "Active" : "Inactive"}
                  </p>
                </CardContent>
              </Card>

              <button
                className="p-6 bg-blue-400 mb-8 ml-20 mt-4 h-20"
                onClick={handlePartnerSync}
              >
                Payment Method Sync
              </button>
            </div>
          </div>
          {id && (
            <ConnectivitySalaryEmployeeListTab
              companyName={partner?.name}
              partnerId={partner?.id}
              partnerInfo={partner}
              tabValue={location?.state?.tabValue}
              pageName={location?.state?.pageName}
            />
          )}
        </>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default CompanyProfile;
