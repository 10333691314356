import { FileDownload } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useCallback, useState } from "react";
import { getReports, getReportSummary } from "../../pages/reports/queries";
import { removeEmptyValues, toTitleCase } from "../../utils/helper";
import { toastNotification } from "../../utils/toast";
import moment from "moment";
import * as XLSX from "xlsx";

const ExportToExcelGB = ({ loading, groupBy, level, params, filter }) => {
  const [dataLoading, setDataLoading] = useState(false);

  const exportExcel = useCallback(async () => {
    setDataLoading(true);
    try {
      let data;
      if (!!!groupBy[level]) {
        let filterParams = removeEmptyValues(filter);
        // if (params.employer_pay_cycle) {
        //   console.log(params.employer_pay_cycle);
        //   let paycycle = params?.employer_pay_cycle.split(" ");
        //   let from_month = parseInt(
        //     `${paycycle?.[0]?.[5]}${paycycle?.[0]?.[6]}`
        //   );
        //   let from_year = parseInt(
        //     `${paycycle?.[0]?.[0]}${paycycle?.[0]?.[1]}${paycycle?.[0]?.[2]}${paycycle?.[0]?.[3]}`
        //   );
        //   let to_month = parseInt(`${paycycle?.[2]?.[5]}${paycycle?.[2]?.[6]}`);
        //   let to_year = parseInt(
        //     `${paycycle?.[2]?.[0]}${paycycle?.[2]?.[1]}${paycycle?.[2]?.[2]}${paycycle?.[2]?.[3]}`
        //   );
        //   // console.log({
        //   //   from_year,
        //   //   from_month,
        //   //   to_year,
        //   //   to_month,
        //   // });
        //   filterParams = {
        //     ...filterParams,
        //     from_year,
        //     from_month,
        //     to_year,
        //     to_month,
        //   };
        // }
        let grpBy = groupBy[level - 1];
        // alert(groupBy);
        // console.log(params, groupBy[level - 1]);
        if (!!grpBy && grpBy === "employer_pay_cycle") {
          let from_time = moment(params?.employer_pay_cycle.slice(0, 10));
          let to_time = moment(params?.employer_pay_cycle.slice(14, 25)).add(
            1,
            "months"
          );
          // console.log(
          //   params?.employer_pay_cycle.slice(14, 25),
          //   to_time.format("MM"),
          //   to_time.format("YYYY")
          // );
          filterParams = {
            ...filterParams,
            from_year: parseInt(from_time.format("YYYY")),
            from_month: parseInt(from_time.format("MM")),
            to_year: parseInt(to_time.format("YYYY")),
            to_month: parseInt(to_time.format("MM")),
          };
        } else {
          filterParams = {
            ...filterParams,
            from_year: moment(new Date()).subtract(3, "months").format("YYYY"),
            from_month: moment(new Date()).subtract(3, "months").format("MM"),
            to_year: parseInt(moment().format("YYYY")),
            to_month: parseInt(moment().format("MM")),
          };
        }
        // if (grpBy === "employer_pay_cycle") {
        //   delete filterParams.employer_pay_cycle;
        // }
        const { data: resData } = await getReports({
          ...params,
          ...filterParams,
        });
        data = resData;
        let keys = [
          "employee_name",
          "id_number",
          "gender",

          "employer_pay_cycle",
          "number_of_days_worked",
          "salary_earned",

          "withdrawable_amount",
          "requested_amount",
          "carry_over_amount",
          "mfs_wallet",
          "bank_account",

          "requested_date",

          "disbursement_date",

          "disbursement_amount",

          "service_charge",

          "cost_of_finance_amount",

          "net_revenue",
          "expected_payment_date",
          "actual_reimnursement_date",
          "penalty_interest",
          "penalty_amount",
          "status",
        ];
        data = resData.map((rd) => {
          let row = {};
          keys.map((key) => {
            row[toTitleCase(key)] = [
              "requested_date",
              "disbursement_date",
              "expected_payment_date",
            ].includes(key)
              ? moment(rd[key]).isValid()
                ? moment(rd[key]).format("DD-MM-YYYY")
                : ""
              : rd[key];
            return key;
          });
          return row;
        });
      } else {
        data = await getReportSummary({
          ...params,
          groupby: groupBy[level],
        });
      }
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `Report-${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
      );
    } catch (error) {
      toastNotification("error", error.message);
    }
    setDataLoading(false);
  }, [filter, groupBy, level, params]);
  //   useEffect(() => {
  //     if (open) {
  //       fetchData();
  //     }
  //   }, [fetchData, open]);

  return (
    <>
      <Button
        startIcon={dataLoading ? <CircularProgress /> : <FileDownload />}
        disabled={loading || dataLoading}
        variant="contained"
        onClick={exportExcel}
        color="success"
      >
        Export to excel
      </Button>
    </>
  );
};

export default ExportToExcelGB;
