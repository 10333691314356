import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  LinearProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { auth } from "../../store/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogInPage = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();

  const { errorMessage } = useSelector((state) => state.authSlice);

  const [authData, setAuthData] = useState({ username: "", password: "" });

  const handleChange = (event) => {
    setAuthData({
      ...authData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.set("username", authData.username);
    formData.set("password", authData.password);
    let result = await dispatch(auth(formData));
    if (result.payload.status === 200) {
      navigate("/admin");
    }
    setLoading(false);
  };

  return (
    <div className="h-screen bg-primaryBackground flex justify-center items-center">
      <form
        className="w-100 lg:w-118.75 bg-white p-8 text-center rounded-lg border-2 border-primaryBorder"
        onSubmit={handleSignIn}
      >
        <p className="text-2xl text-primaryText font-bold mb-4">
          Hi, Welcome Back
        </p>
        <p className="text-base text-mutedText">
          Enter your credentials to continue
        </p>

        <FormControl fullWidth sx={{ marginTop: 4 }}>
          <InputLabel htmlFor="outlined-adornment-email-login">
            Email Address / Username
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-email-login"
            // type="email"
            value={authData.username}
            name="username"
            // onBlur={handleBlur}
            onChange={handleChange}
            label="Email Address / Username"
            inputProps={{
              required: true,
            }}
            sx={{
              borderRadius: 3,
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <InputLabel htmlFor="outlined-adornment-password-login">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password-login"
            type={showPassword ? "text" : "password"}
            value={authData.password}
            name="password"
            // onBlur={handleBlur}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            inputProps={{
              required: true,
            }}
            sx={{
              borderRadius: 3,
            }}
          />
        </FormControl>

        {errorMessage && (
          <p className="mt-2 text-red-500 font-bold text-start">
            {errorMessage}
          </p>
        )}

        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={loading}
          // className={`w-full p-2 flex justify-center items-center bg-primaryText text-white ${
          //   authData.username !== "" && authData.password !== ""
          //     ? "cursor-pointer"
          //     : "cursor-not-allowed"
          // } ${errorMessage ? "mt-4" : "mt-6"} rounded-lg`}
          sx={{ bgcolor: "#673ab7", mt: 1 }}
        >
          Sign in
        </Button>
        {loading ? <LinearProgress /> : ""}

        <div className="flex justify-between items-center mt-4">
          <FormControlLabel
            control={
              <Checkbox
                // checked={checked}
                // onChange={(event) => setChecked(event.target.checked)}
                name="checked"
                color="primary"
              />
            }
            label="Remember me"
          />
          <p className="text-sm cursor-pointer text-primaryText">
            Forgot Password
          </p>
        </div>
      </form>
    </div>
  );
};

export default LogInPage;
