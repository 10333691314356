import React, { useEffect, useState } from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import Select from "react-select";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  createOgreemFee,
  setOgreemFeeModal,
  updateOgreemFee,
} from "../../store/partnerSlice";

const feeTypeOptions = [
  { label: "Fixed", value: "fixed" },
  { label: "Percentage", value: "percentage" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F7F5ED",
  boxShadow: 24,
  p: 3,
  borderTopLeftRadius: "20px",
  borderBottomRightRadius: "20px",
};

const OgreemFeeModal = ({ partnerId }) => {
  const dispatch = useDispatch();

  const { ogreemFeeList, ogreemFeeModal } = useSelector(
    (state) => state.partnerSlice
  );

  const [slab, setSlab] = useState({
    from_amount: 0,
    to_amount: 0,
    fee_type: null,
    fee_amount: 0,
  });

  useEffect(() => {
    if (ogreemFeeModal.type === "new") {
      setSlab({
        from_amount: 0,
        to_amount: 0,
        fee_type: null,
        fee_amount: 0,
      });
    }
    if (ogreemFeeModal.type === "edit") {
      const selectedFee = ogreemFeeList.find(
        (fee) => fee.id === ogreemFeeModal.id
      );
      setSlab({
        from_amount: selectedFee.from_amount,
        to_amount: selectedFee.to_amount,
        fee_type: selectedFee.fee_type,
        fee_amount: selectedFee.fee_amount,
        partner_id: selectedFee.partner_id,
        id: selectedFee.id,
      });
    }
  }, [ogreemFeeModal, ogreemFeeList]);

  const handleValueChange = (name, value) => {
    let modifiedValue = null;

    if (
      name === "from_amount" ||
      name === "to_amount" ||
      name === "fee_amount"
    ) {
      if (value === "") {
        modifiedValue = value;
      } else {
        modifiedValue = parseFloat(value);
      }
    } else {
      modifiedValue = value;
    }

    setSlab({
      ...slab,
      [name]: modifiedValue,
    });
  };

  const handleSubmit = () => {
    let feeData = { ...slab, partner_id: partnerId };
    dispatch(createOgreemFee(feeData));
  };

  const handleClose = () => {
    dispatch(setOgreemFeeModal({ open: false, type: "new", id: null }));
  };

  const handleUpdate = () => {
    dispatch(updateOgreemFee(slab));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={ogreemFeeModal.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableAutoFocus={true}
    >
      <Fade in={ogreemFeeModal.open}>
        <Box sx={style} className="login-alert">
          <div className="w-full h-full relative">
            <div className="modal-close-button" onClick={handleClose}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7 5.3C18.5 5.1 18.3 5 18 5C17.7 5 17.5 5.1 17.3 5.3L12 10.6L6.7 5.3C6.3 4.9 5.7 4.9 5.3 5.3C4.9 5.7 4.9 6.3 5.3 6.7L10.6 12L5.3 17.3C5.1 17.5 5 17.7 5 18C5 18.3 5.1 18.5 5.3 18.7C5.5 18.9 5.7 19 6 19C6.3 19 6.5 18.9 6.7 18.7L12 13.4L17.3 18.7C17.5 18.9 17.7 19 18 19C18.3 19 18.5 18.9 18.7 18.7C18.9 18.5 19 18.3 19 18C19 17.7 18.9 17.5 18.7 17.3L13.4 12L18.7 6.7C18.9 6.5 19 6.3 19 6C19 5.7 18.9 5.5 18.7 5.3Z"
                  fill="#1A1A1A"
                />
              </svg>
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">From Amount</p>
              <input
                type="number"
                name="from_amount"
                value={slab.from_amount}
                className="border-2 w-full col-span-2 border-black pl-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">To Amount</p>
              <input
                type="number"
                name="to_amount"
                value={slab.to_amount}
                className="w-full col-span-2 border-2 border-black pl-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            <div className="grid grid-cols-3 mb-6">
              <div className="flex items-center">
                <p className="">Fee Type</p>
              </div>
              <div className="w-full col-span-2">
                <Select
                  options={feeTypeOptions}
                  styles={customStyles}
                  value={
                    slab.fee_type
                      ? {
                          label: _.capitalize(slab.fee_type),
                          value: slab.fee_type,
                        }
                      : null
                  }
                  onChange={(event) =>
                    handleValueChange("fee_type", event.value)
                  }
                />
              </div>
            </div>

            <div className="grid grid-cols-3 mb-6">
              <p className="">Fee</p>
              <input
                type="number"
                name="fee_amount"
                value={slab.fee_amount}
                className="w-full col-span-2 border-2 border-black pl-2"
                onChange={(event) =>
                  handleValueChange(event.target.name, event.target.value)
                }
              />
            </div>

            <div className="flex justify-end">
              {ogreemFeeModal.type === "edit" ? (
                <button className="p-4 bg-green-500" onClick={handleUpdate}>
                  Update
                </button>
              ) : (
                <button className="p-4 bg-green-500" onClick={handleSubmit}>
                  Submit
                </button>
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default OgreemFeeModal;
