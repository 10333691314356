import React, { useEffect, useState } from "react";
import {
  companyCreate,
  companyDataGet,
  companyUpdate,
} from "../../store/agrimSlice";
import { useDispatch, useSelector } from "react-redux";

const OgrimProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(companyDataGet());
  }, [dispatch]);

  const { companyData } = useSelector((state) => state.agrimSlice);

  const [ogrimProfile, setOgrimProfile] = useState({
    name: "",
    address: "",
    balance: 0,
    max_amount: 0,
    max_percentage: 0,
  });

  useEffect(() => {
    if (companyData !== null) {
      setOgrimProfile({
        name: companyData.name,
        address: companyData.address,
        balance: companyData.balance,
        max_amount: companyData.max_amount,
        max_percentage: companyData.max_percentage,
      });
    } else {
      setOgrimProfile({
        name: "",
        address: "",
        balance: 0,
        max_amount: 0,
        max_percentage: 0,
      });
    }
  }, [companyData]);

  const handleValueChange = (event) => {
    let value = null;

    if (
      ["balance", "max_amount", "max_percentage"].includes(event.target.name)
    ) {
      value = parseFloat(event.target.value);
    } else {
      value = event.target.value;
    }

    setOgrimProfile((prevState) => {
      return {
        ...prevState,
        [event.target.name]: value,
      };
    });
  };

  const handleSave = () => {
    dispatch(companyCreate(ogrimProfile));
  };

  const handleUpdate = () => {
    dispatch(companyUpdate({ data: ogrimProfile, company_id: companyData.id }));
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-6">
        <div className="flex justify-between items-center mb-4">
          <p>Name:</p>
          <textarea
            className="border-2 border-black w-[75%] p-2"
            value={ogrimProfile.name || ""}
            name="name"
            onChange={handleValueChange}
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <p>Address:</p>
          <textarea
            className="border-2 border-black w-[75%] p-2"
            value={ogrimProfile.address || ""}
            rows={3}
            name="address"
            onChange={handleValueChange}
          />
        </div>
        <div className="flex justify-between items-center">
          <p>Balance:</p>
          <input
            className="border-2 border-black w-[75%] p-2"
            type="number"
            value={ogrimProfile.balance}
            name="balance"
            onChange={handleValueChange}
          />
        </div>
        <div className="flex justify-between items-center">
          <p>Max Amount:</p>
          <input
            className="border-2 border-black w-[75%] p-2"
            type="number"
            value={ogrimProfile.max_amount}
            name="max_amount"
            onChange={handleValueChange}
          />
        </div>
        <div className="flex justify-between items-center">
          <p>Max Percentage:</p>
          <input
            className="border-2 border-black w-[75%] p-2"
            type="number"
            value={ogrimProfile.max_percentage}
            name="max_percentage"
            onChange={handleValueChange}
          />
        </div>

        {companyData ? (
          <button className="p-4 bg-yellow-500 mt-20" onClick={handleUpdate}>
            Update
          </button>
        ) : (
          <button className="p-4 bg-green-500 mt-20" onClick={handleSave}>
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default OgrimProfile;
