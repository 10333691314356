import React, { useEffect, useMemo } from "react";
import CommonTable from "../../components/common-table";
import { useNavigate, Link } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { IconButton } from "@mui/material";
import { partnerGetAll } from "../../store/partnerSlice";
import { useDispatch, useSelector } from "react-redux";

const CompanyList = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();

  const { userData, notAdmin } = useSelector((state) => state.authSlice);
  const { partnerList } = useSelector((state) => state.partnerSlice);

  useEffect(() => {
    dispatch(partnerGetAll());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Company Address",
        accessor: "address",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Status",
        id: "status",
        className: "font-bold",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.status ? "Active" : "Inactive"}
          </div>
        ),
      },
    ],
    []
  );

  const handleConfigure = (ev, row) => {
    ev.stopPropagation();
    const id = row.original.id;
    const URL = `/admin/company-profile/${id}`;
    // navigate(URL, { state: { id: row.original.id, name: row.original.name, address: row.original.address, status: row.original.status } })
    navigate(URL, {
      state: {
        partnerInfo: { ...row.original },
        tabValue: 0,
        pageName: "Company List",
      },
    });
  };

  return (
    <div className="company-list-app">
      {userData.id !== notAdmin ? (
        <Link
          className="p-4 bg-yellow-500 mb-6 inline-block"
          to="/admin/partner-create"
        >
          Create Partner
        </Link>
      ) : (
        ""
      )}
      <CommonTable
        columns={columns}
        data={partnerList}
        handleClick={(event, row) => handleConfigure(event, row)}
      />
    </div>
  );
};

export default CompanyList;
