import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIDataServices } from "../service-pattern";
import jwt from "jwt-decode";

export const auth = createAsyncThunk(
  "authSlice/auth",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices().Auth(formData).then((result) => {
      if (result.status === 200) {
        dispatch(setToken(result.data.access_token));
        sessionStorage.setItem("token", result.data.access_token);
        let decoded = jwt(result.data.access_token);
        let user = {
          id: decoded.user_id || "",
        };
        dispatch(setUser(user));
        dispatch(setErrorMessage(null));
      }
      if (result.status === 400) {
        dispatch(setErrorMessage(result.data.detail));
      }

      return { data: result.data, status: result.status };
    });

    return data;
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    name: "manto",
    token: null,
    userData: {
      id: "",
    },
    errorMessage: null,
    // notAdmin: "e77ffbfa-32aa-4e3e-a626-ceb973294b84",
    notAdmin: "536f9645-e412-46a9-8a8f-eb179c4d001c",
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    resetAuth: (state) => {
      state.token = null;
      state.userData = {
        id: "",
      };
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setToken, setErrorMessage, setUser, resetAuth } =
  authSlice.actions;

export default authSlice.reducer;
