import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TableSearch from "../TableSearch";
import ReloadButton from "../ReloadButton";
import { invoiceGetAll } from "../../../store/invoiceSlice";
import {
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { partnerGetAll } from "../../../store/partnerSlice";
/* import { useDispatch } from 'react-redux'; */

const ITableTools = ({ table }) => {
  const [filterPartner, setFilterPartner] = useState("all");
  const { partnerList } = useSelector((state) => state.partnerSlice);
  let [, setSearchParams] = useSearchParams();
  const {
    /* selectedFlatRows, */
    /* state: { selectedRowIds }, */
    setGlobalFilter,
  } = table;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(partnerGetAll());
  }, [dispatch]);
  useEffect(() => {
    if (filterPartner === "all") {
      setSearchParams({});
    } else {
      setSearchParams({
        partner_id: filterPartner,
      });
    }
  }, [filterPartner, setSearchParams]);
  const handleChange = (e) => {
    setFilterPartner(e.target.value);
  };
  const navigate = useNavigate();
  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ButtonGroup>
          <ReloadButton reloadFunc={invoiceGetAll} />
          <IconButton onClick={() => navigate("create")}>
            <Add />
          </IconButton>
        </ButtonGroup>
        <Box>
          <TableSearch setGlobalFilter={setGlobalFilter} />
          <FormControl size="small" sx={{ ml: 2 }}>
            <InputLabel id="partner-select-label">Filter by partner</InputLabel>
            <Select
              sx={{ width: "250px" }}
              size="small"
              labelId="partner-select-label"
              id="partner-select"
              value={filterPartner}
              label="Filter by partner"
              onChange={handleChange}
            >
              <MenuItem value="all">All</MenuItem>
              {partnerList.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {partner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Grid>
  );
};

export default ITableTools;
