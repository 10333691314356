import { APIRequestServices } from "../../service-pattern";
import { store } from "../../store";
import { resetAuth } from "../../store/authSlice";

export const getEmployeeProfile = async (params) => {
  try {
    let employeedata = {
      profile: {},
      config: {},
      detail: {},
    };
    const { data, status } = await new APIRequestServices().Get({
      URL: `employees`,
      getRawResponse: true,
      data: params,
    });
    if (status === 401) {
      sessionStorage.removeItem("token");
      store.dispatch(resetAuth());
    }
    employeedata.profile = data;
    const { data: detailData, status: detailStatus } =
      await new APIRequestServices().Get({
        URL: `employees/details`,
        getRawResponse: true,
        data: params,
      });
    if (detailStatus === 401) {
      sessionStorage.removeItem("token");
      store.dispatch(resetAuth());
    }
    employeedata.detail = detailData;
    if (data.partner_id) {
      const { data: configData, configStatus } =
        await new APIRequestServices().Get({
          URL: `config`,
          getRawResponse: true,
          data: {
            partner_id: data.partner_id,
          },
        });
      if (configStatus === 401) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
      }
      employeedata.config = configData;
    }
    return employeedata;
  } catch (error) {
    throw error;
  }
};
