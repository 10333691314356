import React, { useEffect, useMemo, useState } from "react";
// import Select from "react-select";
import CommonTable from "../../components/common-table";
import { useNavigate } from "react-router-dom";
// import { IconButton } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import {
  partnerEmployeeSync,
  partnerEmployeeSyncGetData,
} from "../../store/partnerSlice";
// import _ from "lodash";
import TableSearch from "../../components/page-components/TableSearch";

const CompanyEmployeeList = ({
  // companyName,
  partnerId,
  // partnerInfo,
  // pageName,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(partnerEmployeeSyncGetData({ partner_id: partnerId, offset: offset, limit: limit }));
  }, [dispatch, partnerId, offset, limit]);

  const { partnerEmployeeListData } = useSelector(
    (state) => state.partnerSlice
  );

  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  // const actionOptions = [
  //   { label: "Active", value: "active" },
  //   { label: "Inactive", value: "inactive" },
  //   { label: "Hold", value: "hold" },
  // ];

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee Id",
        accessor: "emp_id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Mobile",
        accessor: "phone",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Salary",
        accessor: "salary",
        className: "font-bold",
        width: 100,
        sortable: true,
      },
      {
        Header: "Job Status",
        accessor: "job_status",
        className: "font-bold",
        width: 100,
        sortable: true,
      },
      {
        Header: "Active Status",
        id: "active_status",
        width: 100,
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.active_status ? "Active" : "Inactive"}
          </div>
        ),
      },
      {
        Header: "Paynemt Type",
        id: "payment_type",
        width: 100,
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method?.type.toUpperCase()}
          </div>
        ),
      },
      {
        Header: "Paynemt Method",
        id: "payment_method",
        width: 100,
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method?.name}
          </div>
        ),
      },
    ],
    []
  );

  const handleEmployeeProfileView = (event, row) => {
    event.stopPropagation();
    // const name = row.original.name
    //   .trim()
    //   .replace(/[\. ,:-]+/g, "-")
    //   .toLowerCase();
    // const compName = companyName
    //   .trim()
    //   .replace(/[\. ,:-]+/g, "-")
    //   .toLowerCase();
    // const URL = `/admin/company-profile/${compName}/employee-profile/${name}`;
    navigate(`/admin/employee-profile/${row.original.id}`);
  };

  const handleEmployeeSync = () => {
    dispatch(partnerEmployeeSync({ partner_id: partnerId }));
  };

  return (
    <div>
      <div>
        <button className="p-4 bg-yellow-500 mb-6" onClick={handleEmployeeSync}>
          Employee Sync
        </button>
      </div>

      <CommonTable
        request_type="advance"
        changePage={changePage}
        offset={offset}
        limit={limit}
        columns={columns}
        data={partnerEmployeeListData}
        handleClick={(event, row) => handleEmployeeProfileView(event, row)}
        TableSearch={TableSearch}
      />
    </div>
  );
};

export default CompanyEmployeeList;
