import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/common-table";
import { partnerEmployeeSalaryRequestData } from "../../store/partnerSlice";
import moment from "moment";
import { Chip } from "@mui/material";
import labels from "../../utils/labels";

const PartnerEmployeeSalaryRequest = ({ partnerId }) => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const {
    partnerEmployeeSalaryRequestList,
    partnerEmployeeSalaryRequestListLoading,
  } = useSelector((state) => state.partnerSlice);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee",
        accessor: "employee_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Employee ID",
        accessor: "employee",
        className: "font-bold",
        sortable: true,
        Cell: (val) => (val.value.emp_id ? val.value.emp_id : "-"),
      },
      {
        Header: "Reason",
        accessor: "reason",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Date Requested",
        accessor: "requested_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("DD/MM/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Date Disbursed",
        accessor: "disbursed_at",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("DD/MM/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Requested Amount",
        accessor: "requested_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Ogreem Fee",
        accessor: "agrim_fee",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Available Amount",
        accessor: "available_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Paynemt Type",
        id: "payment_type",
        width: 100,
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.type.toUpperCase()}
          </div>
        ),
      },
      {
        Header: "Paynemt Method",
        id: "payment_method",
        width: 100,
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.payment_method.name}
          </div>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_no",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Branch Name",
        accessor: "branch",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
        Cell: ({ value }) => (
          <Chip size="small" label={value} color={labels[value]} />
        ),
      },
    ],
    []
  );

  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  useEffect(() => {
    dispatch(partnerEmployeeSalaryRequestData({ partner_id: partnerId, offset: offset, limit: limit }));
  }, [dispatch, partnerId, offset, limit]);

  return (
    <div>
      <CommonTable
        request_type="advance"
        columns={columns}
        data={partnerEmployeeSalaryRequestList}
        changePage={ changePage }
        offset={offset}
        limit={limit}
        handleClick={(event, row) => console.log("")}
        tableLoader={partnerEmployeeSalaryRequestListLoading}
      />
    </div>
  );
};

export default PartnerEmployeeSalaryRequest;
