import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import FilterList from "@mui/icons-material/FilterList";
import { useDispatch, useSelector } from "react-redux";
import {
  partnerEmployeeSalaryRequestData,
  setPartnerEmployeeSalaryRequestList,
} from "../../../store/partnerSlice";

import Select from "react-select";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { toast } from "react-toastify";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { getPaymentMethodList } from "../../../store/agrimSlice";

const toastOptions = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F7F5ED",
  boxShadow: 24,
  p: 3,
  borderTopLeftRadius: "20px",
  borderBottomRightRadius: "20px",
};

const advanceRequestStatusOptions = [
  { label: "All", value: {} },
  { label: "Valid", value: "valid" },
  { label: "Invalid", value: "invalid" },
  { label: "Batch Created", value: "batch_created" },
  { label: "Batch Approved", value: "batch_approved" },
  { label: "Disbursed", value: "disbursed" },
  { label: "Invoiced", value: "invoiced" },
  { label: "Done", value: "done" },
  { label: "Cancelled", value: "cancelled" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

// const initialValues = {
//     status: "",
//     employee_id: 0,
//     partner_id: 0,
//     date_from: null,
//     date_to: null,
// };

const FilterDialog = ({ status, filter_data={}, handleSettingFilterData=() => {} }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { paymentMethodList } = useSelector((state) => state.agrimSlice);

  useEffect(() => {
    dispatch(getPaymentMethodList());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  useEffect(() => {
    let status = location.pathname.split("/")[2].split("-")[3];
    let value = null;
    if (status === "all") {
      value = {};
    } else {
      value = status;
    }
    setSelectedStatus({ label: _.capitalize(status), value });
  }, [location]);

  useEffect(() => {
    if (paymentMethodList.length) {
      const paymentMethodOptionsArray = paymentMethodList.map((method) => ({
        label: method.name,
        value: method.id,
      }));
      setPaymentMethodOptions(paymentMethodOptionsArray);
    }
  }, [paymentMethodList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const toastId = toast.loading("Filtering. Please Wait..");

    let query = {
      ...filter_data,
      from_date: dateFrom
        ? moment(dateFrom).format("YYYY-MM-DD[T]HH:mm:ss")
        : null,
      to_date: dateTo ? moment(dateTo).format("YYYY-MM-DD[T]HH:mm:ss") : null,
      payment_str: selectedPaymentMethod ? selectedPaymentMethod.label : null,
    };

    if (selectedStatus.label !== "All") {
      query = {
        ...query,
        status: selectedStatus.value,
      };
    }

    handleSettingFilterData(query)

    let response;

    // if (selectedStatus.label == "All") {
    //     response = await dispatch(partnerEmployeeSalaryRequestData());
    // } else {
    //     response = await dispatch(partnerEmployeeSalaryRequestData({ status: selectedStatus.value }));
    // }

    response = await dispatch(partnerEmployeeSalaryRequestData(query));

    console.log("====================", query);
    if (response.payload.status === 200) {
      // if (selectedPaymentMethod != null) {
      //   const filteredData = response.payload.data.filter(
      //     (value) => value.payment_method.name === selectedPaymentMethod.label
      //   );
      //   dispatch(setPartnerEmployeeSalaryRequestList(filteredData));
      // }
      setOpen(false);
      toast.dismiss(toastId);
      toast.success("Filtering Done", toastOptions);
    } else {
      setOpen(false);
      toast.dismiss(toastId);
      toast.error("Error occoured. Please try again", toastOptions);
    }
  };

  return (
    <>
      <Button startIcon={<FilterList />} onClick={handleClickOpen}>
        Filter
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableAutoFocus={true}
      >
        <Fade in={open}>
          <Box sx={style} className="login-alert">
            <div className="w-full h-full relative">
              <div className="modal-close-button" onClick={handleClose}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7 5.3C18.5 5.1 18.3 5 18 5C17.7 5 17.5 5.1 17.3 5.3L12 10.6L6.7 5.3C6.3 4.9 5.7 4.9 5.3 5.3C4.9 5.7 4.9 6.3 5.3 6.7L10.6 12L5.3 17.3C5.1 17.5 5 17.7 5 18C5 18.3 5.1 18.5 5.3 18.7C5.5 18.9 5.7 19 6 19C6.3 19 6.5 18.9 6.7 18.7L12 13.4L17.3 18.7C17.5 18.9 17.7 19 18 19C18.3 19 18.5 18.9 18.7 18.7C18.9 18.5 19 18.3 19 18C19 17.7 18.9 17.5 18.7 17.3L13.4 12L18.7 6.7C18.9 6.5 19 6.3 19 6C19 5.7 18.9 5.5 18.7 5.3Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </div>

              {status === "all" && (
                <div className="grid grid-cols-3 mb-6">
                  <div className="flex items-center">
                    <p className="">Request Status</p>
                  </div>
                  <div className="w-full col-span-2">
                    <Select
                      options={advanceRequestStatusOptions}
                      styles={customStyles}
                      placeholder="Select Status"
                      value={selectedStatus}
                      onChange={(event) => setSelectedStatus(event)}
                    />
                  </div>
                </div>
              )}

              <div className="grid grid-cols-3 mb-6">
                <div className="flex items-center">
                  <p className="">Payment Method</p>
                </div>
                <div className="w-full col-span-2">
                  <Select
                    options={paymentMethodOptions}
                    styles={customStyles}
                    placeholder="Select Payment Method"
                    value={selectedPaymentMethod}
                    onChange={(event) => setSelectedPaymentMethod(event)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 mb-6">
                <div className="flex items-center">
                  <p className="">Date From</p>
                </div>
                <div className="w-full col-span-2">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      onChange={(event) => setDateFrom(event)}
                      renderInput={(params) => (
                        <TextField label="Date From" size="small" {...params} />
                      )}
                      value={dateFrom}
                      className="w-full"
                      // {...restField}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="grid grid-cols-3 mb-6">
                <div className="flex items-center">
                  <p className="">Date To</p>
                </div>
                <div className="w-full col-span-2">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      onChange={(event) => setDateTo(event)}
                      renderInput={(params) => (
                        <TextField label="Date To" size="small" {...params} />
                      )}
                      value={dateTo}
                      className="w-full"
                      // {...restField}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mt-8 flex justify-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Filter
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FilterDialog;
