import { PointOfSale } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { APIDataServices } from "../../../service-pattern";

const toastOptions = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const ManualAdvanceRequestForm = ({ row, fetchData }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (values) => {
    const toastId = toast.loading("Updating. Please Wait..");
    try {
      const { status } = await new APIDataServices().CreateManualTransaction({
        request_id: row.original.id,
        transaction_id: values.transaction_id,
      });
      if (status === 200) {
        toast.success("Transaction ID updated", toastOptions);
        fetchData();
        handleClose();
      } else {
        toast.error("Error occoured. Please try again", toastOptions);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error occoured. Please try again", toastOptions);
    }
    toast.dismiss(toastId);
  };

  return (
    <>
      <Tooltip title="Update transaction">
        <IconButton onClick={handleClickOpen}>
          <PointOfSale sx={{ fontSize: 20, color: "blue" }} />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleClose} open={open}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Update Transactions</DialogTitle>
          <DialogContent>
            {row?.original?.payment_method_id === 2 ? (
              <Typography>
                Manual transaction not allowed for nagad wallets
              </Typography>
            ) : (
              <TextField
                sx={{ my: 3 }}
                error={errors.transaction_id}
                label="Transaction ID"
                {...register("transaction_id", { required: true })}
              />
            )}
          </DialogContent>
          {row?.original?.payment_method_id !== 2 && (
            <DialogActions>
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Confirm</Button>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default ManualAdvanceRequestForm;
