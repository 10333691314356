import React from 'react'
import { Backdrop, Box, Modal, Fade } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { setPaymentMethodSyncResultModal } from '../../store/agrimSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F7F5ED',
    boxShadow: 24,
    p: 3,
    borderTopLeftRadius: '20px',
    borderBottomRightRadius: '20px',
};


const SyncResultModal = () => {

    const dispatch = useDispatch()

    const { paymentMethodSyncResultModal } = useSelector(state => state.agrimSlice)

    const handleClose = () => {
        dispatch(setPaymentMethodSyncResultModal({ open: false, data: [] }))
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={paymentMethodSyncResultModal.open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableAutoFocus={true}
        >
            <Fade in={paymentMethodSyncResultModal.open}>
                <Box sx={style} className="login-alert">
                    <div className='w-full h-full relative'>
                        <div className='modal-close-button' onClick={handleClose}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7 5.3C18.5 5.1 18.3 5 18 5C17.7 5 17.5 5.1 17.3 5.3L12 10.6L6.7 5.3C6.3 4.9 5.7 4.9 5.3 5.3C4.9 5.7 4.9 6.3 5.3 6.7L10.6 12L5.3 17.3C5.1 17.5 5 17.7 5 18C5 18.3 5.1 18.5 5.3 18.7C5.5 18.9 5.7 19 6 19C6.3 19 6.5 18.9 6.7 18.7L12 13.4L17.3 18.7C17.5 18.9 17.7 19 18 19C18.3 19 18.5 18.9 18.7 18.7C18.9 18.5 19 18.3 19 18C19 17.7 18.9 17.5 18.7 17.3L13.4 12L18.7 6.7C18.9 6.5 19 6.3 19 6C19 5.7 18.9 5.5 18.7 5.3Z" fill="#1A1A1A" />
                            </svg>
                        </div>

                        <table className='border-separate border-spacing-6 border border-slate-400'>
                            <tbody>
                                {
                                    paymentMethodSyncResultModal.data.map((value, index) => (
                                        <tr>
                                            <td className='border border-slate-400 p-3 font-bold text-lg'>{value.url}</td>
                                            <td className='border border-slate-400 p-3'>{value.message}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default SyncResultModal