import React, { useCallback, useEffect, useMemo } from "react";
import {
  getPaymentMethodList,
  getPaymentWalletList,
  setPaymentWalletModal,
} from "../../store/agrimSlice";
import { useDispatch, useSelector } from "react-redux";
import PaymentWalletModal from "./payment-wallet-modal";
import CommonTable from "../../components/common-table";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const OgrimPaymentWallet = () => {
  const dispatch = useDispatch();

  const { paymentWalletList } = useSelector((state) => state.agrimSlice);

  useEffect(() => {
    dispatch(getPaymentMethodList());
    dispatch(getPaymentWalletList());
  }, [dispatch]);

  const handlePaymentWalletCreate = () => {
    dispatch(setPaymentWalletModal({ open: true, type: "new", id: null }));
  };

  const handleEdit = useCallback(
    (row) => {
      dispatch(
        setPaymentWalletModal({ open: true, type: "edit", id: row.original.id })
      );
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Acount No",
        accessor: "account_no",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Active",
        id: "active",
        className: "font-bold",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.active ? "Active" : "Inactive"}
          </div>
        ),
      },
      {
        Header: "Payment Method",
        id: "payment_method",
        className: "font-bold",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row?.original.payment_method?.name}
          </div>
        ),
      },
      {
        Header: "Edit",
        id: "edit",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton onClick={(ev) => handleEdit(row)}>
              <EditIcon sx={{ fontSize: 30, color: "black" }} />
            </IconButton>
          </div>
        ),
      },
    ],
    [handleEdit]
  );

  return (
    <div>
      <p className="font-bold text-2xl mb-8">Payment Wallet List</p>

      <button
        className="p-6 bg-yellow-600 mb-8"
        onClick={handlePaymentWalletCreate}
      >
        Create payment Wallet
      </button>

      <CommonTable
        columns={columns}
        data={paymentWalletList}
        handleClick={(event, row) => null}
      />

      <PaymentWalletModal />
    </div>
  );
};

export default OgrimPaymentWallet;
