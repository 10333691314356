import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CommonTable from "../../components/common-table";
import ITableTools from "../../components/page-components/invoice/ITableTools";
// import TableTools from "../../components/page-components/salary-request-list/TableTools";
import { invoiceGetAll } from "../../store/invoiceSlice";
import ApproveInvoice from "./ApproveInvoice";
import CancelInvoice from "./CancelInvoice";
import InvoiceDialog from "./InvoiceDialog";
import SettleInvoice from "./SettleInvoice";

const InvoiceList = () => {
  const { invoiceList } = useSelector((state) => state.invoiceSlice);
  let [searchParams] = useSearchParams();
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)


  const changePage = (of, li) => {
     if(limit !== li) {
        setOffset(0)
        setLimit(li)
     } else {
        setOffset(of)
     }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Total Requested Amount",
        accessor: "total_requested_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Invoice Status",
        accessor: "invoice_status",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Issue Date",
        accessor: "issue_date",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("DD/MM/YYYY hh:mm:ss a") : "-",
      },
      {
        Header: "Payroll Start Date",
        accessor: "payroll_start_date",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("DD/MM/YYYY") : "-",
      },
      {
        Header: "Payroll End Date",
        accessor: "payroll_end_date",
        className: "font-bold",
        sortable: true,
        Cell: (val) =>
          val.value ? moment(val.value).format("DD/MM/YYYY") : "-",
      },
      // {
      //   Header: "Creation Date",
      //   accessor: "created_at",
      //   className: "font-bold",
      //   sortable: true,
      //   Cell: (val) =>
      //     val.value ? moment(val.value).format("DD/MM/YYYY hh:mm:ss a") : "-",
      // },
      // {
      //   Header: "Due Date",
      //   accessor: "due_date",
      //   className: "font-bold",
      //   sortable: true,
      //   Cell: (val) =>
      //     val.value
      //       ? moment(val.value, "DD-MM-YYYY").format("DD/MM/YYYY hh:mm:ss a")
      //       : "-",
      // },
      {
        Header: "Request Lines",
        accessor: "request_line_no",
        className: "font-bold",
        sortable: true,
        // Cell: (val) => val.value,
      },
      {
        Header: "Actions",
        id: "print",
        sortable: false,
        Cell: ({ row }) => (
          <>
            <InvoiceDialog invoice_id={row.original.id} />
            {row.original.invoice_status !== "settled" &&
              row.original.invoice_status !== "cancelled" && (
                <SettleInvoice
                  status={row.original.invoice_status}
                  invoice_id={row.original.id}
                  partner_id={row.original.partner_id}
                />
              )}
            {row.original.invoice_status === "draft" && (
              <>
                <ApproveInvoice
                  invoice_id={row.original.id}
                  partner_id={row.original.partner_id}
                />
                <CancelInvoice
                  invoice_id={row.original.id}
                  partner_id={row.original.partner_id}
                />
              </>
            )}

            {/* <InvoiceDialog invoice_id={row.original.id} /> */}
          </>
        ),
      },
    ],
    []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      invoiceGetAll({
        partner_id: searchParams.get("partner_id"),
        offset: offset,
        limit: limit
      })
    );
  }, [dispatch, searchParams, offset, limit]);

  return (
    <div>
      <p className="font-bold text-2xl mb-8">Invoice List</p>
      <CommonTable
        request_type="advance"
        offset={offset}
        limit={limit}
        changePage={changePage}
        columns={columns}
        data={invoiceList}
        handleClick={() => null}
        TableToolsComponent={ITableTools}
      />
    </div>
  );
};

export default InvoiceList;
