import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import agrimSlice from "./agrimSlice";
import partnerSlice from "./partnerSlice";
import userSlice from "./userSlice";
import invoiceSlice from "./invoiceSlice";
import batchSlice from "./batchSlice";
// import snackbarSlice from "./snackbarSlice";

export const store = configureStore({
  reducer: {
    authSlice,
    agrimSlice,
    partnerSlice,
    userSlice,
    invoiceSlice,
    batchSlice
    // snackbarSlice,
  },
});
