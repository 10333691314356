import { useEffect, useRef, useState } from "react";
// import { useDispatch } from 'react-redux';
import * as echarts from "echarts";
// import InvoiceCard from './invoice-card';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { partnerGetAll } from "../../store/partnerSlice";
import { getDashboardReports } from "./queries";
import {
  useLoaderData,
  useNavigation,
  useSearchParams,
} from "react-router-dom";

export function loader({ request }) {
  const url = new URL(request.url);
  const searchTerm = url.searchParams.get("partnerId");
  return (
    getDashboardReports(searchTerm ? { partner_id: searchTerm } : {}) || null
  );
}

const Dashboard = () => {
  const navigation = useNavigation();
  console.log(navigation.state);
  const [selection, setSelection] = useState(0);
  // const navigate = useNavigate();
  let [, setSearchParams] = useSearchParams();
  const dashboardData = useLoaderData();
  console.log(dashboardData);

  const { partnerList } = useSelector((state) => state.partnerSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(partnerGetAll());
  }, [dispatch]);

  useEffect(() => {
    if (selection === 0) {
      setSearchParams({});
    } else {
      setSearchParams({ partnerId: selection });
    }
  }, [selection, setSearchParams]);

  //   alert(JSON.stringify(partnerList));
  return (
    <div className="dashboard-app">
      <Grid container direction="row" spacing={2}>
        {navigation.state === "loading" ? (
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={4}>
              <MetricsCard
                label="Total Amount of Disbursement"
                value={dashboardData?.aggrigate_report?.disbursed_amount}
                qty={dashboardData?.aggrigate_report?.disbursed_number}
                unit="TK"
              />
            </Grid>
            <Grid item xs={4}>
              <MetricsCard
                label="Total Requested amount"
                value={dashboardData?.aggrigate_report?.requested_amount}
                qty={dashboardData?.aggrigate_report?.requested_number}
                unit="TK"
              />
            </Grid>
            <Grid item xs={4}>
              <MetricsCard
                label="Total Settled Earned"
                value={dashboardData?.aggrigate_report?.settled_amount}
                qty={dashboardData?.aggrigate_report?.settled_number}
                unit="TK"
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: "100%" }}>
                <FormControl sx={{ width: "180px", ml: "auto" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select Company
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selection}
                    label="Select Company"
                    onChange={(e) => setSelection(e.target.value)}
                    disabled={partnerList.length === 0}
                  >
                    <MenuItem value={0} selected>
                      All
                    </MenuItem>
                    {partnerList.map((partner) => (
                      <MenuItem key={partner.id} value={partner.id}>
                        {partner.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {selection === 0 ? (
              <>
                <Grid item xs={6}>
                  <BarChart
                    data={dashboardData?.all_partner_data}
                    topic="Requests"
                  />
                </Grid>
                <Grid item xs={6}>
                  <BarChart
                    data={dashboardData?.all_partner_data}
                    topic="Disbursement"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <LineChart data={dashboardData?.six_month_data} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

const MetricsCard = ({ label = "", value = 0, unit = "", qty = 0 }) => (
  <Card sx={{ bgcolor: "grey.100" }}>
    <CardContent>
      <Typography variant="h5">{label}</Typography>
      <Typography variant="body2">
        {value} {unit} | QTY {qty}
      </Typography>
    </CardContent>
  </Card>
);

const LineChart = ({ data }) => {
  const lineChartRef = useRef(null);
  console.log(data);
  useEffect(() => {
    if (lineChartRef.current != null) {
      let dom = lineChartRef.current;

      let lineChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
        height: 500,
      });

      lineChart.setOption({
        title: {
          text: "Monthly Disbursement vs Requests",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["Disbursements", "Requests"],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: data?.month_str || [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Requests",
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: data?.requested_data || [],
          },
          {
            name: "Disbursements",
            type: "line",
            stack: "Total",
            label: {
              show: true,
              position: "top",
            },
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: data?.disbursed_data || [],
          },
        ],
      });
    }
  }, [
    data?.disbursed_data,
    data?.month_str,
    data?.requested_data,
    lineChartRef,
  ]);
  return (
    <div className="mt-10">
      <div ref={lineChartRef}></div>
    </div>
  );
};

const topicSelector = {
  Requests: "company_total_requested_list",
  Disbursement: "company_total_disbursed_list",
};

const BarChart = ({ topic, data }) => {
  const barChartRef = useRef(null);
  console.log(topicSelector[topic]);
  useEffect(() => {
    if (barChartRef.current != null) {
      let dom = barChartRef.current;

      let barChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
        height: 500,
      });

      barChart.setOption({
        title: {
          text: `Top 5 Organization by ${topic}`,
        },
        xAxis: {
          type: "category",
          data: data?.partner_name_list || [],
        },
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "item",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        series: [
          {
            data: data?.[topicSelector?.[topic]] || [],
            type: "bar",
          },
        ],
      });
    }
  }, [barChartRef, data, data?.partner_name_list, topic]);

  return (
    <div className="mt-10">
      <div ref={barChartRef}></div>
    </div>
  );
};

export default Dashboard;
