import { FilterAlt } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { removeEmptyValues } from "../../utils/helper";

const FilterModalGB = ({ groupBy, loading, setFilter }) => {
  const [filterValue, setFilterValue] = useState({});
  const { partnerList } = useSelector((state) => state.partnerSlice);
  const { paymentMethodList } = useSelector((state) => state.agrimSlice);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const clearFilter = () => {
    setFilter({});
    setFilterValue({});
  };
  const submitFilter = () => {
    let fv = removeEmptyValues({ ...filterValue });
    if (fv.from_year) {
      fv.from_year = moment(fv.from_year).format("YYYY");
    }
    if (fv.to_year) {
      fv.to_year = moment(fv.to_year).format("YYYY");
    }
    setFilter(fv);
  };
  return (
    <>
      <IconButton size="small" onClick={handleClickOpen} disabled={loading}>
        <FilterAlt />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth size="small">
                  <InputLabel id="from_month">From Month</InputLabel>
                  <Select
                    labelId="from_month"
                    id="from_month"
                    value={filterValue["from_month"]}
                    label="From Month"
                    onChange={(e) => {
                      setFilterValue((f) => ({
                        ...f,
                        from_month: parseInt(e.target.value),
                      }));
                    }}
                  >
                    <MenuItem value={1}>Jan</MenuItem>
                    <MenuItem value={2}>Feb</MenuItem>
                    <MenuItem value={3}>Mar</MenuItem>
                    <MenuItem value={4}>Apr</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>Jun</MenuItem>
                    <MenuItem value={7}>Jul</MenuItem>
                    <MenuItem value={8}>Aug</MenuItem>
                    <MenuItem value={9}>Sep</MenuItem>
                    <MenuItem value={10}>Oct</MenuItem>
                    <MenuItem value={11}>Nov</MenuItem>
                    <MenuItem value={12}>Dec</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id="to_month">To Month</InputLabel>
                  <Select
                    labelId="to_month"
                    id="to_month"
                    value={filterValue["to_month"]}
                    label="To Month"
                    onChange={(e) => {
                      setFilterValue((f) => ({
                        ...f,
                        to_month: parseInt(e.target.value),
                      }));
                    }}
                  >
                    <MenuItem value={1}>Jan</MenuItem>
                    <MenuItem value={2}>Feb</MenuItem>
                    <MenuItem value={3}>Mar</MenuItem>
                    <MenuItem value={4}>Apr</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>Jun</MenuItem>
                    <MenuItem value={7}>Jul</MenuItem>
                    <MenuItem value={8}>Aug</MenuItem>
                    <MenuItem value={9}>Sep</MenuItem>
                    <MenuItem value={10}>Oct</MenuItem>
                    <MenuItem value={11}>Nov</MenuItem>
                    <MenuItem value={12}>Dec</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <DatePicker
                  views={["year"]}
                  label="From Year"
                  value={filterValue["from_year"]}
                  onChange={(newValue) => {
                    setFilterValue((f) => ({
                      ...f,
                      from_year: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} size="small" />
                  )}
                />
                <DatePicker
                  size="small"
                  views={["year"]}
                  label="To Year"
                  value={filterValue["to_year"]}
                  onChange={(newValue) => {
                    setFilterValue((f) => ({
                      ...f,
                      to_year: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} size="small" />
                  )}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                {!groupBy.includes("partner_id") && (
                  <FormControl fullWidth size="small">
                    <InputLabel id="partner-select-label">Partner</InputLabel>
                    <Select
                      labelId="partner-select-label"
                      id="partner-select"
                      value={filterValue["partner_id"]}
                      label="Partner"
                      onChange={(e) => {
                        setFilterValue((f) => ({
                          ...f,
                          partner_id: e.target.value,
                        }));
                      }}
                    >
                      <MenuItem value="">All</MenuItem>
                      {partnerList.map((partner) => (
                        <MenuItem key={partner.id} value={partner.id}>
                          {partner.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!groupBy.includes("employer_pay_cycle") && (
                  <TextField
                    fullWidth
                    placeholder="e.g. 2023-03-20 to 2023-04-19"
                    size="small"
                    label="Employer Pay Cycle"
                    value={filterValue["employer_pay_cycle"]}
                    onChange={(e) => {
                      setFilterValue((f) => ({
                        ...f,
                        employer_pay_cycle: e.target.value,
                      }));
                    }}
                  />
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                {!groupBy.includes("id_number") && (
                  <>
                    <TextField
                      fullWidth
                      size="small"
                      label="Employee ID number"
                      value={filterValue["id_number"]}
                      onChange={(e) => {
                        setFilterValue((f) => ({
                          ...f,
                          id_number: e.target.value,
                        }));
                      }}
                    />
                  </>
                )}
                {!groupBy.includes("status") && (
                  <>
                    <FormControl fullWidth size="small">
                      <InputLabel id="status-select-label">Status</InputLabel>
                      <Select
                        labelId="status-select-label"
                        id="status-select"
                        value={filterValue["status"]}
                        label="Status"
                        onChange={(e) => {
                          setFilterValue((f) => ({
                            ...f,
                            status: e.target.value,
                          }));
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        {[
                          { label: "Valid", value: "valid" },
                          { label: "Invalid", value: "invalid" },
                          { label: "Batch Created", value: "batch_created" },
                          { label: "Batch Approved", value: "batch_approved" },
                          { label: "Disbursed", value: "disbursed" },
                          { label: "Invoiced", value: "invoiced" },
                          { label: "Done", value: "done" },
                          { label: "Cancelled", value: "cancelled" },
                        ].map((stat) => (
                          <MenuItem key={stat.value} value={stat.value}>
                            {stat.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                {!groupBy.includes("payment_code") && (
                  <>
                    <FormControl fullWidth size="small">
                      <InputLabel id="payment-select-label">Payment</InputLabel>
                      <Select
                        labelId="payment-select-label"
                        id="payment-select"
                        value={filterValue["payment_code"]}
                        label="Payment"
                        onChange={(e) => {
                          setFilterValue((f) => ({
                            ...f,
                            payment_code: e.target.value,
                          }));
                        }}
                      >
                        <MenuItem value="">All</MenuItem>
                        {paymentMethodList.map((pm) => (
                          <MenuItem key={pm.id} value={pm.code}>
                            {pm.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button onClick={clearFilter}>Clear</Button>
                <Button onClick={submitFilter}>Filter</Button>
              </Stack>
            </Stack>
          </LocalizationProvider>
          {/* {JSON.stringify(filterValue)} */}
          {/* {JSON.stringify([
            { label: "from_month" },
            { label: "to_month" },
            { label: "from_year" },
            { label: "to_year" },
            { label: "partner_id" },
            { label: "employer_pay_cycle" },
            { label: "id_number" },
            { label: "status" },
            { label: "payment_code" },
          ])} */}
          {/* {JSON.stringify(groupBy)} */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FilterModalGB;
