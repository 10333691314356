import React from "react";
import { Container, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <style>
        {`
                        [data-custom-class='body'], [data-custom-class='body'] * {
                            background: transparent !important;
                        }
                        [data-custom-class='title'], [data-custom-class='title'] * {
                            font-family: Arial !important;
                            font-size: 26px !important;
                            color: #000000 !important;
                        }
                        [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
                            font-family: Arial !important;
                            color: #595959 !important;
                            font-size: 14px !important;
                        }
                        [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
                            font-family: Arial !important;
                            font-size: 19px !important;
                            color: #000000 !important;
                        }
                        [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
                            font-family: Arial !important;
                            font-size: 17px !important;
                            color: #000000 !important;
                        }
                        [data-custom-class='body_text'], [data-custom-class='body_text'] * {
                            color: #595959 !important;
                            font-size: 14px !important;
                            font-family: Arial !important;
                        }
                        [data-custom-class='link'], [data-custom-class='link'] * {
                            color: #3030F1 !important;
                            font-size: 14px !important;
                            font-family: Arial !important;
                            word-break: break-word !important;
                        }

                        ul {
                            list-style-type: square;
                            }
                            ul > li > ul {
                            list-style-type: circle;
                            }
                            ul > li > ul > li > ul {
                            list-style-type: square;
                            }
                            ol li {
                            font-family: Arial ;
                         }
                    `}
      </style>

      <Container>
        <Box data-custom-class="body">
          <div>
            <div>
              <strong>
                <span style={{ fontSize: "26px" }}>
                  <span data-custom-class="title">
                    <span className="block-component"></span>
                    <span className="question">PRIVACY POLICY</span>
                    <span className="statement-end-if-in-editor"></span>
                  </span>
                </span>
              </strong>
            </div>
            <div>
              <br />
            </div>
            <div>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="subtitle">
                      Last updated
                      <span className="question">August 22, 2024</span>
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    This privacy notice for
                    {/* Assuming `bdt` is a custom component or placeholder */}
                    <span className="question">
                      Ogreem
                      <span className="block-component"></span>
                    </span>
                    (<span className="block-component"></span>"
                    <strong>we</strong>," "<strong>us</strong>," or "
                    <strong>our</strong>"
                    <span className="statement-end-if-in-editor"></span>
                  </span>
                  <span data-custom-class="body_text">
                    ), describes how and why we might collect, store, use,
                    and/or share (<span className="block-component"></span>"
                    <strong>process</strong>"
                    <span className="statement-end-if-in-editor"></span>) your
                    information when you use our services (
                    <span className="block-component"></span>"
                    <strong>Services</strong>"
                    <span className="statement-end-if-in-editor"></span>
                    ), such as when you:
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span className="block-component"></span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>

              <div>
                <span className="block-component">
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span className="block-component"></span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>

            <ul>
              <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      Download and use
                      {/* Assuming `bdt` is a custom component or placeholder */}
                      <span className="block-component"></span>
                      our mobile application
                      <span className="block-component"></span>(
                      <span className="question">Ogreem</span>)
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  {/* Assuming `bdt` is a custom component or placeholder */}
                                  <span className="statement-end-if-in-editor">
                                    ,
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              {/* Assuming `bdt` is a custom component or placeholder */}
                              <span className="statement-end-if-in-editor">
                                <span className="block-component">
                                  {" "}
                                  or any other application of ours that links to
                                  this privacy notice
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        {/* Assuming `bdt` is a custom component or placeholder */}
                        <span className="block-component"></span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>

            <ul>
              <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      Engage with us in other related ways, including any sales,
                      marketing, or events
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              {/* Assuming `bdt` is a custom component or placeholder */}
                              <span className="statement-end-if-in-editor"></span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <strong>Questions or concerns? </strong>
                    Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our
                    policies and practices, please do not use our Services.
                    <span className="block-component"></span>
                    If you still have any questions or concerns, please contact
                    us at
                    <span className="question">info@ogreem.com</span>.
                  </span>
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <strong>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="heading_1">
                    SUMMARY OF KEY POINTS
                  </span>
                </span>
              </strong>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>
                      This summary provides key points from our privacy notice,
                      but you can find out more details about any of these
                      topics by clicking the link following each key point or by
                      using our{" "}
                    </em>
                  </strong>
                </span>
              </span>
              <a data-custom-class="link" href="#toc">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>table of contents</em>
                    </strong>
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em> below to find the section you are looking for.</em>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>What personal information do we process?</strong> When
                  you visit, use, or navigate our Services, we may process
                  personal information depending on how you interact with us and
                  the Services, the choices you make, and the products and
                  features you use. Learn more about{" "}
                </span>
              </span>
              <a data-custom-class="link" href="#personalinfo">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    personal information you disclose to us
                  </span>
                </span>
              </a>
              <span data-custom-class="body_text">.</span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    Do we process any sensitive personal information?
                  </strong>
                  <bdt class="block-component"></bdt>
                  We do not process sensitive personal information.
                  <bdt class="else-block"></bdt>
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    Do we collect any information from third parties?
                  </strong>
                  <bdt class="block-component"></bdt>
                  We do not collect any information from third parties.
                  <bdt class="else-block"></bdt>
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>How do we process your information?</strong> We
                  process your information to provide, improve, and administer
                  our Services, communicate with you, for security and fraud
                  prevention, and to comply with law. We may also process your
                  information for other purposes with your consent. We process
                  your information only when we have a valid legal reason to do
                  so. Learn more about{" "}
                </span>
              </span>
              <a data-custom-class="link" href="#infouse">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    how we process your information
                  </span>
                </span>
              </a>
              <span data-custom-class="body_text">.</span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    In what situations and with which
                    <bdt class="block-component"></bdt>
                    parties do we share personal information?
                  </strong>
                  We may share information in specific situations and with
                  specific
                  <bdt class="block-component"></bdt>
                  third parties. Learn more about
                </span>
              </span>
              <a data-custom-class="link" href="#whoshare">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    when and with whom we share your personal information
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  .<bdt class="block-component"></bdt>
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>How do we keep your information safe?</strong> We have
                  <bdt class="block-component"></bdt>
                  organizational
                  <bdt class="statement-end-if-in-editor"></bdt>
                  and technical processes and procedures in place to protect
                  your personal information. However, no electronic transmission
                  over the internet or information storage technology can be
                  guaranteed to be 100% secure, so we cannot promise or
                  guarantee that hackers, cybercriminals, or other
                  <bdt class="block-component"></bdt>
                  unauthorized
                  <bdt class="statement-end-if-in-editor"></bdt>
                  third parties will not be able to defeat our security and
                  improperly collect, access, steal, or modify your information.
                  Learn more about
                </span>
              </span>
              <a data-custom-class="link" href="#infosafe">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    how we keep your information safe
                  </span>
                </span>
              </a>
              <span data-custom-class="body_text">.</span>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>What are your rights?</strong> Depending on where you
                  are located geographically, the applicable privacy law may
                  mean you have certain rights regarding your personal
                  information. Learn more about{" "}
                </span>
              </span>
              <a data-custom-class="link" href="#privacyrights">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">your privacy rights</span>
                </span>
              </a>
              <span data-custom-class="body_text">.</span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>How do you exercise your rights?</strong> The easiest
                  way to exercise your rights is by
                  <bdt class="block-component">submitting a </bdt>
                </span>
              </span>
              <a
                data-custom-class="link"
                href="https://app.termly.io/notify/49459c6d-915a-41fd-baca-c991921a6331"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    data subject access request
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>, or by contacting us. We
                  will consider and act upon any request in accordance with
                  applicable data protection laws.
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  Want to learn more about what we do with any information we
                  collect?{" "}
                </span>
              </span>
              <a data-custom-class="link" href="#toc">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Review the privacy notice in full
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>

            <div id="toc" style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        TABLE OF CONTENTS
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infocollect">
                  <span style={{ color: "rgb(0, 58, 250)" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infouse">
                  <span style={{ color: "rgb(0, 58, 250)" }}>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                    {/* <bdt class="block-component"></bdt> */}
                  </span>
                </a>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    4. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    5.HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    6. DO WE COLLECT INFORMATION FROM MINORS?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    7. WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    8. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    9. DO WE MAKE UPDATES TO THIS NOTICE?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>

            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  {/* <bdt class="block-component"></bdt> */}
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {/* <bdt class="block-component"></bdt> */}
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* <bdt class="block-component"></bdt> */}
                </span>
              </span>
            </div>

            <div
              id="infocollect"
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              <span
                id="control"
                data-custom-class="heading_1"
                style={{
                  background: "transparent",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Arial",
                  fontSize: "19px",
                  fontWeight: "bold",
                }}
              >
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              &nbsp;
            </div>
            <div
              id="personalinfo"
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              <span
                data-custom-class="heading_2"
                style={{
                  background: "transparent",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Arial",
                  fontSize: "15px",
                }}
              >
                <strong
                  style={{
                    background: "transparent",
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Arial",
                    fontSize: "17px",
                  }}
                >
                  Personal information you disclose to us
                </strong>
              </span>
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              <div style={{ background: "transparent" }}>&nbsp;</div>
              <div style={{ background: "transparent", lineHeight: "1.5" }}>
                <em
                  style={{
                    background: "transparent",
                    color: "rgb(89, 89, 89)",
                    fontFamily: "Arial",
                    fontSize: "14px",
                  }}
                >
                  <span
                    data-custom-class="body_text"
                    style={{
                      background: "transparent",
                      color: "rgb(89, 89, 89)",
                      fontFamily: "Arial",
                      fontSize: "14px",
                    }}
                  >
                    <strong
                      style={{
                        background: "transparent",
                        color: "rgb(89, 89, 89)",
                        fontFamily: "Arial",
                        fontSize: "14px",
                      }}
                    >
                      In Short:&nbsp;
                    </strong>
                    We collect personal information that you provide to us.
                  </span>
                </em>
              </div>
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              <span
                data-custom-class="body_text"
                style={{
                  background: "transparent",
                  color: "rgb(89, 89, 89)",
                  fontFamily: "Arial",
                  fontSize: "14px",
                }}
              >
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </span>
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              <span
                data-custom-class="body_text"
                style={{
                  background: "transparent",
                  color: "rgb(89, 89, 89)",
                  fontFamily: "Arial",
                  fontSize: "14px",
                }}
              >
                <strong
                  style={{
                    background: "transparent",
                    color: "rgb(89, 89, 89)",
                    fontFamily: "Arial",
                    fontSize: "14px",
                  }}
                >
                  Personal Information Provided by You.
                </strong>{" "}
                The personal information that we collect depends on the context
                of your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
              </span>
            </div>
            <ul
              style={{
                listStyleType: "square",
                background: "transparent",
                color: "rgb(89, 89, 89)",
                fontFamily: "Arial",
                fontSize: "14px",
                lineHeight: "1.5",
              }}
            >
              <li data-custom-class="body_text">names</li>
            </ul>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              &nbsp;
            </div>
            <ul
              style={{
                listStyleType: "square",
                background: "transparent",
                color: "rgb(89, 89, 89)",
                fontFamily: "Arial",
                fontSize: "14px",
                lineHeight: "1.5",
              }}
            >
              <li data-custom-class="body_text">phone numbers</li>
            </ul>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              &nbsp;
            </div>
            <ul
              style={{
                listStyleType: "square",
                background: "transparent",
                color: "rgb(89, 89, 89)",
                fontFamily: "Arial",
                fontSize: "14px",
                lineHeight: "1.5",
              }}
            >
              <li data-custom-class="body_text">passwords</li>
            </ul>
            <div
              id="sensitiveinfo"
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              <span
                data-custom-class="body_text"
                style={{
                  background: "transparent",
                  color: "rgb(89, 89, 89)",
                  fontFamily: "Arial",
                  fontSize: "14px",
                }}
              >
                <strong
                  style={{
                    background: "transparent",
                    color: "rgb(89, 89, 89)",
                    fontFamily: "Arial",
                    fontSize: "14px",
                  }}
                >
                  Sensitive Information.
                </strong>{" "}
                We do not process sensitive information.
              </span>
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                lineHeight: "1.5",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
              }}
            >
              <p>
                <span
                  data-custom-class="body_text"
                  style={{
                    background: "transparent",
                    color: "rgb(89, 89, 89)",
                    fontFamily: "Arial",
                    fontSize: "15px",
                  }}
                >
                  <strong
                    style={{
                      background: "transparent",
                      color: "rgb(89, 89, 89)",
                      fontFamily: "Arial",
                      fontSize: "14px",
                    }}
                  >
                    Application Data.
                  </strong>{" "}
                  If you use our application(s), we also may collect the
                  following information if you choose to provide us with access
                  or permission:
                </span>
              </p>
              <div style={{ lineHeight: "1.5", background: "transparent" }}>
                <div style={{ lineHeight: "1.5", background: "transparent" }}>
                  <div style={{ lineHeight: "1.5", background: "transparent" }}>
                    &nbsp;
                  </div>
                  <ul
                    style={{
                      listStyleType: "square",
                      background: "transparent",
                    }}
                  >
                    <li
                      data-custom-class="body_text"
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontFamily: "Arial",
                        fontSize: "14px",
                        lineHeight: "1.5",
                      }}
                    >
                      <em
                        style={{
                          background: "transparent",
                          color: "rgb(89, 89, 89)",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        Mobile Device Access.
                      </em>{" "}
                      We may request access or permission to certain features
                      from your mobile device, including your mobile device's
                      Bluetooth, calendar, camera, contacts, microphone,
                      reminders, sensors, SMS messages, social media accounts,
                      storage, and other features. If you wish to change our
                      access or permissions, you may do so in your device's
                      settings.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              id="infouse"
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                id="control"
                data-custom-class="heading_1"
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
              >
                <strong style={{ background: "transparent !important" }}>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </strong>
              </span>
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <div
                style={{
                  background: "transparent !important",
                  lineHeight: 1.5,
                }}
              >
                &nbsp;
              </div>
              <div
                style={{
                  background: "transparent !important",
                  lineHeight: 1.5,
                }}
              >
                <em
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                >
                  <span
                    data-custom-class="body_text"
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    <strong
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89) !important",
                        fontFamily: "Arial !important",
                        fontSize: "14px !important",
                      }}
                    >
                      In Short:&nbsp;
                    </strong>
                    We process your information to provide, improve, and
                    administer our Services, communicate with you, for security
                    and fraud prevention, and to comply with law. We may also
                    process your information for other purposes with your
                    consent.
                  </span>
                </em>
              </div>
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                data-custom-class="body_text"
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
              >
                <strong
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                >
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
              </span>
            </div>

            <ul
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                listStyleType: "square",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <li
                data-custom-class="body_text"
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                  lineHeight: 1.5,
                }}
              >
                <span
                  data-custom-class="body_text"
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                >
                  <strong
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.&nbsp;
                  </strong>
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </span>
              </li>
            </ul>

            <div
              id="whoshare"
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                id="control"
                data-custom-class="heading_1"
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
              >
                <strong style={{ background: "transparent !important" }}>
                  3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </strong>
              </span>
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <em
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
              >
                <span
                  data-custom-class="body_text"
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                >
                  <strong
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    In Short:
                  </strong>
                  &nbsp;We may share information in specific situations
                  described in this section and/or with the following third
                  parties.
                </span>
              </em>
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                data-custom-class="body_text"
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
              >
                We may need to share your personal information in the following
                situations:
              </span>
            </div>

            <ul
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                listStyleType: "square",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <li
                data-custom-class="body_text"
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                  lineHeight: 1.5,
                }}
              >
                <span
                  data-custom-class="body_text"
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                >
                  <strong
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    Business Transfers.
                  </strong>{" "}
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </span>
              </li>
            </ul>

            <div
              style={{
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                wordSpacing: 0,
              }}
              id="inforetain"
            >
              <br />
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
                id="control"
                data-custom-class="heading_1"
              >
                <strong style={{ background: "transparent !important" }}>
                  4. HOW LONG DO WE KEEP YOUR INFORMATION?
                </strong>
              </span>
            </div>
            <div
              style={{
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                wordSpacing: 0,
              }}
            />
            <div
              style={{
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                wordSpacing: 0,
              }}
            >
              <em
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                  data-custom-class="body_text"
                >
                  <strong
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    In Short:&nbsp;
                  </strong>
                  We keep your information for as long as necessary to fulfill
                  the purposes outlined in this privacy notice unless otherwise
                  required by law.
                </span>
              </em>
            </div>
            <div
              style={{
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                wordSpacing: 0,
              }}
            />
            <div
              style={{
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                wordSpacing: 0,
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us.
              </span>
            </div>
            <div
              style={{
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                wordSpacing: 0,
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </span>
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: 0,
              }}
              id="infosafe"
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
                id="control"
                data-custom-class="heading_1"
              >
                <strong style={{ background: "transparent !important" }}>
                  5. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </strong>
              </span>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: 0,
              }}
            />
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: 0,
              }}
            >
              <em
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                  data-custom-class="body_text"
                >
                  <strong
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    In Short:&nbsp;
                  </strong>
                  We aim to protect your personal information through a system
                  of organizational and technical security measures.
                </span>
              </em>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: 0,
              }}
            />
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "Times New Roman",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: 1.5,
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: 0,
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: 0,
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                We have implemented appropriate and reasonable technical and
                organizational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
              </span>
            </div>

           
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
              >
                <strong style={{ background: "transparent !important" }}>
                  6. DO WE COLLECT INFORMATION FROM MINORS?
                </strong>
              </span>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age.
              <br />
              <br />
              We do not knowingly collect, solicit data from, or market to
              children under 18 years of age, nor do we knowingly sell such
              personal information. By using the Services, you represent that
              you are at least 18 or that you are the parent or guardian of such
              a minor and consent to such minor dependent’s use of the Services.
              If we learn that personal information from users less than 18
              years of age has been collected, we will deactivate the account
              and take reasonable measures to promptly delete such data from our
              records. If you become aware of any data we may have collected
              from children under age 18, please contact us at
              <strong>__________</strong>.
            </div>

            {/* ff */}

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
              id="privacyrights"
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
                id="control"
                data-custom-class="heading_1"
              >
                <strong style={{ background: "transparent !important" }}>
                  7. WHAT ARE YOUR PRIVACY RIGHTS?
                </strong>
              </span>
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              <em
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                  data-custom-class="body_text"
                >
                  <strong
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    In Short:
                  </strong>
                  &nbsp;
                </span>
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                  data-custom-class="body_text"
                >
                  You may review, change, or terminate your account at any time,
                  depending on your country, province, or state of residence.
                </span>
              </em>
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
              id="withdrawconsent"
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                <strong
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                >
                  <u
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    Withdrawing your consent:
                  </u>
                </strong>{" "}
                If we are relying on your consent to process your personal
                information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw
                your consent at any time. You can withdraw your consent at any
                time by contacting us by using the contact details provided in
                the section
                <a
                  style={{
                    background: "transparent !important",
                    color: "rgb(48, 48, 241) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                    wordBreak: "break-word !important",
                  }}
                  href="#contact"
                  data-custom-class="link"
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(48, 48, 241) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                      wordBreak: "break-word !important",
                    }}
                    data-custom-class="body_text"
                  >
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </span>
                </a>
                " below.
              </span>
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
              </span>
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              <br />
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "17px !important",
                }}
                data-custom-class="heading_2"
              >
                <strong
                  style={{
                    background: "transparent !important",
                    color: "rgb(0, 0, 0) !important",
                    fontFamily: "Arial !important",
                    fontSize: "17px !important",
                  }}
                >
                  Account Information
                </strong>
              </span>
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "15px",
                }}
                data-custom-class="body_text"
              >
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </span>
            </div>

            <ul
              style={{
                background: "transparent !important",
                listStyleType: "square",
              }}
            >
              <li
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                  lineHeight: 1.5,
                }}
                data-custom-class="body_text"
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "15px",
                  }}
                  data-custom-class="body_text"
                >
                  Log in to your account settings and update your user account.
                </span>
              </li>
            </ul>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </span>
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "15px",
                }}
                data-custom-class="body_text"
              >
                If you have questions or comments about your privacy rights, you
                may email us at{" "}
                <a
                  href="mailto:info@ogreem.com"
                  style={{
                    color: "rgb(48, 48, 241) !important",
                    fontFamily: "Arial !important",
                    fontSize: "15px !important",
                    textDecoration: "none",
                  }}
                >
                  info@ogreem.com
                </a>
                .
              </span>
            </div>

            <div
              style={{
                background: "transparent !important",
                lineHeight: 1.5,
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
              id="DNT"
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
                id="control"
                data-custom-class="heading_1"
              >
                <strong style={{ background: "transparent !important" }}>
                  8. CONTROLS FOR DO-NOT-TRACK FEATURES
                </strong>
              </span>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage, no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </span>
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
              id="policyupdates"
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
                id="control"
                data-custom-class="heading_1"
              >
                <strong style={{ background: "transparent !important" }}>
                  9. DO WE MAKE UPDATES TO THIS NOTICE?
                </strong>
              </span>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <em
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89) !important",
                    fontFamily: "Arial !important",
                    fontSize: "14px !important",
                  }}
                  data-custom-class="body_text"
                >
                  <strong
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89) !important",
                      fontFamily: "Arial !important",
                      fontSize: "14px !important",
                    }}
                  >
                    In Short:&nbsp;
                  </strong>
                  Yes, we will update this notice as necessary to stay compliant
                  with relevant laws.
                </span>
              </em>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(89, 89, 89) !important",
                  fontFamily: "Arial !important",
                  fontSize: "14px !important",
                }}
                data-custom-class="body_text"
              >
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date at the
                top of this privacy notice. If we make material changes to this
                privacy notice, we may notify you either by prominently posting
                a notice of such changes or by directly sending you a
                notification. We encourage you to review this privacy notice
                frequently to be informed of how we are protecting your
                information.
              </span>
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
              >
                <strong style={{ background: "transparent !important" }}>
                  10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </strong>
              </span>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              If you have questions or comments about this notice, you may
              contact us by post at:
              <br />
              <br />
              <strong>Ogreem</strong>
              <br />
              Mir Info Systems, H#B-147, Road-22
              <br />
              Dhaka, Dhaka 1206
              <br />
              Bangladesh
            </div>

            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              <span
                style={{
                  background: "transparent !important",
                  color: "rgb(0, 0, 0) !important",
                  fontFamily: "Arial !important",
                  fontSize: "19px !important",
                }}
              >
                <strong style={{ background: "transparent !important" }}>
                  11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </strong>
              </span>
            </div>
            <div
              style={{
                WebkitTextStrokeWidth: "0px",
                background: "transparent !important",
                color: "rgb(0, 0, 0)",
                fontFamily: "'Times New Roman'",
                fontSize: "medium",
                fontStyle: "normal",
                fontVariantCaps: "normal",
                fontVariantLigatures: "normal",
                fontWeight: 400,
                letterSpacing: "normal",
                lineHeight: "1.5",
                orphans: 2,
                textAlign: "start",
                textDecorationColor: "initial",
                textDecorationStyle: "initial",
                textDecorationThickness: "initial",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: 2,
                wordSpacing: "0px",
              }}
            >
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, details about how we have processed it, correct
              inaccuracies, or delete your personal information. You may also
              have the right to withdraw your consent to our processing of your
              personal information. These rights may be limited in some
              circumstances by applicable law. To request to review, update, or
              delete your personal information, please fill out and submit a
              data subject access request.
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
