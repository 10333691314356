import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { APIDataServices } from "../service-pattern";
import { resetAuth } from "./authSlice";

export const getUsersList = createAsyncThunk(
  "partnerSlice/getOgreemFeeList",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .GetUsersList(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          dispatch(setUsers(result.data));
        } else {
          dispatch(setUsers([]));
        }
      });

    return data;
  }
);

const initialState = {
  users: [],
  loading: true,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    clearAll: (state) => {
      state = initialState;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setUsers, clearAll, setLoading } = userSlice.actions;
export default userSlice.reducer;
