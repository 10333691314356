import React from "react";
import IconButton from "@mui/material/IconButton";
import Replay from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";

const ReloadButton = ({ reloadFunc }) => {
  const dispatch = useDispatch();
  return (
    <IconButton onClick={() => dispatch(reloadFunc())}>
      <Replay />
    </IconButton>
  );
};

export default ReloadButton;
