import { Sync } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { APIDataServices } from "../../service-pattern";
import { toastNotification } from "../../utils/toast";

const SyncUser = ({ row }) => {
  const [loading, setLoading] = useState(false);
  const generateInvoice = async () => {
    setLoading(true);
    try {
      const { data } = await new APIDataServices().UserWallerSync({
        employee_id: row.original.employee_id,
      });
      if (data) {
        toastNotification("success", "Synced successfully");

        // const doc = invoiceRef.current.contentDocument;
        // invoiceRef.current.width = "fit-content";
        // doc.open();
        // doc.write(data);
        // doc.close();
      }
      console.log(data);
    } catch (error) {
      toastNotification("error", error.message);
    }
    setLoading(false);
  };
  return (
    <>
      {row.original.employee_id && (
        <>
          {row.original.is_synce_needed && (
            <IconButton
              color="secondary"
              disabled={loading}
              onClick={generateInvoice}
            >
              <Sync />
            </IconButton>
          )}
        </>
      )}
    </>
  );
};

export default SyncUser;
