import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/common-table";
import {
  getPaymentMethodList,
  paymentMethodSyncToAllPartner,
  setPaymentMethodListModal,
} from "../../store/agrimSlice";
import _ from "lodash";
import PaymentMethodListModal from "./payment-method-list-modal";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SyncResultModal from "./sync-result-modal";

const OgrimPaymentMethod = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethodList());
  }, [dispatch]);

  const handleEdit = useCallback(
    (row) => {
      dispatch(
        setPaymentMethodListModal({
          open: true,
          type: "edit",
          id: row.original.id,
        })
      );
    },
    [dispatch]
  );
  const { paymentMethodList } = useSelector((state) => state.agrimSlice);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Code",
        accessor: "code",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Type",
        // accessor: 'type',
        className: "font-bold",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.type === "mfs"
              ? row.original.type.toUpperCase()
              : _.capitalize(row.original.type)}
          </div>
        ),
      },
      {
        Header: "Account Length",
        accessor: "account_length",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Active",
        id: "active",
        className: "font-bold",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.active ? "Active" : "Inactive"}
          </div>
        ),
      },
      {
        Header: "Edit",
        id: "edit",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton onClick={(ev) => handleEdit(row)}>
              <EditIcon sx={{ fontSize: 30, color: "black" }} />
            </IconButton>
          </div>
        ),
      },
    ],
    [handleEdit]
  );

  const handlePaymentMethodCreate = () => {
    dispatch(setPaymentMethodListModal({ open: true, type: "new", id: null }));
  };

  const handlePaymentMethodSyncToAllPartner = () => {
    dispatch(paymentMethodSyncToAllPartner());
  };

  return (
    <div>
      <p className="font-bold text-2xl mb-8">Payment Method List</p>

      <button
        className="p-6 bg-yellow-600 mb-8"
        onClick={handlePaymentMethodCreate}
      >
        Create payment Method
      </button>

      <button
        className="p-6 bg-blue-400 mb-8 ml-8"
        onClick={handlePaymentMethodSyncToAllPartner}
      >
        Payment Method Sync to all Partner
      </button>

      <CommonTable
        columns={columns}
        data={paymentMethodList}
        handleClick={(event, row) => null}
      />

      <PaymentMethodListModal />
      <SyncResultModal />
    </div>
  );
};

export default OgrimPaymentMethod;
