import React, { useState } from 'react';
import IconButton from "@mui/material/IconButton";
import Replay from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { partnerEmployeeSalaryRequestData } from "../../store/partnerSlice";
import { useEffect } from 'react';

const ReloadAdvancedSalaryRequests = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const [status, setStatus] = useState("")

    useEffect(() => {
        setStatus(location.pathname.split("/")[2].split("-")[3])
    }, [location])

    const handleReload = () => {
        if(status === "all"){
            dispatch(partnerEmployeeSalaryRequestData())
        } else {
            dispatch(partnerEmployeeSalaryRequestData({ status }))
        }
    }

    return (
        <IconButton onClick={handleReload}>
            <Replay />
        </IconButton>
    )
}

export default ReloadAdvancedSalaryRequests
