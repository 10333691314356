import axios from "axios";

export class APIRequestServices {
  constructor() {
    this.baseURL =
      process.env.REACT_APP_API_URL || "http://118.179.84.157:8009";
  }

  async Get(config) {
    return await axios
      .get(`${this.baseURL}/${config.URL}`, {
        params: config.data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((result) => {
        return Promise.resolve(
          config.getRawResponse ? result : result.data.data
        );
      })
      .catch((errors) => {
        return Promise.resolve(errors.response);
      });
  }

  async Post(config) {
    return await axios
      .post(`${this.baseURL}/${config.URL}`, config.data, {
        params: config.params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((errors) => {
        return Promise.resolve(errors.response);
      });
  }

  async Patch(config) {
    return await axios
      .patch(`${this.baseURL}/${config.URL}`, config.data, {
        params: config.params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((errors) => {
        return Promise.resolve(errors.response);
      });
  }

  async Put(config) {
    return await axios
      .put(`${this.baseURL}/${config.URL}`, config.data, {
        // params: config.data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((errors) => {
        return Promise.resolve(errors.response);
      });
  }

  async Delete(config) {
    return await axios
      .delete(`${this.baseURL}/${config.URL}`, {
        params: config.params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((errors) => {
        return Promise.resolve(errors.response);
      });
  }
}
