import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { APIDataServices } from "../../service-pattern";
import { resetAuth } from "../../store/authSlice";
import { toastNotification } from "../../utils/toast";

const EditPartnerProfile = ({ partner, getPartnerInfo }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [partnerData, setPartnerData] = useState({ ...partner });
  console.log(partnerData, "partner");
  const formRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.reset();
    setOpen(false);
  };

  const handleValueChange = (name, value) => {
    setPartnerData((p) => ({
      ...p,
      [name]: value,
    }));
  };
  //   const handleStatus = () => {
  //     if (partnerData?.status === null) {
  //       return null;
  //     }
  //     if (partnerData?.status === false) {
  //       return { label: "Inactive", value: partnerData?.status };
  //     }

  //     if (partnerData?.status === true) {
  //       return { label: "Active", value: partnerData?.status };
  //     }
  //   };
  useEffect(() => {
    setPartnerData(partner);
    return () => {
      setPartnerData(partner);
    };
  }, [partner]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await new APIDataServices().PartnerUpdate({
        partner_id: partner.id,
        partner: partnerData,
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      }
      if (status === 201) {
        getPartnerInfo();
        toastNotification("success", "Successfully updated partner profile");
        handleClose();
      }
    } catch (error) {
      toastNotification(
        "error",
        "Error updating partner profile. Please Try Again"
      );
    }
  };

  return (
    <CardHeader
      action={
        <>
          <Tooltip title="Edit company profile">
            <IconButton onClick={handleClickOpen}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Edit Profile</DialogTitle>
            <Box component="form" ref={formRef} onSubmit={handleSubmit}>
              <DialogContent>
                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    <div className="flex justify-between items-center mb-4">
                      <p className="w-[25%]">Name:</p>
                      <textarea
                        className="border-2 border-black w-[75%] p-2"
                        value={partnerData?.name}
                        name="name"
                        onChange={(event) =>
                          handleValueChange(
                            event.target.name,
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <p className="w-[25%]">Address:</p>
                      <textarea
                        className="border-2 border-black w-[75%] p-2"
                        value={partnerData?.address}
                        rows={3}
                        name="address"
                        onChange={(event) =>
                          handleValueChange(
                            event.target.name,
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <p className="w-[25%]">Email:</p>
                      <input
                        type="email"
                        className="border-2 border-black w-[75%] p-2"
                        value={partnerData?.email}
                        name="email"
                        onChange={(event) =>
                          handleValueChange(
                            event.target.name,
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex justify-between items-center mb-6">
                      <p className="w-[25%]">Status</p>
                      <div className="w-[75%]">
                        <select
                          className="w-[100%] p-2 border-black border-2"
                          name="status"
                          id="status"
                          value={partnerData?.status ? "active" : "inactive"}
                          onChange={(event) => {
                            handleValueChange(
                              "status",
                              event.target.value === "active"
                            );
                          }}
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <label htmlFor="has_slider" className="w-[25%]">
                        Has Slider?
                      </label>
                      <input
                        defaultChecked={partner?.has_slider}
                        id="has_slider"
                        type="checkbox"
                        value={partnerData?.has_slider}
                        name="has_slider"
                        className="border-2 border-black mr-auto p-2"
                        onChange={(event) => {
                          console.log(event.target.checked);
                          setPartnerData((pd) => ({
                            ...pd,
                            has_slider: event.target.checked,
                          }));
                        }}
                      />
                    </div>
                    {partnerData?.has_slider ? (
                      <>
                        <div className="flex justify-between items-center mb-4">
                          <p className="w-[25%]">Min Withdraw:</p>
                          <input
                            type="number"
                            className="border-2 border-black w-[75%] p-2"
                            value={partnerData?.min_withdraw}
                            name="min_withdraw"
                            onChange={(event) =>
                              handleValueChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          />
                        </div>
                        <div className="flex justify-between items-center mb-4">
                          <p className="w-[25%]">Withdraw Interval:</p>
                          <input
                            type="number"
                            className="border-2 border-black w-[75%] p-2"
                            value={partnerData?.withdraw_interval}
                            name="withdraw_interval"
                            onChange={(event) =>
                              handleValueChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  type="button"
                  color="error"
                  onClick={() => {
                    formRef.current.reset();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit" color="secondary">
                  Update
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </>
      }
    />
  );
};

export default EditPartnerProfile;
