import React from 'react'

const TermsAndConditionBN = () => {
    return (
        <div className='w-full'>
            <div className="w-1/2 mx-auto">
                <p className='text-xl font-bold text-center my-8'>শর্তাবলী</p>
                <p>
                    শিরোনাম: শর্তাবলী
                    1. সমস্ত ব্যবহারকারী অবশ্যই চুক্তিবদ্ধ কোম্পানির একজন কর্মচারী হতে হবে
                    2. প্রত্যেক ব্যবহারকারীর বেতন এবং বেতন অ্যাকাউন্টের কিছু তথ্য সংগ্রহ করা হবে
                </p>

                <p>
                    This version of the Agreement will be applicable from October 01, 2018.



                    This Privacy Policy and Terms and Conditions (hereinafter collectively referred to as “Agreement”) explains how we, Nagad Ltd. (hereinafter referred to as “Nagad”/“we”/“us”/“our”), collect, process and use information of our Users (hereinafter addressed as “Users”/“you”/“your”/“yourself”). Your consent to this Agreement is exerted when you sign up for, access, or use the “Services” provided by us. You agree to allow us to use and disclose your personal information entered into our system according to the description of this Agreement.



                    Overview:

                    This Agreement applies to any kind of use of our website, mobile application, electronic communications and/or any other services (the “Platform) for any purpose. The Platform may request you to provide your personal information in details, including your National ID/ Passport Number/Driving License, Tax Identification Number (TIN), and/or bank account details, as required for operating the Platform and also for reducing the risk of fraudulent activities, money laundering and terrorist financing. We process your information with utmost importance, care and use this information conforming to the terms of this Agreement. The term “information” in this Agreement refers to any confidential and/or individually identifiable information or other information related to any User of the Platform.



                    We shall not rent or sell your information to third parties for their marketing purposes without your explicit consent. In case we need to convey your information to any third party for completing your requested service, we shall share the required information under strict restriction and adhering to this Agreement. If you use any third party website via the Platform or use third party website to access the Platform, you shall be responsible for reviewing the privacy policy statements of those websites. Nagad shall not be responsible for the privacy practice of any third party.







                    1. Changes to Terms and Conditions:



                    Nagad shall routinely update the terms and conditions of this Agreement to improve our practices and to reflect new or different privacy practices. Such update includes, among other things, adding new Services, functionality or features to the Platform, adding certain fees or charges etc. Updated Agreement shall be effective upon our posting of such updated Agreement at our Platform. You shall be solely responsible for regularly reviewing the terms and conditions of the Platform. All future changes to terms and conditions shall be done as per the existing laws of Bangladesh and rules, regulations and guidelines of Bangladesh Post Office and other regulatory authorities and as per business policy of Nagad and shall be posted on the Platform and shall become applicable from the date of posting such changes on the Platform.







                    2. Acceptance of Terms and Conditions

                    Your continued access or use of the Platform after posting the updated terms and conditions of this Agreement constitutes your consent to be bound by this updated terms and conditions of the Agreement. If you do not agree to the terms and conditions, please do not use or access our Platform. Please note that, by use of the Platform, you expressly consent to the use and disclosure of your personal information in accordance with this Agreement.



                    Your continued use of the Platform denotes that, you understand and agree that we may collect and use your personal data and information, technical data and related information to meet any of our legal or regulatory compliances as per applicable laws of Bangladesh.







                    3. Information

                    3.1 Basic Information:



                    In order to sign up for Nagad account or use Nagad Services, you must provide your name, phone number and other contact details including, NID/Driving License/Passport/Birth Registration number, as applicable, and other required identification information. We may also request you to provide other verification documents before processing any transaction which is not consistent with your regular transaction or is of a very high amount. You agree to cooperate with Nagad in this process by providing all required information and/or documents accordingly.



                    We collect personal information that you provide to us or over the Platform through correspondence, chats, or any other way, or shared by you from other social websites, services and/or applications; or any additional information (personal or otherwise) that you may be asked to provide as and when necessary.



                    We collect public information about your business size, customer base and usual transactions that might be available from social media platform, other Nagad account holders or other sources for accessing goodwill, trustworthiness and solvency to check your account and business.







                    3.2 Device Identification:



                    Nagad collects information from your interaction with our Platform, Services, content and advertising, including but not limited to, device ID, device type including the hardware model, operating system and version, unique device identifiers and mobile network information, location, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address, information collected by cookies and other tracking technologies, standard web log information and other identifying information about the computer or device that you use to access your Nagad account shall be tracked for helping us detect possible instances of unauthorized transactions and shall be used by Nagad to provide smooth experience of Nagad services.


                </p>
            </div>
        </div>
    )
}

export default TermsAndConditionBN
