// import { APIRequestServices } from "../../service-pattern";
import fetcher from "../../service-pattern/api-request-service/fetcher";
import { store } from "../../store";
import { resetAuth } from "../../store/authSlice";

export const getReports = (params, source) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const { data: countData, status: countStatus } = await fetcher.get(
      //   "report/v2/count",
      //   {
      //     params,
      //     headers: {
      //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      //     },
      //   }
      // );
      const { data, status } = await fetcher.get("report/v2", {
        params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        cancelToken: source?.token,
      });
      if (status === 401 /* || countStatus === 401 */) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
        reject("Unauthorized! logged out!");
      }
      resolve({ data });
    } catch (error) {
      console.log(error.response?.status);
      if (error.response?.status === 401) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
        reject("Unauthorized! logged out!");
      }
      reject(error);
    }
  });
};

export const getReportSummary = (params, source) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await fetcher.get("report/summery", {
        params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        cancelToken: source?.token,
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
        reject("Unauthorized! logged out!");
      }
      resolve(data);
    } catch (error) {
      if (error.response?.status === 401) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
        reject("Unauthorized! logged out!");
      }
      reject(error);
    }
  });
};
