import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIDataServices } from "../service-pattern";
import { toastNotification } from "../utils/toast";
import { resetAuth } from "./authSlice";

export const partnerCreate = createAsyncThunk(
  "partnerSlice/partnerCreate",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .PartnerCreate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        return { status: result.status };
      });

    return data;
  }
);

export const partnerGetAll = createAsyncThunk(
  "partnerSlice/partnerGetAll",
  async (_, { dispatch }) => {
    dispatch(setPartnerListLoading(true));
    const data = await new APIDataServices().PartnerGetAll().then((result) => {
      if (result.status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      }
      if (result.status === 200) {
        dispatch(setPartnerList(result.data));
      } else {
        dispatch(setPartnerList([]));
      }
    });
    dispatch(setPartnerListLoading(false));
    return data;
  }
);

export const partnerCredentialsCreate = createAsyncThunk(
  "partnerSlice/partnerCredentialsCreate",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .PartnerCredentialsCreate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        toastNotification("dismiss", undefined, toastId);

        if (result.status === 201) {
          dispatch(partnerCredentialsGet({ partner_id: formData.partner_id }));
          toastNotification("success", "Configuration Created");
        } else {
          toastNotification("error", "Please Try Again");
        }
      });

    return data;
  }
);

export const partnerCredentialsUpdate = createAsyncThunk(
  "partnerSlice/partnerCredentialsUpdate",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .PartnerCredentialsUpdate(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        toastNotification("dismiss", undefined, toastId);

        if (result.status === 201) {
          dispatch(setPartnerCredentialsData(result.data));
          toastNotification("success", "Credentials Updated");
        } else {
          toastNotification("error", "Please Try again");
        }
      });

    return data;
  }
);

export const partnerCredentialsGet = createAsyncThunk(
  "partnerSlice/partnerCredentialsGet",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .PartnerCredentialsGet(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        toastNotification("dismiss", undefined, toastId);
        toastNotification("success", "Success");
        dispatch(setPartnerCredentialsData(result.data));
      });

    return data;
  }
);

export const salaryConfigurationSync = createAsyncThunk(
  "partnerSlice/salaryConfigurationSync",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .SalaryConfigurationSync(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        toastNotification("dismiss", undefined, toastId);
        if (result.status === 200) {
          dispatch(salaryConfigurationSyncGetData({ ...formData }));
          toastNotification("success", "Salary config Synced");
        } else {
          dispatch(salaryConfigurationSyncGetData({ ...formData }));
          toastNotification("error", "Getting Old Data");
        }
      });

    return data;
  }
);

export const salaryConfigurationSyncGetData = createAsyncThunk(
  "partnerSlice/salaryConfigurationSyncGetData",
  async (formData = {}, { dispatch }) => {
    const data = await new APIDataServices()
      .SalaryConfigurationSyncGetData(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        // if (result.status === 200) {
        dispatch(setSalaryConfigurationData(result.data));
        // }
      });

    return data;
  }
);

export const partnerEmployeeSync = createAsyncThunk(
  "partnerSlice/partnerEmployeeSync",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .PartnerEmployeeSync(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        toastNotification("dismiss", undefined, toastId);

        dispatch(partnerEmployeeSyncGetData(formData));

        if (result.status === 200) {
          toastNotification("success", "Employee List Synced");
        } else {
          toastNotification("error", "Getting Old Data");
        }
      });

    return data;
  }
);

export const partnerEmployeeSyncGetData = createAsyncThunk(
  "partnerSlice/partnerEmployeeSyncGetData",
  async (formData, { dispatch }) => {
    dispatch(setPartnerEmployeeListDataLoading(true));
    const data = await new APIDataServices()
      .PartnerEmployeeSyncGetData(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          dispatch(setPartnerEmployeeListData(result.data));
        }
      });
    dispatch(setPartnerEmployeeListDataLoading(false));
    return data;
  }
);

export const partnerEmployeeAttendenceSync = createAsyncThunk(
  "partnerSlice/partnerEmployeeAttendenceSync",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .PartnerEmployeeAttendenceSync(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }

        toastNotification("dismiss", undefined, toastId);

        dispatch(
          partnerEmployeeAttendenceSyncGetData({
            partner_id: formData.partner_id,
            date_from: formData.filterDate.date_from,
            date_to: formData.filterDate.date_to,
          })
        );

        if (result.status === 200) {
          toastNotification("success", "Attendence List Synced");
        } else {
          toastNotification("error", "Getting Old Data");
        }
      });

    return data;
  }
);

export const partnerEmployeeAttendenceSyncGetData = createAsyncThunk(
  "partnerSlice/partnerEmployeeAttendenceSyncGetData",
  async (formData, { dispatch }) => {
    dispatch(setPartnerEmployeeAttendenceListDataLoading(true));
    const data = await new APIDataServices()
      .PartnerEmployeeAttendenceSyncGetData(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          dispatch(setPartnerEmployeeAttendenceListData(result.data));
        }
      });
    dispatch(setPartnerEmployeeAttendenceListDataLoading(false));
    return data;
  }
);

export const partnerEmployeeSalaryRequestData = createAsyncThunk(
  "partnerSlice/partnerEmployeeSalaryRequestData",
  async (params = {}, { dispatch }) => {
    dispatch(setPartnerEmployeeSalaryRequestListLoading(true));
    const data = await new APIDataServices()
      .PartnerEmployeeSalaryRequestData(params)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          result.data.sort((a, b) => b.id - a.id);
          dispatch(setPartnerEmployeeSalaryRequestList(result.data));
        } else {
          dispatch(setPartnerEmployeeSalaryRequestList([]));
        }

        return { status: result.status, data: result.data };
      });
    dispatch(setPartnerEmployeeSalaryRequestListLoading(false));
    return data;
  }
);

export const partnerEmployeeSalaryEditActionData = createAsyncThunk(
  "partnerSlice/partnerEmployeeSalaryEditActionData",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .PartnerEmployeeSalaryEditData(formData.data, formData.action_type)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
      });
    dispatch(partnerEmployeeSalaryRequestData());
    return data;
  }
);

export const createOgreemFee = createAsyncThunk(
  "partnerSlice/createOgreemFee",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");

    const data = await new APIDataServices()
      .CreateOgreemFee(formData)
      .then((result) => {
        toastNotification("dismiss", undefined, toastId);
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 201) {
          toastNotification("success", "Ogreem fee created");
          dispatch(
            getOgreemFeeList({ params: { partner_id: formData.partner_id } })
          );
          dispatch(setOgreemFeeModal({ open: false, type: "new", id: null }));
        } else {
          toastNotification("error", "Please Try Again");
        }
      });

    return data;
  }
);

export const getOgreemFeeList = createAsyncThunk(
  "partnerSlice/getOgreemFeeList",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .GetOgreemFeeList(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 200) {
          dispatch(setOgreemFeeList(result.data));
        } else {
          dispatch(setOgreemFeeList([]));
        }
      });

    return data;
  }
);

export const deleteOgreemFee = createAsyncThunk(
  "partnerSlice/deleteOgreemFee",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .DeleteOgreemFee(formData.params)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 204) {
          dispatch(
            getOgreemFeeList({ params: { partner_id: formData.partner_id } })
          );
        }
      });

    return data;
  }
);

export const updateOgreemFee = createAsyncThunk(
  "partnerSlice/updateOgreemFee",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .UpdateOgreemFee(formData)
      .then((result) => {
        toastNotification("dismiss", undefined, toastId);
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 201) {
          toastNotification("success", "Ogreem fee updated");
          dispatch(
            getOgreemFeeList({ params: { partner_id: formData.partner_id } })
          );
          dispatch(setOgreemFeeModal({ open: false, type: "new", id: null }));
        } else {
          toastNotification("error", "Please Try Again");
        }
      });

    return data;
  }
);

export const salaryRequestDelete = createAsyncThunk(
  "partnerSlice/salaryRequestDelete",
  async (formData, { dispatch }) => {
    const data = await new APIDataServices()
      .SalaryRequestDelete(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        if (result.status === 204) {
          dispatch(partnerEmployeeSalaryRequestData({ status: "valid" }));
        }
      });

    return data;
  }
);

export const partnerSync = createAsyncThunk(
  "partnerSlice/partnerSync",
  async (formData, { dispatch }) => {
    let toastId = toastNotification("loading", "Please Wait...");
    const data = await new APIDataServices()
      .PartnerSync(formData)
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.removeItem("token");
          dispatch(resetAuth());
        }
        toastNotification("dismiss", undefined, toastId);
        if (result.status === 202) {
          toastNotification("success", "Payment Method Synced");
        } else {
          toastNotification("error", "Getting Old Data");
        }
      });

    return data;
  }
);

const partnerSlice = createSlice({
  name: "partnerSlice",
  initialState: {
    partnerListLoading: false,
    partnerList: [],
    partnerCredentialsData: {},
    salaryConfigurationData: null,
    partnerEmployeeListDataLoading: false,
    partnerEmployeeListData: [],
    partnerEmployeeAttendenceListData: [],
    partnerEmployeeAttendenceListDataLoading: false,
    partnerEmployeeSalaryRequestList: [],
    partnerEmployeeSalaryRequestListLoading: false,
    ogreemFeeList: [],
    ogreemFeeModal: {
      open: false,
      type: "new",
      id: null,
    },
  },
  reducers: {
    setPartnerListLoading: (state, action) => {
      state.partnerListLoading = action.payload;
    },
    setPartnerList: (state, action) => {
      state.partnerList = action.payload;
    },
    setPartnerCredentialsData: (state, action) => {
      state.partnerCredentialsData = action.payload;
    },
    setSalaryConfigurationData: (state, action) => {
      state.salaryConfigurationData = action.payload;
    },
    setPartnerEmployeeListDataLoading: (state, action) => {
      state.partnerEmployeeListDataLoading = action.payload;
    },
    setPartnerEmployeeListData: (state, action) => {
      state.partnerEmployeeListData = action.payload;
    },
    setPartnerEmployeeAttendenceListData: (state, action) => {
      state.partnerEmployeeAttendenceListData = action.payload;
    },
    setPartnerEmployeeAttendenceListDataLoading: (state, action) => {
      state.partnerEmployeeAttendenceListDataLoading = action.payload;
    },
    setPartnerEmployeeSalaryRequestList: (state, action) => {
      state.partnerEmployeeSalaryRequestList = action.payload;
    },
    setPartnerEmployeeSalaryRequestListLoading: (state, action) => {
      state.partnerEmployeeSalaryRequestListLoading = action.payload;
    },
    setOgreemFeeList: (state, action) => {
      state.ogreemFeeList = action.payload;
    },
    setOgreemFeeModal: (state, action) => {
      state.ogreemFeeModal = action.payload;
    },
  },
});

export const {
  setPartnerListLoading,
  setPartnerList,
  setPartnerCredentialsData,
  setSalaryConfigurationData,
  setPartnerEmployeeListDataLoading,
  setPartnerEmployeeListData,
  setPartnerEmployeeAttendenceListData,
  setPartnerEmployeeAttendenceListDataLoading,
  setPartnerEmployeeSalaryRequestList,
  setPartnerEmployeeSalaryRequestListLoading,
  setOgreemFeeList,
  setOgreemFeeModal,
} = partnerSlice.actions;

export default partnerSlice.reducer;
