import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  partnerCredentialsCreate,
  partnerCredentialsGet,
  partnerCredentialsUpdate,
} from "../../store/partnerSlice";
import { Button } from "@mui/material";

const ConnectivityConfiguration = ({ partnerId }) => {
  const dispatch = useDispatch();
  const { partnerCredentialsData } = useSelector((state) => state.partnerSlice);
  const [loading, setLoading] = useState(true);
  const getPartnerCreds = useCallback(() => {
    setLoading(true);
    if (partnerId) {
      dispatch(partnerCredentialsGet({ partner_id: partnerId }));
    }
    setLoading(false);
  }, [dispatch, partnerId]);
  useEffect(() => {
    getPartnerCreds();
  }, [dispatch, getPartnerCreds]);

  const [partnerConnectivity, setPartnerConnectivity] = useState({
    url: "",
    db: "",
    username: "",
    token: "",
  });

  useEffect(() => {
    if (Object.keys(partnerCredentialsData).length > 0) {
      setPartnerConnectivity(partnerCredentialsData);
    } else {
      setPartnerConnectivity({
        url: "",
        db: "",
        username: "",
        token: "",
      });
    }
  }, [partnerCredentialsData]);

  const handleValueChange = (name, value) => {
    setPartnerConnectivity({
      ...partnerConnectivity,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    dispatch(
      partnerCredentialsCreate({
        data: partnerConnectivity,
        partner_id: partnerId,
      })
    );
  };

  const handleUpdate = () => {
    dispatch(
      partnerCredentialsUpdate({
        data: partnerConnectivity,
        partner_id: partnerId,
      })
    );
  };

  return (
    <div className="grid grid-cols-12">
      {!loading && (
        <div className="col-span-6">
          <div className="flex justify-between items-center mb-4">
            <p>URL:</p>
            <textarea
              className="border-2 border-black w-[75%] p-2"
              value={partnerConnectivity?.url}
              name="url"
              onChange={(event) =>
                handleValueChange(event.target.name, event.target.value)
              }
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <p>Database:</p>
            <textarea
              className="border-2 border-black w-[75%] p-2"
              value={partnerConnectivity?.db}
              name="db"
              onChange={(event) =>
                handleValueChange(event.target.name, event.target.value)
              }
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <p>User name:</p>
            <textarea
              className="border-2 border-black w-[75%] p-2"
              value={partnerConnectivity?.username}
              name="username"
              onChange={(event) =>
                handleValueChange(event.target.name, event.target.value)
              }
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <p>Token:</p>
            <textarea
              className="border-2 border-black w-[75%] p-2"
              value={partnerConnectivity?.token}
              name="token"
              onChange={(event) =>
                handleValueChange(event.target.name, event.target.value)
              }
            />
          </div>

          {Object.keys(partnerCredentialsData)?.length > 0 ? (
            <Button
              variant="contained"
              sx={{ marginTop: 5 }}
              color="secondary"
              onClick={handleUpdate}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              sx={{ marginTop: 5 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ConnectivityConfiguration;
