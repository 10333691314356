import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Select from "react-select"
import _ from "lodash";
import {
  companyDataGet,
  companyGetFee,
  companyDeleteFee,
  setCreateFeeModal,
} from "../../store/agrimSlice";
import FeeConfigurationAddingModal from "./fee-config-modal";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonTable from "../../components/common-table";

// const customStyles = {
//     container: (provided) => ({
//         ...provided,
//         width: "100%"
//     })
// }

// const feeTypeOptions = [
//     { label: "Fixed", value: "fixed" },
//     { label: "Percentage", value: "percent" },
// ]

const OgrimSettings = () => {
  const dispatch = useDispatch();
  const { companyData, companyFeeData } = useSelector(
    (state) => state.agrimSlice
  );
  const getCompanyAllData = useCallback(async () => {
    const companyProfileData = await dispatch(companyDataGet());
    dispatch(
      companyGetFee({ company_id: companyProfileData?.payload.data?.id })
    );
  }, [dispatch]);

  useEffect(() => {
    getCompanyAllData();
  }, [getCompanyAllData]);
  const handleEdit = useCallback(
    (row) => {
      dispatch(
        setCreateFeeModal({ open: true, type: "edit", id: row?.original?.fid })
      );
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    (row) => {
      dispatch(
        companyDeleteFee({
          fee_line_id: row?.original?.fid,
          company_id: companyData?.id,
        })
      );
    },
    [companyData?.id, dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: "From Amount",
        accessor: "from_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "To Amount",
        accessor: "to_amount",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Fee Type",
        // accessor: 'type',
        className: "font-bold",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {_.capitalize(row.original.fee_type)}
          </div>
        ),
      },
      {
        Header: "Transaction Fee",
        accessor: "fee",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Edit",
        id: "edit",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton onClick={(ev) => handleEdit(row)}>
              <EditIcon sx={{ fontSize: 30, color: "black" }} />
            </IconButton>
          </div>
        ),
      },
      {
        Header: "Delete",
        id: "delete",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton onClick={(ev) => handleDelete(row)}>
              <DeleteIcon sx={{ fontSize: 30, color: "red" }} />
            </IconButton>
          </div>
        ),
      },
    ],
    [handleDelete, handleEdit]
  );

  return (
    <div>
      <p className="mb-8 font-bold text-lg">Ogrim Fee Configuration</p>

      <div className="flex justify-between mb-8">
        <button
          className="p-4 bg-yellow-500"
          onClick={() =>
            dispatch(setCreateFeeModal({ open: true, type: "new", id: null }))
          }
        >
          Add new
        </button>
      </div>

      <CommonTable
        columns={columns}
        data={companyFeeData}
        handleClick={(event, row) => null}
      />

      <FeeConfigurationAddingModal />
    </div>
  );
};

export default OgrimSettings;
