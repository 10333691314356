export const toTitleCase = (str = " ", splitter = "_", joiner = " ") => {
  const realString = typeof str === "number" ? str.toString() : str;
  return realString
    ?.split(splitter)
    ?.map((txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    ?.join(joiner);
};

export const removeEmptyValues = (object) => {
  let obj = { ...object };
  const keys = Object.keys(obj);
  for (var i = 0; i < keys.length; ++i) {
    const key = keys[i];
    const value = obj[key];
    if (value === null || value === undefined || value === "") {
      delete obj[key];
    }
  }
  return obj;
};
