import React, { useState } from "react";
import Select from "react-select";
// import _ from "lodash"
import { partnerCreate } from "../../store/partnerSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const statusTypeOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const PartnerProfileConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [partnerProfile, setPartnerProfile] = useState({
    name: "",
    address: "",
    status: null,
    min_withdraw: "",
    withdraw_interval: "",
    has_slider: false,
    email: "",
  });

  const handleValueChange = (name, value) => {
    setPartnerProfile({
      ...partnerProfile,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let result = await dispatch(partnerCreate(partnerProfile));
    if (result.payload.status === 201) {
      navigate("/admin/company-list");
    }
  };

  const handleStatus = () => {
    if (partnerProfile.status === null) {
      return null;
    }
    if (partnerProfile.status === false) {
      return { label: "Inactive", value: partnerProfile.status };
    }

    if (partnerProfile.status === true) {
      return { label: "Active", value: partnerProfile.status };
    }
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-6">
        <div className="flex justify-between items-center mb-4">
          <p>Name:</p>
          <textarea
            className="border-2 border-black w-[75%] p-2"
            value={partnerProfile.name}
            name="name"
            onChange={(event) =>
              handleValueChange(event.target.name, event.target.value)
            }
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <p>Address:</p>
          <textarea
            className="border-2 border-black w-[75%] p-2"
            value={partnerProfile.address}
            rows={3}
            name="address"
            onChange={(event) =>
              handleValueChange(event.target.name, event.target.value)
            }
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <p>Email:</p>
          <input
            type="email"
            className="border-2 border-black w-[75%] p-2"
            value={partnerProfile.email}
            name="email"
            onChange={(event) =>
              handleValueChange(event.target.name, event.target.value)
            }
          />
        </div>
        <div className="flex justify-between items-center mb-6">
          <p className="mr-4">Status</p>
          <div className="w-3/4">
            <Select
              options={statusTypeOptions}
              styles={customStyles}
              value={handleStatus()}
              onChange={(event) => handleValueChange("status", event.value)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <p>Min Withdraw:</p>
          <input
            type="number"
            className="border-2 border-black w-[75%] p-2"
            value={partnerProfile.min_withdraw}
            name="min_withdraw"
            onChange={(event) =>
              handleValueChange(event.target.name, event.target.value)
            }
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <p>Withdraw Interval:</p>
          <input
            type="number"
            className="border-2 border-black w-[75%] p-2"
            value={partnerProfile.withdraw_interval}
            name="withdraw_interval"
            onChange={(event) =>
              handleValueChange(event.target.name, event.target.value)
            }
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <label for="has_slider" className="w-[25%]">
            Has Slider?
          </label>
          <input
            id="has_slider"
            type="checkbox"
            value={partnerProfile.has_slider}
            name="has_slider"
            className="border-2 border-black mr-auto p-2"
            onChange={(event) =>
              handleValueChange(event.target.name, event.target.checked)
            }
          />
        </div>
        <button className="p-4 bg-yellow-500" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default PartnerProfileConfiguration;
