// import React, { useState } from "react";
import { Chip, FormControl } from "@mui/material";
// import { partnerEmployeeSalaryEditActionData } from "../../../store/partnerSlice";
// import { useDispatch } from "react-redux";
import labels from "../../../utils/labels";

const ChangeStatusActions = ({ row: { original }, value }) => {
  // const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  // const [open, setOpen] = React.useState(false);

  // const handleClose = () => {
  //     setOpen(false);
  // };

  // const handleOpen = () => {
  //     setOpen(true);
  // };
  // const handleStatusChange = async (e) => {
  //     try {
  //         setLoading(true);
  //         dispatch(
  //             partnerEmployeeSalaryEditActionData({
  //                 data: [original["id"]],
  //                 action_type: e.target.value,
  //             })
  //         );
  //         handleClose();
  //     } catch (error) {
  //         console.log(error);
  //     }
  //     setLoading(false);
  // };

  return (
    <>
      <Chip
        label={value}
        color={labels[value]}
        // onClick={handleOpen}
        id="demo-simple-select-label"
      />
      <FormControl sx={{ width: 120 }} size="small">
        {/* <Select
                    size="small"
                    disabled={loading}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={value}
                    onChange={handleStatusChange}
                    sx={{ opacity: 0 }}
                >
                    <MenuItem value="valid">Valid</MenuItem>
                    <MenuItem value="invalid">Invalid</MenuItem>
                    <MenuItem value="disburse">Disburse</MenuItem>
                    <MenuItem value="invoiced">Invoiced</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select> */}
      </FormControl>
    </>
  );
};
export default ChangeStatusActions;
