import {
  Box,
  Card,
  CardContent,
  // CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import CommonTable from "../../components/common-table";
// import ChangeStatusActions from "../../components/page-components/salary-request-list/ChangeStatusActions";
import { APIRequestServices } from "../../service-pattern";
import { store } from "../../store";
import { resetAuth } from "../../store/authSlice";
import { toastNotification } from "../../utils/toast";

const EmployeeAttendances = ({ employee_id }) => {
  const [attendances, setAttendances] = useState([]);
  const [loading, setLoading] = useState(false);
  const { config, detail } = useRouteLoaderData("employee-profile");
  const getEmployeeAttendances = useCallback(async () => {
    setLoading(true);
    try {
      const { data, status } = await new APIRequestServices().Get({
        URL: `attendances`,
        getRawResponse: true,
        data: {
          employee_id,
          date_from: config.payroll_start_date,
          date_to: config.payroll_end_date,
        },
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        store.dispatch(resetAuth());
      } else {
        console.log(data);
        setAttendances(data);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
    setLoading(false);
  }, [config.payroll_end_date, config.payroll_start_date, employee_id]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "employee_name",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Check In",
        accessor: "check_in",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Working Hours",
        accessor: "worked_hours",
        className: "font-bold",
        sortable: true,
        width: 80,
      },
      {
        Header: "Is Late",
        id: "is_late",
        className: "font-bold",
        sortable: true,
        width: 80,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.is_late ? "Late" : "Not Late"}
          </div>
        ),
      },
      {
        Header: "Check Out",
        accessor: "check_out",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Is Early",
        id: "is_early",
        className: "font-bold",
        sortable: true,
        width: 100,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.is_early ? "Early" : "Not Early"}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "date",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        className: "font-bold",
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    getEmployeeAttendances();
  }, [getEmployeeAttendances]);

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>
                  Total Days Count
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {detail?.total_workday}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>
                  Total Present (in current payroll)
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {detail?.total_present}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>
                  Total Absent (in current payroll)
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {detail?.total_absent}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px" }}>
                  Total Leave (in current payroll)
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "20px" }}>
                    {detail?.total_leaves}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CommonTable
            columns={columns}
            data={attendances}
            handleClick={() => null}
            tableLoader={loading}
            // TableToolsComponent={TableTools}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeAttendances;
