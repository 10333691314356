import { Close, Print } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  Box,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { useCallback, useEffect, useRef, useState } from "react";
import { APIDataServices } from "../../service-pattern";
import { toastNotification } from "../../utils/toast";
import PDFRenderer from "./PDFRenderer";
// import { renderToString } from "react-dom/server";

const InvoiceDialog = ({ invoice_id }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceDataKeys, setInvoiceDataKeys] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const printInvoice = () => {
  // const pdf = new jsPDF();
  // console.log(ref.current);
  // const dop = renderToString(ref.current.innerHTML);
  // pdf.html(ref.current, {
  //   callback: function (doc) {
  //     doc.save();
  //   },
  // });
  // // pdf.fromHTML(ref.current.innerHTML);
  // // pdf.save();
  // };

  const generateInvoice = useCallback(async () => {
    try {
      const { data } = await new APIDataServices().InvoiceGenerate({
        invoice_id,
      });
      if (data) {
        setInvoiceData(data);
        if (data?.req_lines && data?.req_lines?.length !== 0) {
        }
        setInvoiceDataKeys(Object.keys(data?.req_lines?.[0]));
        // const doc = invoiceRef.current.contentDocument;
        // invoiceRef.current.width = "fit-content";

        // doc.open();
        // doc.write(data);
        // doc.close();
      }
      console.log(data);
    } catch (error) {
      toastNotification("error", error.message);
    }
  }, [invoice_id]);
  useEffect(() => {
    if (open) {
      generateInvoice();
    }
  }, [generateInvoice, open]);
  return (
    <>
      <Tooltip title="Print invoice">
        <IconButton color="secondary" onClick={handleClickOpen}>
          <Print />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleClose} open={open} fullScreen>
        {/* <pre>{JSON.stringify(invoiceData)}</pre> */}
        <DialogActions>
          <ReactToPrint
            trigger={() => <Button startIcon={<Print />}>Print</Button>}
            content={() => ref.current}
          />

          <Button startIcon={<Close />} color="error" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <PDFRenderer
            ref={ref}
            invoiceData={invoiceData}
            invoiceDataKeys={invoiceDataKeys}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default InvoiceDialog;
