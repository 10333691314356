import axios from "axios";

let headers = {
  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
};

let fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://118.179.84.157:8009",
  headers,
});

export default fetcher;
