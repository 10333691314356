import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/common-table";
import {
  salaryConfigurationSync,
  salaryConfigurationSyncGetData,
} from "../../store/partnerSlice";
import EditPartnerConfig from "./EditPartnerConfig";

const SalaryConfiguration = ({ partnerId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(salaryConfigurationSyncGetData({ partner_id: partnerId }));
  }, [dispatch, partnerId]);

  const { salaryConfigurationData } = useSelector(
    (state) => state.partnerSlice
  );

  const [salaryConfigData, setSalaryConfigData] = useState({});

  useEffect(() => {
    if (salaryConfigurationData) {
      setSalaryConfigData(salaryConfigurationData);
    } else {
      setSalaryConfigData({});
    }
  }, [salaryConfigurationData]);

  const handleSalaryConfigSync = () => {
    dispatch(salaryConfigurationSync({ partner_id: partnerId }));
  };

  const columns = useMemo(
    () => [
      {
        Header: "From Amount",
        accessor: "amount_from",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "To Amount",
        accessor: "amount_to",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Frequency",
        accessor: "frequency",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Percent",
        accessor: "percent",
        className: "font-bold",
        sortable: true,
      },
      {
        Header: "Cut-off Dates",
        id: "profile-view",
        // width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.cutoff_dates.join()}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={4}>
          <button
            className="p-4 bg-yellow-500 mb-6"
            onClick={handleSalaryConfigSync}
          >
            Salary Configuration Sync
          </button>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <Typography>
              payroll_start_date : {salaryConfigData.payroll_start_date}
            </Typography>
            <Typography>
              payroll_end_date : {salaryConfigData.payroll_end_date}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <EditPartnerConfig
            partnerId={partnerId}
            salaryConfigData={salaryConfigData}
            getConfigData={() => {
              dispatch(
                salaryConfigurationSyncGetData({ partner_id: partnerId })
              );
            }}
          />
        </Grid>
      </Grid>
      <p className="mb-4 font-bold text-xl">Slab Lines</p>
      {salaryConfigData.slab_lines && (
        <CommonTable
          columns={columns}
          data={salaryConfigData.slab_lines}
          handleClick={() => {}}
        />
      )}
    </div>
  );
};

export default SalaryConfiguration;
