import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetcher from "../service-pattern/api-request-service/fetcher"
import { resetAuth } from "./authSlice"

export const fetchAllBatch = createAsyncThunk(
    "batchSlice/GetAllBatch",
    async (params, { dispatch }) => {
        dispatch(setBatchRequestLoading(true))  
        try {
            const result = await fetcher.get("payment_batch", {
                params,
                headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
             },
            })
            const { data } = result;
            dispatch(setAllBatchData(data))
            dispatch(setBatchRequestLoading(false))
        } catch (err) {
            if (err.response?.status === 401) {
             sessionStorage.removeItem("token");
             dispatch(resetAuth());
            }
            dispatch(setBatchRequestLoading(false))
            dispatch(setAllBatchData([]))
        }
    }
)

const batchSlice = createSlice({
    name: 'batchSlice',
    initialState: {
       batchRequestLoading: false,
       allBatchData: []
    },
    reducers: {
       setBatchRequestLoading: (state, { payload }) => {
           state.batchRequestLoading = payload;
       },
       setAllBatchData: (state, { payload }) => {
           state.allBatchData = payload;
       }
    }
})

export const { setBatchRequestLoading, setAllBatchData  } = batchSlice.actions
export default batchSlice.reducer