import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useGroupBy,
  useExpanded,
} from "react-table";
import PaginationActions from "./PaginationActions";
import { ArrowDownward, ArrowForward, ArrowLeft, ArrowRight } from "@mui/icons-material";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const CommonTable = ({
  columns,
  changePage,
  offset,
  limit,
  request_type,
  data,
  // paginationData,
  handleClick,
  // permission,
  checkbox = false,
  // actions = {},
  TableToolsComponent = null,
  TableSearch = null,
  dense = true,
  tableLoader,
  CellRow,
  celldata,
  filter_data = {},
  handleSettingFilterData = () => { }
}) => {

  const table = useTable(
    {
      columns,
      offset,
      limit,
      data,
      autoResetPage: true,
      initialState: request_type !== 'advance' ? { pageSize: 10 } : {},
    },
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((_columns) => {
        let clms = [..._columns];
        if (checkbox)
          clms = [
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...clms,
          ];
        return [...clms];
      });
    }
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    rows,
    state: {
      pageSize,
      // groupBy,
      // expanded,
      // globalFilter
    },
  } = table;

  let pageRows

  if (request_type == 'advance') {
    pageRows = rows
  } else {
    pageRows = page
  }

  const [, setRowPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
    setPageNumber(newPage);
    // const nextPageNumber = newPage + 1
    // if (nextPageNumber <= paginationData.last_page) {
    //     // dispatch(getKPIWindowList({ page: nextPageNumber, per_page: rowPerPage }))
    // }
  };

  const handleChangeRowsPerPage = (event) => {
    // dispatch(getKPIWindowList({ per_page: event.target.value }))
    setRowPerPage(Number(event.target.value));
    setPageSize(Number(event.target.value));
  };

  return (
    <>
      {TableToolsComponent ? <TableToolsComponent table={table} filter_data={filter_data} handleSettingFilterData={handleSettingFilterData} /> : null}
      {TableSearch ? <TableSearch setGlobalFilter={setGlobalFilter} /> : null}

      {request_type == 'advance' && <Box sx={{ maxWidth: "240px", marginTop: "30px", display: "flex", justifyContent: "space-between" }}>
        <IconButton
          onClick={() => {
            changePage(offset - limit > 0 ? offset - limit : 0, limit)
          }}
          size="small"
        >
          <ArrowLeft />
        </IconButton>
        <Typography sx={{ marginTop: "8px" }}>Page {Math.ceil(offset / limit) + 1}</Typography>
        <IconButton
          onClick={() => {
            changePage(offset + limit, limit)
          }}
          size="small"
        >
          <ArrowRight />
        </IconButton>
        <Select
          size="small"
          value={limit}
          onChange={(e) => {
            changePage(0, Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50, 100, 150, 200].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              Show {pageSize}
            </MenuItem>
          ))}
        </Select>
      </Box>}

      {tableLoader ? (
        // <TableRow sx={{ p: 0 }}>
        //   <TableCell colSpan={page.length} sx={{ p: 0 }}>
        <LinearProgress sx={{ width: "100%", p: 0 }} />
      ) : (
        //   </TableCell>
        // </TableRow>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {!tableLoader && data.length === 0 ? (
            <Typography>No data to show</Typography>
          ) : (
            <>
              <TableContainer className="flex flex-1">
                <Table
                  {...getTableProps()}
                  stickyHeader
                  size={dense ? "small" : ""}
                >
                  <TableHead>
                    {headerGroups.map((headerGroup) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {!!CellRow ? <TableCell /> : ""}
                        {headerGroup.headers.map((column) => (
                          <TableCell
                            sx={{ fontWeight: "bold" }}
                            {...(!column.sortable
                              ? column.getHeaderProps()
                              : column.getHeaderProps(
                                column.getSortByToggleProps()
                              ))}
                          >
                            {/* {console.log(column)}
                            {column.canGroupBy ? (
                              // If the column can be grouped, let's add a toggle
                              <span {...column.getGroupByToggleProps()}>
                                {column.isGrouped ? "🛑 " : "👊 "}
                              </span>
                            ) : null} */}
                            {column.render("Header")}
                            {column.sortable ? (
                              <TableSortLabel
                                active={column.isSorted}
                                // react-table has a unsorted state which is not treated here
                                direction={column.isSortedDesc ? "desc" : "asc"}
                              />
                            ) : null}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {pageRows.map((row, i) => {
                      // row.permission = permission
                      prepareRow(row);
                      return (
                        <Fragment key={i}>
                          {!!CellRow ? (
                            <CellRow row={row} celldata={celldata} />
                          ) : (
                            <TableRow
                              {...row.getRowProps()}
                              className="cursor-pointer"
                              onClick={(event) => handleClick(event, row)}
                              hover
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <TableCell
                                    {...cell.getCellProps()}
                                    className={`p-4 md:p-12 ${cell.column.className}`}
                                    width={cell.column.width}
                                  >
                                    {cell.isGrouped ? (
                                      // If it's a grouped cell, add an expander and row count
                                      <>
                                        <span
                                          {...row.getToggleRowExpandedProps()}
                                        >
                                          {row.isExpanded ? (
                                            <ArrowDownward />
                                          ) : (
                                            <ArrowForward />
                                          )}
                                        </span>{" "}
                                        {cell.render("Cell")} (
                                        {row.subRows.length})
                                      </>
                                    ) : cell.isAggregated ? (
                                      // If the cell is aggregated, use the Aggregated
                                      // renderer for cell
                                      cell.render("Aggregated")
                                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                      // Otherwise, just render the regular cell
                                      cell.render("Cell")
                                    )}
                                    {/* {cell.render("Cell")} */}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          )}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {request_type !== 'advance' && <TablePagination
                component="div"
                classes={{
                  root: "flex-shrink-0 border-t-1",
                }}
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={5}
                // count={paginationData?.total ? paginationData?.total : 0}
                count={rows.length}
                // count={data.length}
                rowsPerPage={pageSize}
                page={pageNumber}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: false,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={PaginationActions}
              />}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default CommonTable;
