import React from "react";
import TextField from "@mui/material/TextField";

const TableSearch = ({ setGlobalFilter, loading = false }) => {
  return (
    <TextField
      type="search"
      size="small"
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder="Search"
      disabled={loading}
    />
  );
};

export default TableSearch;
