import { Cancel, Close, Send } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogActions,
  Button,
  DialogTitle,
  Tooltip,
} from "@mui/material";
// import ReactToPrint from "react-to-print";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { APIDataServices } from "../../service-pattern";
import { invoiceGetAll } from "../../store/invoiceSlice";
import { toastNotification } from "../../utils/toast";
// import { renderToString } from "react-dom/server";

const CancelInvoice = ({ invoice_id, partner_id }) => {
  let [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  // const printInvoice = () => {
  // const pdf = new jsPDF();
  // console.log(ref.current);
  // const dop = renderToString(ref.current.innerHTML);
  // pdf.html(ref.current, {
  //   callback: function (doc) {
  //     doc.save();
  //   },
  // });
  // // pdf.fromHTML(ref.current.innerHTML);
  // // pdf.save();
  // };

  const cancelInvoice = async () => {
    try {
      const { data } = await new APIDataServices().InvoiceCancel({
        invoice_id,
        partner_id,
      });
      if (data) {
        handleClose();
        dispatch(
          invoiceGetAll({
            partner_id: searchParams.get("partner_id"),
          })
        );
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  };

  return (
    <>
      <Tooltip title="Cancel invoice">
        <IconButton color="error" onClick={handleClickOpen}>
          <Cancel />
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog onClose={handleClose} open={open}>
          {/* <pre>{JSON.stringify(invoiceData)}</pre> */}
          <DialogTitle>Do you want to cancel this invoice?</DialogTitle>
          <DialogActions>
            <Button startIcon={<Close />} color="error" onClick={handleClose}>
              Close
            </Button>
            <Button startIcon={<Send />} onClick={cancelInvoice}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CancelInvoice;
