import { Settings } from "@mui/icons-material";
import { Box, Button, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { APIDataServices } from "../../service-pattern";
import { resetAuth } from "../../store/authSlice";
import { toastNotification } from "../../utils/toast";

const EditPartnerConfig = ({ salaryConfigData, partnerId, getConfigData }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const {
    register,
    handleSubmit,
    // formState: { errors },
    watch,
    reset,
  } = useForm();
  const onSubmit = async (values) => {
    // console.log(values);
    // toastNotification("success", "Updating. Please Wait..");

    try {
      const { status } = await new APIDataServices().SalaryConfigurationUpdate({
        partner_id: partnerId,
        data: values,
      });
      if (status === 401) {
        sessionStorage.removeItem("token");
        dispatch(resetAuth());
      } else if (status === 200) {
        toastNotification("success", "Config updated");
        getConfigData();
        setEdit(false);
      } else {
        toastNotification("error", "Error occoured. Please try again");
      }
    } catch (error) {
      console.log(error);
      toastNotification("error", error.message);
    }
  };
  useEffect(() => {
    reset({
      buffer_days: salaryConfigData.buffer_days,
      payment_date: salaryConfigData.payment_date,
      cost_of_finance_rate: salaryConfigData.cost_of_finance_rate,
      penalty_rate: salaryConfigData.penalty_rate,
    });
  }, [salaryConfigData, reset]);

  return (
    <>
      {!edit ? (
        <Button startIcon={<Settings />} onClick={() => setEdit((ed) => !ed)}>
          Edit Config
        </Button>
      ) : (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={2}>
            <TextField
              label="Buffer Days"
              placeholder="Buffer Days"
              type="number"
              {...register("buffer_days")}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* <TextField placeholder="" type="date" {...register("payment_date")} /> */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Payment date"
                inputFormat="DD/MM/YYYY"
                value={watch("payment_date")}
                onChange={(newValue) => {
                  reset({
                    ...watch(),
                    payment_date: newValue.format("YYYY-MM-DD"),
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              label="Cost of Finance Rate"
              placeholder="Cost of Finance Rate"
              type="number"
              {...register("cost_of_finance_rate")}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Penalty Rate"
              placeholder="Penalty Rate"
              type="number"
              {...register("penalty_rate")}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={() => setEdit((ed) => !ed)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Update
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default EditPartnerConfig;
